import React, { PropsWithChildren } from "react";
import { FaCheck } from "react-icons/fa";
import makeStyle from "../../../style/makeStyle";

type Props = {
	stepNumber: number;
	current: boolean;
	finished: boolean;
}

export default function StepTitle(props: PropsWithChildren<Props>): JSX.Element {
	const wrapperStyle = "flex flex-row";
	const indexStyle = makeStyle("flex items-center justify-center inline-block w-7 h-7 rounded-full border text-slate-400 border-slate-400 select-none", [
		...(props.current ? [ "!text-primary", "!border-primary" ] : []),
		...(props.finished ? [ "!text-success", "!border-success" ] : []),
	]);
	const titleStyle = makeStyle("hidden md:flex items-center ml-2 text-slate-400", [
		props.current && "!text-primary",
		props.finished && "!text-success",
	]);

	const stepIcon = props.finished
		? <FaCheck size={12} />
		: props.stepNumber;

	return (
		<div className={wrapperStyle}>
			<span className={indexStyle}>
				{stepIcon}
			</span>

			<span className={titleStyle}>
				{props.children}
			</span>
		</div>
	);
}
