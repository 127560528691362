import React, { useEffect } from "react";
import AuthenticatedLayout from "../../layouts/AuthenticatedLayout";
import { useTranslation } from "react-i18next";
import Alert from "../../components/atoms/Alert";
import Table from "../../components/atoms/table/Table";
import TableHead from "../../components/atoms/table/TableHead";
import TableRow from "../../components/atoms/table/TableRow";
import TableCell from "../../components/atoms/table/TableCell";
import TableBody from "../../components/atoms/table/TableBody";
import { DateTime } from "luxon";
import i18next from "i18next";
import ContentLoader from "../../components/molecules/ContentLoader";
import { getDossiers } from "../../queries/rentalContracts";
import { Link } from "react-router-dom";
import Button from "../../components/atoms/Button";
import {
	FaFileContract, FaHouseUser, FaUser,
} from "react-icons/fa";
import useManagementContracts from "../../hooks/useManagementContracts";
import useAuth from "../../hooks/useAuth";

export default function DossiersOverview(): JSX.Element {
	const { t } = useTranslation("dossiers");
	const contracts = useManagementContracts();
	const auth = useAuth();
	const rentalContracts = getDossiers(contracts.currentContractId);

	useEffect(() => {
		void rentalContracts.refetch();
	}, [contracts.currentContractId]);

	return (
		<AuthenticatedLayout
			title={t("pageTitleOverview")}
			showManagementContracts
		>
			{rentalContracts.isLoading ? (<ContentLoader />)
				: !rentalContracts.data
					? (
						<Alert variant="info" className="mb-5">
							{t("rentalContractDocumentDetail.errors.noTenantsAssigned")}
						</Alert>
					)
					: (
						<Table>
							<TableHead>
								<TableRow>

									<TableCell>
										{t("overview.tableHeader.vheName")}
									</TableCell>

									<TableCell>
										{t("overview.tableHeader.contractNumber")}
									</TableCell>

									<TableCell>
										{t("overview.tableHeader.tenantName")}
									</TableCell>

									<TableCell>
										{t("overview.tableHeader.startDate")}
									</TableCell>

									<TableCell>
										{t("overview.tableHeader.endDate")}
									</TableCell>

									<TableCell>
										{t("overview.tableHeader.contractType")}
									</TableCell>

									<TableCell>
										{t("overview.tableHeader.documents")}
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{rentalContracts.data.filter(rentalContract =>
									!(DateTime.now() >= DateTime.fromISO(rentalContract.endDate)))
									.map(dossier => (
										<TableRow key={`${dossier.rentalContractId}`}>

											<TableCell>
												{dossier.unit}
											</TableCell>

											<TableCell>
												{dossier.name}
											</TableCell>

											<TableCell>
												{dossier.tenant}
											</TableCell>

											<TableCell>
												{DateTime
													.fromISO(dossier.startDate)
													.setLocale(i18next.language)
													.toFormat("MMMM y")}
											</TableCell>

											<TableCell>
												{dossier.endDate ? DateTime
													.fromISO(dossier.endDate)
													.setLocale(i18next.language)
													.toFormat("MMMM y") : t("overview.openEndedContract")}
											</TableCell>

											<TableCell>
												{dossier.type}
											</TableCell>

											<TableCell>
												{auth.hasCapability(["DossierRelationDocuments"])
													&& (
														<Link to={`/dossiers/relation/${dossier.rentalContractId}`} className="mb-2 inline-block">
															<Button>
																<FaUser className="pr-px" />

																{t("overview.buttons.tenantDocuments")}
															</Button>
														</Link>
													)}

												{auth.hasCapability(["DossierRentalContractDocuments"])
													&& (
														<Link to={`/dossiers/documents/${dossier.rentalContractId}/`} className="mb-2 mx-2 mt-2 inline-block">
															<Button>
																<FaFileContract className="pr-px" />

																{t("overview.buttons.contractDocuments")}
															</Button>
														</Link>
													)}

												{auth.hasCapability(["DossierUnitDocuments"])
													&& (
														<Link to={`/dossiers/documents/object/${dossier.unitId}/${dossier.tenantId}`} className="mb-2 mx-2 mt-2 inline-block">
															<Button>
																<FaHouseUser className="pr-px" />

																{t("overview.buttons.objectDocuments")}
															</Button>
														</Link>
													)}
											</TableCell>

										</TableRow>
									))}
							</TableBody>
						</Table>
					)}
		</AuthenticatedLayout>
	);
}
