import React, { PropsWithChildren } from "react";
import { ButtonProps } from "../../models/props/button.props";
import makeStyle from "../../style/makeStyle";
import Loader from "./Loader";

export default function Button(props: PropsWithChildren<ButtonProps>): JSX.Element {
	const variant = props.variant ?? "info";
	const isDisabled = (!!props.disabled || !!props.loading);

	const variantStyle = props.outlined
		? `text-black border ${variant} outlined`
		: `text-white ${variant}`;
	const disabledVariantStyle = props.outlined
		? "text-gray-400 bg-gray-300"
		: "text-gray-400 border border-gray-400";

	const style = makeStyle("flex items-center py-2 px-4 rounded-md font-medium shadow-md", [
		isDisabled ? disabledVariantStyle : variantStyle,
		props.className,
	]);

	return (
		<button
			type={props.type ?? "button"}
			onClick={props.onClick}
			className={style}
			disabled={isDisabled}
			{...props}
		>
			{!!props.loading && <Loader className="mr-3" />}

			{props.children}
		</button>
	);
}
