import React, {
	useEffect, useRef, useState,
} from "react";
import TableRow from "../../atoms/table/TableRow";
import TableCell from "../../atoms/table/TableCell";
import { RequestedDocumentResponse } from "../../../models/types/api/documents.type";
import { useTranslation } from "react-i18next";
import Modal from "../../atoms/Modal";
import Subtitle from "../../atoms/Subtitle";
import FileUploader, { FileUploaderActions } from "../../molecules/FileUploader";
import { uploadDocument } from "../../../queries/documents";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { CELL_STYLE, WARNING_CELL_STYLE } from "../../../pages/DocumentsPage";
import { CiWarning } from "react-icons/ci";
import { FaCheck, FaUpload } from "react-icons/fa";
import IconButton from "../../atoms/IconButton";

type RequestedDocumentsProps = {
	documents: RequestedDocumentResponse[];
	onUpload?: (id: number) => void;
	uploadedDocuments?: number[];
}

const MIN_FILE_COUNT = 0;
const MAX_FILE_COUNT = 5;

export default function RequestedDocuments(props: RequestedDocumentsProps): JSX.Element {
	const [ selectedDocument, setSelectedDocument ]
		= useState<RequestedDocumentResponse | null>(null);
	const [ selectedFiles, setSelectedFiles ] = useState<File[]>([]);
	const [ errors, setErrors ] = useState<string[]>([]);

	const canSubmit = selectedFiles.length > MIN_FILE_COUNT
		&& selectedFiles.length <= MAX_FILE_COUNT;

	const fileUploaderRef = useRef<FileUploaderActions>(null);
	const { t, i18n } = useTranslation("documents");

	const queryClient = useQueryClient();
	const mutation = uploadDocument({
		onSuccess() {
			if (!props.onUpload || !props.uploadedDocuments || !selectedDocument)
				void queryClient.invalidateQueries(["documents"]);
			else
				props.onUpload(selectedDocument.relationDocumentId);

			clearStates();
			toast.success(t("upload.success"));
		},
	});

	useEffect(() => {
		if (!mutation.error && !mutation.isLoading) {
			setErrors([]);
			return;
		}

		if (mutation.error)
			setErrors(mutation.error);
	}, [mutation.error]);

	const handleSelectDocument = (document: RequestedDocumentResponse): void => {
		setSelectedDocument(document);

		fileUploaderRef.current?.startUpload();
	};

	const handleCancel = (): void => {
		clearStates();
	};

	const clearStates = (): void => {
		setSelectedDocument(null);
		setSelectedFiles([]);
		setErrors([]);
	};

	const handleSubmit = (): void => {
		if (!selectedDocument || !canSubmit) {
			toast.warning(t("upload.errors.incorrect_amount", {
				min: MIN_FILE_COUNT,
				max: MAX_FILE_COUNT,
			}));

			return;
		}

		mutation.mutate({
			relationDocumentId: selectedDocument.relationDocumentId,
			files: selectedFiles,
		});
	};

	return (
		<>
			{props.documents.length > 0 && (
				<TableRow>
					<TableCell className="all-unset">
						<Modal
							open={!!selectedDocument}
							onSubmit={handleSubmit}
							onCancel={handleCancel}
							buttonSubmitText={t("upload.modal.submit")}
							allowedToSubmit={canSubmit}
							loading={mutation.isLoading}
						>
							<Subtitle>
								{t("upload.modal.title", { filename: selectedDocument?.documentTypeName_EN ?? "file" })}
							</Subtitle>

							<FileUploader
								ref={fileUploaderRef}
								max={5}
								filesUpdateHandler={setSelectedFiles}
								files={selectedFiles}
								errors={errors}
								namespace="documents"
								className="mt-4"
								e2e="Documents.Upload"
							/>
						</Modal>
					</TableCell>
				</TableRow>
			)}

			{
				props.documents.map((document) => {
					const isUploaded = props.uploadedDocuments
						?.includes(document.relationDocumentId) && props.onUpload;
					return (
						<TableRow key={`requested-${document.relationDocumentId}`} e2e="User.Edit.DocumentUpload.Uploadable">
							<TableCell className={WARNING_CELL_STYLE}>
								{!isUploaded && <CiWarning size={24} className="text-warning" title={t("actions.upload")} />}
							</TableCell>

							<TableCell className="w-full">
								{i18n.language === "en" && document.documentTypeName_EN !== null ? document.documentTypeName_EN : document.documentTypeName_NL}
							</TableCell>

							<TableCell alignment="right" className={CELL_STYLE}>
								{
									isUploaded ? (<FaCheck className="text-success m-3" />) : (
										<IconButton
											e2e="User.Edit.DocumentUpload.Upload"
											onClick={(): void => handleSelectDocument(document)}
										>
											<FaUpload />
										</IconButton>
									)
								}
							</TableCell>
						</TableRow>
					);
				})
			}
		</>
	);
}
