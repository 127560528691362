import {
	useMutation, UseMutationResult, useQuery, UseQueryResult,
} from "react-query";
import api from "../axios/api";
import TicketResponse from "../models/types/api/ticket.type";
import useAuth from "../hooks/useAuth";
import TicketStatus from "../models/enums/ticket-status.enum";
import { TicketDetailResponse } from "../models/types/api/ticket-detail.type";
import { LocationResponse } from "../models/types/api/location.type";
import { CategoryResponse } from "../models/types/api/category.type";
import RemedyResponse from "../models/types/api/remedy.type";
import { useTranslation } from "react-i18next";
import SmallMaintenanceType from "../models/types/api/small-maintenance.type";

export function getTickets(): UseQueryResult<TicketResponse[], void> {
	const auth = useAuth();

	const ticketStatusValues = Object.values(TicketStatus);

	return useQuery(["tickets"], async (): Promise<TicketResponse[]> => {
		if (!auth.hasCapability(["Tickets"]))
			return [];

		const result = await api.get<TicketResponse[]>("/tickets");

		result.data.sort((ticketA, ticketB) => {
			const statusA = ticketStatusValues.indexOf(ticketA.statusId ?? "NIEUW");
			const statusB = ticketStatusValues.indexOf(ticketB.statusId ?? "NIEUW");

			return statusA - statusB;
		});

		return result.data;
	});
}

export function getTicket(ticketId: number): UseQueryResult<TicketDetailResponse, void> {
	return useQuery([ "ticket", ticketId ], async () => {
		const result = await api.get<TicketDetailResponse>(`/tickets/${ticketId}`);
		return result.data;
	});
}

export function getTicketLocations(unitId: number): UseQueryResult<LocationResponse[], void> {
	return useQuery([ "location", unitId ], async (): Promise<LocationResponse[]> => {
		const result = await api.get<LocationResponse[]>(`tickets/locations/${unitId}`);

		return result.data.filter(location => location.locationId && location.name);
	});
}

export function getTicketCategories(unitId: number): UseQueryResult<CategoryResponse[], void> {
	return useQuery([ "category", unitId ], async (): Promise<CategoryResponse[]> => {
		const result = await api.get<CategoryResponse[]>(`/tickets/categories/${unitId}/`);

		return result.data.filter(cat => cat.categoryId && cat.name);
	});
}

export function getTicketRemedies(categoryId: string): UseQueryResult<RemedyResponse[], void> {
	const { i18n } = useTranslation();

	return useQuery([ "remedies", categoryId ], async (): Promise<RemedyResponse[]> => {
		if (!categoryId)
			return [];

		const currentLanguage = i18n.language === "nl" ? "Dutch" : "English";

		const result = await api.get<RemedyResponse[]>(`/tickets/remedies/${categoryId}?categoryId=${categoryId}&language=${currentLanguage}`);

		return result.data;
	});
}

type TicketMessageForm = {
	message: string,
	files: File[]
}

export function sendTicketMessage(
	ticketId: number,
	options?: { onSuccess: () => void }
): UseMutationResult<void, void, TicketMessageForm, void> {
	return useMutation(async (form: TicketMessageForm): Promise<void> => {
		const files = form.files.reduce((previousValue, currentValue, index) => {
			return { ...previousValue, [index]: currentValue };
		}, {});

		await api.postForm(`/tickets/${ticketId}/message`, {
			message: JSON.stringify({
				message: form.message,
				ticketId,
			}),
			files,
		});
	}, options);
}

type TicketForm = {
	unitId: number,
	locationId: string,
	categoryId: string,
	title: string,
	description: string,
	files: File[]
}

export function sendTicket(): UseMutationResult<void, void, TicketForm, void> {
	return useMutation(async (form: TicketForm): Promise<void> => {
		const files = form.files.reduce((previousValue, currentValue, index) => {
			return { ...previousValue, [index]: currentValue };
		}, {});

		await api.postForm("/tickets", {
			ticket: JSON.stringify({
				unitId: form.unitId,
				locationId: form.locationId,
				categoryId: form.categoryId,
				title: form.title,
				description: form.description,
			}),
			files,
		});
	});
}

export function getOptionToSmallMaintenanceDocument() : UseQueryResult<SmallMaintenanceType, void> {
	return useQuery([], async (): Promise<SmallMaintenanceType> => {
		const result = await api.get<SmallMaintenanceType>("/tickets/configuration/");

		return result.data;
	});
}
