import React from "react";
import { ValidationError } from "../../models/types/validation.type";
import { useTranslation } from "react-i18next";
import E2EProp from "../../models/types/e2e.type";

type ErrorListProps = E2EProp & {
	errors: ValidationError[];
	namespace?: string;
}

type Value = {
	[key: string]: string
}

export default function ErrorList(props: ErrorListProps): JSX.Element {
	const { t, i18n } = useTranslation(props.namespace);

	const prefix = props.namespace ? "" : "public.validation";

	return (
		<ul className="text-sm text-error" {...props}>
			{props.errors.map((error) => {
				if (!error)
					return "";

				const [ message, context ] = typeof error === "string" ? [ error, {} ] : [
					error.key, ((): Value => {
						const keys = Object.keys(error.context) as (keyof typeof error.context)[];
						const values: Value = {};

						for (const key of keys) {
							if (!(typeof key === "string")) continue;

							const value = error.context[key];

							values[key] = i18n.exists(value) ? t(value) : value;
						}

						return values;
					})(),
				];

				const path = `${prefix}.${message}`;

				return (
					<li key={message}>
						{
							i18n.exists(path) ? t(path, context) : t(message)
						}
					</li>
				);
			})}
		</ul>
	);
}
