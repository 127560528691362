import React, { PropsWithChildren } from "react";
import Button from "../atoms/Button";
import { Link } from "react-router-dom";
import { LinkButtonProps } from "../../models/props/button.props";

export default function LinkButton(props: PropsWithChildren<LinkButtonProps>): JSX.Element {
	return (
		<Link to={props.href}>
			<Button
				variant={props.variant}
				disabled={props.disabled}
				className={props.className}
				e2e={props.e2e}
			>
				{props.children}
			</Button>
		</Link>
	);
}
