import React from "react";
import Loader from "../atoms/Loader";

type Props = {
	withLoadingText?: boolean;
}

export default function FullPageLoader(props: Props): JSX.Element {
	return (
		<div className="fixed top-0 left-0 flex flex-col justify-center items-center w-screen h-screen z-50">
			<Loader size="large" />

			{!!props.withLoadingText && (
				<p className="mt-7 text-slate-500">Loading...</p>
			)}
		</div>
	);
}
