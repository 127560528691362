import { useQuery, UseQueryResult } from "react-query";
import ManagementContractResponse from "../models/types/api/management-contract.type";
import api from "../axios/api";
import { DateTime } from "luxon";
import RentalInvoiceResponse from "../models/types/rental-invoice.type";
import useAuth from "../hooks/useAuth";

export function getManagementContracts(
): UseQueryResult<ManagementContractResponse[], void> {
	const auth = useAuth();

	return useQuery(["managementContracts"], async () => {
		if (!auth.hasCapability(["OwnerSettlements"]) && !auth.hasCapability(["SettleCosts"]))
			return [];

		const result = await api.get("/managementcontracts");

		return result.data as ManagementContractResponse[] ?? [];
	},
	{ enabled: false }
	);
}

export function getRentalInvoices(): UseQueryResult<RentalInvoiceResponse[], void> {
	const auth = useAuth();
	return useQuery(["ownerSettlements"],
		async (): Promise<RentalInvoiceResponse[]> => {
			if (!auth.hasCapability(["OwnerSettlements"]) && !auth.hasCapability(["SettleCosts"]))
				return [];

			const result = await api.get<RentalInvoiceResponse[]>("/managementcontracts/rentalcontracts/invoices?outstanding=true");

			return result.data.sort((invoiceA, invoiceB) => {
				const startdate = DateTime.fromISO(invoiceA.invoiceDate);
				const enddate = DateTime.fromISO(invoiceB.invoiceDate);
				return enddate.diff(startdate).seconds ?? [];
			});
		}, { enabled: false, suspense: true }
	);
}
