import { formatDateTimeToReadable } from "../../../../utils/formatDate";
import parseHtml from "html-react-parser";
import React, { useState } from "react";
import Image from "../../../atoms/Image";
import Modal from "../../../atoms/Modal";
import makeStyle from "../../../../style/makeStyle";
import { TicketMessage } from "../../../../models/types/api/ticket-detail.type";
import ChatAttachment from "./ChatAttachment";

type ChatBalloonProps = {
	ticketId: number;
	message: TicketMessage;
}

export default function ChatBalloon(props: ChatBalloonProps): JSX.Element {
	const [ selectedImage, setSelectedImage ] = useState<number>(NaN);

	const getFileUrl = (attachmentId: number): string => `/tickets/${props.ticketId}/documents/${attachmentId}`;

	const styles = makeStyle("bg-light w-4/6 rounded-md p-3", [props.message.correspondentType === "Huurder" ? "float-left" : "float-right"]);

	return (
		<div className="w-full inline-block my-3">
			{
				props.message.correspondentType === "Systeem" ? (
					<div className="block border-t text-center">
						<p className="text-xs leading-6">
							{formatDateTimeToReadable(props.message.createdOn)}
						</p>

						<p className="text-sm">
							{props.message.message}
						</p>
					</div>
				) : (
					<div className={styles}>
						<p className="text-primary font-semibold">
							{props.message.manager ? "Verhuurder" : props.message.relation}

							<span className="ml-2 text-sm">
								{formatDateTimeToReadable(
									props.message.createdOn
								)}
							</span>
						</p>

						<div className="break-words text-sm mt-2">
							{parseHtml(props.message.message ?? "")}
						</div>

						{props.message.attachments.length > 0 && (
							<div className="bg-gray-100 overflow-hidden rounded-md mt-2">
								{
									props.message.attachments.map(attachment => (
										<ChatAttachment
											attachment={attachment}
											ticketId={props.ticketId}
											onImageSelect={setSelectedImage}
											key={attachment.messageAttachmentId}
										/>
									))
								}
							</div>
						)}

						{ !isNaN(selectedImage) && props.message.attachments.length > 0 && (
							<Modal
								open={!isNaN(selectedImage)}
								onSubmit={(): void => setSelectedImage(NaN)}
								buttonSubmitText="Sluiten"
							>
								<div>
									<Image src={getFileUrl(selectedImage)} />
								</div>
							</Modal>
						)}
					</div>
				)
			}
		</div>
	);
}
