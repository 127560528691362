import React, {
	PropsWithChildren, useEffect, useState,
} from "react";
import Paper from "../components/atoms/Paper";
import Title from "../components/atoms/Title";
import useEnvironment from "../hooks/useEnvironment";
import { useNavigate, useParams } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import SelectInput from "../components/atoms/input/SelectInput";
import SelectOption from "../components/atoms/input/SelectOption";
import Logo from "../components/atoms/Logo";
import PrivacyDisclaimerLink from "../components/atoms/PrivacyDisclaimerLink";

type PublicLayoutProps = {
	title?: string;
}

export default function PublicLayout(props: PropsWithChildren<PublicLayoutProps>): JSX.Element {
	const [ findHostIsLoaded, setFindHostIsLoaded ] = useState(false);

	const env = useEnvironment();
	const { environment: portalEnvironment } = useParams();
	const navigate = useNavigate();
	const { t, i18n } = useTranslation("publiclayout");

	useEffect(() => {
		if (!portalEnvironment)
			return;

		env.init(portalEnvironment)
			.then(() => {
				setFindHostIsLoaded(true);
			})
			.catch(() => {
				navigate("/error/environment");
			});

	}, []);

	const handleOption = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		void i18next.changeLanguage(event.target.value);
	};

	return (
		<div className="flex flex-col justify-center items-center h-screen">
			<Paper className="w-11/12 md:w-1/3">
				<div className="flex flex-col gap-2">
					{!!props.title && (
						<Title>
							{props.title}
						</Title>
					)}

					{props.children}

					<div className="mt-2">
						<SelectInput label={t("select_language")} onChange={handleOption} value={i18n.language}>
							<SelectOption value="en">
								English
							</SelectOption>

							<SelectOption value="nl">
								Nederlands
							</SelectOption>
						</SelectInput>
					</div>
				</div>
			</Paper>

			<div className="max-w-small ">
				{!!findHostIsLoaded && <Logo size="max-w-small" environment={portalEnvironment ?? ""} />}
			</div>

			{!!findHostIsLoaded && <PrivacyDisclaimerLink environment={portalEnvironment ?? ""} />}
		</div>
	);
}
