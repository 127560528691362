import React from "react";
import SelectInput from "../atoms/input/SelectInput";
import SelectOption from "../atoms/input/SelectOption";
import useCountries from "../../hooks/useCountries";
import { useTranslation } from "react-i18next";
import CountryResponse from "../../models/types/api/country.type";

type CountrySelectorProps = {
	label: string;
	value?: string;
	disabled?: boolean;
	onChange: (value: string) => void;
	required?: boolean;
	visible?: boolean;
	e2e?: string;
}

export default function CountrySelector(props: CountrySelectorProps): JSX.Element {
	const countries = useCountries();

	const { i18n } = useTranslation();

	const handleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		props.onChange(event.target.value);
	};

	const sortAlphabetically = (a: CountryResponse, b: CountryResponse):
	number => {
		const reA = /[^a-zA-Z]/g;
		const reN = /[^0-9]/g;
		const aA = a.nameNL.replace(reA, "");
		const bA = b.nameNL.replace(reA, "");
		if (aA === bA) {
			const aN = parseInt(a.nameNL.replace(reN, ""), 10);
			const bN = parseInt(b.nameNL.replace(reN, ""), 10);
			return aN === bN ? 0 : aN > bN ? 1 : -1;
		}
		return aA > bA ? 1 : -1;
	};

	return props.visible || props.visible === undefined || props.visible === null ? (
		<SelectInput
			label={props.label}
			value={props.value}
			onChange={handleChange}
			disabled={props.disabled}
			required={props.required}
			e2e={props.e2e}
		>
			{countries.availableCountries.sort(sortAlphabetically).map(country => (
				<SelectOption value={country.nameNL} key={country.nameNL}>
					{i18n.language.includes("nl") ? country.nameNL : country.nameEN}
				</SelectOption>
			))}
		</SelectInput>
	) : <div />;
}
