import React, {
	createContext,
	PropsWithChildren,
	useContext,
	useEffect, useState,
} from "react";
import api from "../axios/api";
import config from "../config";
import axios, { AxiosInstance } from "axios";
import FindHostResponse from "../models/types/api/findhost.type";

type ContextProps = {
	initialized: boolean;
	init: (environment: string) => Promise<void>;
	getBaseUrl: () => string | null;
	getEnvironment: () => string;
};

let currentHost = window.location.hostname.toLowerCase().trim();

// Define a mapping for the special cases
const hostMapping: { [key: string]: string } = {
	"mijnaccount.brixxonline.com": "oak.brixxonline.com",
	"mijnaccount.vbsonline.nl": "vas1.vbsonline.nl",
};

const developmentHostMapping: { [key: string]: string } = {
	"mijnaccount.development.brixxonline.com": "https://test.brixxonline.com/api/development",
	"mijnaccount.productie.brixxonline.com": "https://test.brixxonline.com",
	"mijnaccount.acceptatie.brixxonline.com": "https://test.brixxonline.com",
};

const isDTAPEnvironment = (host: string): boolean => {
	return Object.prototype.hasOwnProperty.call(developmentHostMapping, host);
};

// If the current host is in the mapping, use the mapped value
if (Object.prototype.hasOwnProperty.call(hostMapping, currentHost)) {
	currentHost = hostMapping[currentHost as keyof typeof hostMapping];
}

let discoveryApi: AxiosInstance;

if (isDTAPEnvironment(currentHost)) {
	const url = developmentHostMapping[currentHost];
	discoveryApi = axios.create(
		{ baseURL: url });
} else {
	discoveryApi = axios.create({ baseURL: `https://${currentHost}/api/v1/` } );
}

const EnvironmentContext = createContext<ContextProps>({} as ContextProps);

export const STORAGE_KEY_URL = "url";
export const STORAGE_KEY_ENV = "environment";

export function EnvironmentProvider(props: PropsWithChildren): JSX.Element {

	const [ initialized, setInitialized ] = useState(false);

	useEffect(() => {
		const url = getBaseUrl();

		if (url) setBaseUrl(url);
	}, []);

	const init = async (environment: string): Promise<void> => {

		setEnvironment(environment);

		const sessionUrl = sessionStorage.getItem(STORAGE_KEY_URL);
		if (sessionUrl) {
			return;
		}

		if (config.useLocalApi) {
			setBaseUrl(config.localApiUrl);
			return;
		}

		const response = await discoveryApi.get<FindHostResponse>(`/${environment}/routing/findhost`);
		const data = response.data;

		if (!data.hostFound)
			return Promise.reject(new Error());

		const baseUrl = `${config.apiPrefix}https://${data.host}/api/v1/`;
		setBaseUrl(baseUrl);
	};

	const getBaseUrl = (): string =>
		sessionStorage.getItem(STORAGE_KEY_URL) ?? "";

	const setBaseUrl = (url: string): void => {
		const baseUrl = api.defaults.baseURL;

		if (baseUrl !== url) {
			sessionStorage.setItem(STORAGE_KEY_URL, url);
			api.defaults.baseURL = url;
		}

		setInitialized(true);
	};

	const getEnvironment = (): string => {
		return sessionStorage.getItem(STORAGE_KEY_ENV) ?? "";
	};

	const setEnvironment = (newEnvironment: string): void => {
		sessionStorage.setItem(STORAGE_KEY_ENV, newEnvironment);
	};

	return (
		<EnvironmentContext.Provider
			value={{
				initialized,
				init,
				getEnvironment,
				getBaseUrl,
			}}
		>
			{props.children}
		</EnvironmentContext.Provider>
	);
}

export default function useEnvironment(): ContextProps {
	return useContext(EnvironmentContext);
}
