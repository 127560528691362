import React from "react";
import AuthenticatedLayout from "../../layouts/AuthenticatedLayout";
import { useTranslation } from "react-i18next";
import { getTickets } from "../../queries/tickets";
import ContentLoader from "../../components/molecules/ContentLoader";
import Alert from "../../components/atoms/Alert";
import Table from "../../components/atoms/table/Table";
import TableHead from "../../components/atoms/table/TableHead";
import TableRow from "../../components/atoms/table/TableRow";
import TableCell from "../../components/atoms/table/TableCell";
import TableBody from "../../components/atoms/table/TableBody";
import { formatDateToReadable } from "../../utils/formatDate";
import IconButton from "../../components/atoms/IconButton";
import { HiOutlineArrowUpRight } from "react-icons/hi2";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/atoms/Button";

export default function TicketsOverviewPage(): JSX.Element {
	const { t } = useTranslation([ "tickets", "translation" ]);

	const navigate = useNavigate();
	const tickets = getTickets();

	const handleNewTicket = (): void => {
		navigate("/ticket/new");
	};

	return (
		<AuthenticatedLayout
			title={t("title")}
			showManagementContracts
			actions={[
				<div className="flex justify-end mb-4" key="create-ticket-button">
					<Button variant="info" onClick={handleNewTicket}>
						{t("new.text")}
					</Button>
				</div>,
			]}
		>
			{tickets.isLoading
				? <ContentLoader /> : tickets.error ? (
					<Alert variant="error">
						{t("default.error")}
					</Alert>
				) : (tickets.data && tickets.data.length <= 0 ? (
					<Alert variant="info" className="mb-5">
						{t("errors.no_tickets")}
					</Alert>
				) : (
					<>
						{tickets.isLoading
							? ( <ContentLoader /> ) : !tickets.data || tickets.data?.length <= 0 ? (
								<Alert variant="info">
									{t("translation:public.tickets.not_found")}
								</Alert>
							) : (
								<Table>
									<TableHead>
										<TableRow>
											<TableCell header>
												{t("stats.ticketId")}
											</TableCell>

											<TableCell header>
												{t("stats.status")}
											</TableCell>

											<TableCell header>
												{t("stats.title")}
											</TableCell>

											<TableCell header>
												{t("stats.location")}
											</TableCell>

											<TableCell header>
												{t("stats.category")}
											</TableCell>

											<TableCell header>
												{t("stats.date")}
											</TableCell>

											<TableCell header alignment="right">
												{t("stats.show")}
											</TableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										{
											tickets.data?.map(ticket => (
												<TableRow key={ticket.ticketId}>

													<TableCell>
														{ticket.ticketId}
													</TableCell>

													<TableCell>
														{t(`status.${ticket.statusId ?? "NIEUW"}`)}
													</TableCell>

													<TableCell>
														{ticket.title}
													</TableCell>

													<TableCell>
														{t(`location.${ticket.locationId ?? "OVERIG"}`)}
													</TableCell>

													<TableCell>
														{t(`category.${ticket.categoryId ?? "OVERIG"}`)}
													</TableCell>

													<TableCell>
														{formatDateToReadable(ticket.createdOn)}
													</TableCell>

													<TableCell alignment="right">
														<Link to={`/ticket/${ticket.ticketId}`}>
															<IconButton>
																<HiOutlineArrowUpRight />
															</IconButton>
														</Link>
													</TableCell>
												</TableRow>
											))
										}
									</TableBody>
								</Table>
							)}
					</>
				))}
		</AuthenticatedLayout>
	);
}
