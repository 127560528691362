import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";

type ListItemProps = {
	onClick?: () => void;
	link?: string;
}

export default function ListItem(props: PropsWithChildren<ListItemProps>): JSX.Element {
	if (props.link) {
		return (
			<Link to={props.link}>
				<InnerListItem {...props} />
			</Link>
		);
	}

	return <InnerListItem {...props} />;
}

function InnerListItem(props: PropsWithChildren<ListItemProps>): JSX.Element {
	const handleClick = (): void => {
		if (props.onClick && !props.link)
			props.onClick();
	};

	return (
		<li onClick={handleClick} className="hover:bg-gray-100 p-2 transition-colors border-t flex flex-row items-center gap-2">
			{props.children}
		</li>
	);
}
