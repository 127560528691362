export default function joinElements(
	elements: JSX.Element[],
	joiner: (index: number) => JSX.Element,
): JSX.Element[] {
	if (elements.length <= 1) return elements;

	const result = [elements[0]];

	for (let i = 1; i < elements.length; i++) {
		const elem = joiner(i - 1);

		result.push(elem, elements[i]);
	}

	return result;
}
