import { useQuery, UseQueryResult } from "react-query";
import api from "../axios/api";
import OwnerManagementContractResponse from "../models/types/api/owner-management-contract.type";
import OwnerManagementContractTicket from "../models/types/api/owner-management-contract-ticket";
import { TicketDetailResponse } from "../models/types/api/ticket-detail.type";

export function getOwnerManagementContracts():
UseQueryResult<OwnerManagementContractResponse[], void> {
	return useQuery("ownerManagementContracts", async (): Promise<OwnerManagementContractResponse[]> => {

		const result = await api.get("/ownertickets/list/");

		return result.data as OwnerManagementContractResponse[];
	}, { enabled: false, suspense: true });
}

export function getOwnerManagementContractTickets(managementContractId: number):
UseQueryResult<OwnerManagementContractTicket[], void> {
	return useQuery("ownerManagementContractTickets", async (): Promise<OwnerManagementContractTicket[]> => {

		const result = await api.get(`/ownertickets/tickets/${managementContractId}`);

		return result.data as OwnerManagementContractTicket[];
	}, { enabled: false, suspense: true });
}

export function getOwnerManagementContractTicket(
	ownerManagementContractId: number,
	ticketId: number):
	UseQueryResult<TicketDetailResponse, void> {
	return useQuery("ownerManagementContractTicket", async (): Promise<TicketDetailResponse> => {

		const result = await api.get(`/ownertickets/tickets/${ownerManagementContractId}/${ticketId}`);

		return result.data as TicketDetailResponse;
	}, { enabled: true, suspense: true });
}
