import React, {
	ChangeEventHandler,
	forwardRef,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { FaUpload } from "react-icons/fa";
import makeStyle from "../../style/makeStyle";
import FileList from "../atoms/FileList";
import ErrorList from "../atoms/ErrorList";

export type FileUploaderActions = {
	startUpload: () => void;
}

type FileUploaderProps = {
	max?: number;
	accept?: string[];
	className?: string;
	filesUpdateHandler: (files: File[]) => void;
	files: File[];
	errors?: string[];
	namespace?: string;
	disabled?: boolean;
	e2e?: string;
}

// Als deze eslint rule hier wordt toegepast, gaat de prefer-arrow-callback
// rule zeuren, welke alleen op deze manier is op te lossen
// eslint-disable-next-line react/display-name
const FileUploader = forwardRef<FileUploaderActions, FileUploaderProps>((props, ref) => {
	const maxFiles = props.max ?? 1;
	const multiple = maxFiles > 1;
	const {
		filesUpdateHandler,
		...inputProps // This will contain all other props except those destructured
	} = props;

	const { t } = useTranslation("components");
	const fileInputRef = useRef<HTMLInputElement>(null);

	const [ hoveringFileUpload, setHoveringFileUpload ] = useState(false);

	const startUpload = (): void => {
		setTimeout(() => {
			fileInputRef.current?.click();
		}, 0);
	};

	useImperativeHandle(ref, () => ({ startUpload }));

	const handleFilesChange: ChangeEventHandler<HTMLInputElement> = (event) => {
		setHoveringFileUpload(false);
		const files = [
			...(event.target.files ?? []),
			...(props.files.length < (props.max ?? 1) ? props.files : []),
		];
		event.target.value = "";
		props.filesUpdateHandler(files);
	};

	const handleDelete = (index: number): void => {
		const files = [...props.files];

		files.splice(index, 1);

		props.filesUpdateHandler(files);
	};

	const dragAndDropStyle = makeStyle("relative p-2 mb-2 border border-dashed rounded", [hoveringFileUpload && "bg-slate-100"]);

	return (
		<div className={props.className}>
			<div className={dragAndDropStyle}>
				<input
					{...inputProps}
					ref={fileInputRef}
					type="file"
					title={t("file_uploader.title")}
					multiple={multiple}
					onChange={handleFilesChange}
					onDragEnter={(): void => setHoveringFileUpload(true)}
					onDragLeave={(): void => setHoveringFileUpload(false)}
					accept={props.accept?.join(", ")}
					className="absolute inset-0 w-full opacity-0 z-10 cursor-pointer"
					disabled={props.disabled}
				/>

				<div className="flex flex-col text-center items-center justify-center p-2 opacity-50 select-none">
					<FaUpload />

					<p className="mt-2 color-primary">
						{hoveringFileUpload ? t("file_uploader.drop") : t("file_uploader.title")}
					</p>
				</div>
			</div>

			<FileList files={props.files} onDelete={handleDelete} />

			{!!props.errors && (
				<ErrorList errors={props.errors} namespace={props.namespace} e2e="ErrorList" />
			)}
		</div>
	);
});

export default FileUploader;
