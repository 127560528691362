import React from "react";
import { CategoryResponse } from "../../../../models/types/api/category.type";
import { getTicketCategories } from "../../../../queries/tickets";
import ContentLoader from "../../../molecules/ContentLoader";
import ListSelector, { ListSelectorElement } from "../../../molecules/ListSelector";
import { useTranslation } from "react-i18next";

type Props = {
	unitId: number;
	selected?: CategoryResponse | null;
	onSelect: (category: CategoryResponse) => void;
}

const CATEGORIES_PNG_ICON = [
	"GAS", "HUISHOUDELIJKEAPPARATEN", "LEKKAGE", "MEUBELS",
];

export default function SelectCategory(props: Props): JSX.Element {
	const { t } = useTranslation("tickets");

	const { data } = getTicketCategories(props.unitId);

	if (!data) return <ContentLoader />;

	return (
		<ListSelector
			keyPrefix="ticketCategory"
			searchLabel={t("new.steps.problem_type.search")}
			default={props.selected?.name}
			items={data.map(category => ({
				...category,
				searchKey: category.name,
				icon: `/icons/categories/${category.categoryId}.${
					CATEGORIES_PNG_ICON.includes(category.categoryId) ? "png" : "svg"
				}`,
			}))}
			onSelect={props.onSelect}
			highlightSelected
		>
			{(item): ListSelectorElement => ({
				key: item.categoryId,
				element: (
					<p>
						{t(`category.${item.categoryId}`)}
					</p>
				),
			})}
		</ListSelector>
	);
}
