import React, { useState } from "react";
import Image from "../../../atoms/Image";
import { FaDownload } from "react-icons/fa";
import Loader from "../../../atoms/Loader";
import { TicketMessageAttachment } from "../../../../models/types/api/ticket-detail.type";
import { openFile } from "../../../../utils/file";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

type ChatAttachmentProps = {
	attachment: TicketMessageAttachment;
	ticketId: number;
	onImageSelect: (messageAttachmentId: number) => void;
}

export default function ChatAttachment(props: ChatAttachmentProps): JSX.Element {
	const [ loading, setLoading ] = useState(false);

	const { t } = useTranslation();

	const url = `/tickets/${props.ticketId}/documents/${props.attachment.messageAttachmentId}`;

	const handleDownload = (): void => {
		setLoading(true);

		openFile(url)
			.catch(() => {
				toast.error(t("public.tickets.errors.failedFileDownload"));
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div key={props.attachment.messageAttachmentId} className="hover:bg-gray-300 cursor-pointer py-1 px-2 transition-colors">
			{props.attachment.contentType === "image/jpeg" ? (
				<div
					onClick={(): void => {
						props.onImageSelect(
							props.attachment.messageAttachmentId
						);
					}}
				>
					<Image className="w-full h-32 object-cover" src={url} />
				</div>
			) : (
				<div
					className="flex items-center py-2"
					onClick={handleDownload}
				>
					<p className="text-sm inline">
						{props.attachment.fileName}
					</p>

					<div className="inline ml-auto">
						{
							loading
								? <Loader size="small" /> : <FaDownload />
						}
					</div>
				</div>
			)}
		</div>
	);
}
