import React from "react";
import { DateTime, ToISOTimeOptions } from "luxon";
import { DateTimeInputProps } from "../../../models/props/input.props";
import Input from "./Input";

const LUXON_OPTIONS: ToISOTimeOptions = {
	suppressMilliseconds: true,
	suppressSeconds: true,
	includeOffset: false,
};

export default function DateTimeInput(props: DateTimeInputProps): JSX.Element {
	const getType = (): "date" | "datetime-local" | "time" => {
		if (props.type === "datetime") return "datetime-local";

		return props.type;
	};

	const getValue = (): string | number => {
		if (!props.value) return "";
		if (!props.value.isValid) return "";
		const dateTime = props.value.toISO(LUXON_OPTIONS);

		switch (props.type) {
			case "date": return dateTime.split("T")[0];
			case "time": return dateTime.split("T")[1].split("Z")[0];
			case "datetime": return dateTime.split("Z")[0];
		}
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.onChange?.(DateTime.fromISO(event.target.value));
	};

	return (
		<Input
			{...props}
			type={getType()}
			value={getValue()}
			onChange={handleChange}
		/>
	);
}
