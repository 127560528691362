import React, { Suspense } from "react";
import {
	createBrowserRouter, Navigate, redirect, RouterProvider,
} from "react-router-dom";
import FullPageLoader from "./components/molecules/FullPageLoader";
import useAuth from "./hooks/useAuth";
import useEnvironment from "./hooks/useEnvironment";
import publicRoutes from "./routes";
import { Capability } from "./models/types/auth.type";

type AuthChecker = () => void;

export type Route = {
	path: string;
	element: JSX.Element;
	requiredCapability?: Capability[];
	requiresAuth?: boolean;
}

export default function Router(): JSX.Element {
	const auth = useAuth();
	const environment = useEnvironment();

	const getAuthChecker = (
		requiresAuth: boolean,
		capability?: Capability[]
	): AuthChecker | undefined => {
		if (!requiresAuth && !capability) return undefined;

		return () => {
			if (!auth.authenticated()) {
				const env = environment.getEnvironment();

				if (!env) throw new Error("No environment specified");
				else throw redirect(`/${env}/auth/signin/`);
			}

			if (capability && !auth.hasCapability(capability))
				throw redirect("/");

			return {};
		};
	};

	const router = createBrowserRouter(
		publicRoutes.map(route => ({
			path: route.path,
			element: route.element,
			loader: getAuthChecker(!!route.requiresAuth, route.requiredCapability),
			errorElement: <Navigate replace to="/error/environment" />,
		}))
	);

	return (
		<Suspense fallback={<FullPageLoader withLoadingText />}>
			<RouterProvider router={router} />
		</Suspense>
	);
}
