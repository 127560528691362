import React, { PropsWithChildren } from "react";

type TableRowProps = {
	e2e?: string;
} & PropsWithChildren;

export default function TableRow(props: TableRowProps): JSX.Element {
	return (
		<tr className="border-zinc-200 border-b" {...props}>
			{props.children}
		</tr>
	);
}
