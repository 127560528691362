import React, {
	createContext,
	PropsWithChildren,
	useContext,
	useEffect,
	useState,
} from "react";
import api from "../axios/api";
import useAuth from "./useAuth";
import useEnvironment from "./useEnvironment";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import CountryPhoneCodeType from "../models/types/api/country-phone-code.type";

type ContextProps = {
	countryPhoneCodes: CountryPhoneCodeType[];
	isLoading: boolean;
	init: () => void;
};

const CountryContext = createContext<ContextProps>({} as ContextProps);

export function CountryPhoneCodeProvider(props: PropsWithChildren): JSX.Element {
	const [ countryPhoneCodes, setCountryPhoneCodes ] = useState<CountryPhoneCodeType[]>([]);
	const [ isLoading, setIsLoading ] = useState(true);

	const { t } = useTranslation();
	const auth = useAuth();
	const environment = useEnvironment();

	useEffect(() => {
		if (auth.authenticated()) {
			init();
		}
	}, [auth.authenticated()]);

	const init = (): void => {
		const baseUrl = environment.getBaseUrl();

		if (baseUrl === null) return;

		setIsLoading(true);
		api.get("/tables/countryphonecodes")
			.then((result) => {
				setCountryPhoneCodes(result.data as CountryPhoneCodeType[]);
				setIsLoading(false);
			})
			.catch((error) => {
				toast.warning(t("public.countries.error"));
				setIsLoading(false);
			});
	};

	return (
		<CountryContext.Provider
			value={{
				countryPhoneCodes,
				isLoading,
				init,
			}}
		>
			{props.children}
		</CountryContext.Provider>
	);
}

export default function useCountryPhoneCodes(): ContextProps {
	return useContext(CountryContext);
}
