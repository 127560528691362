import React from "react";

type SelectOptionProps = {
	value: string;
	children: string;
}

export default function SelectOption(props: SelectOptionProps): JSX.Element {
	return (
		<option value={props.value}>
			{props.children}
		</option>
	);
}
