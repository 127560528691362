import { TextAreaProps } from "../../../models/props/input.props";
import React from "react";
import makeStyle from "../../../style/makeStyle";
import ErrorList from "../ErrorList";

export default function TextArea(props: TextAreaProps<string>): JSX.Element {
	const style = makeStyle("input h-full", [props.errors ? "border-error" : "border-slate-300"]);
	const containerStyle = makeStyle("flex flex-col", [props.className]);

	return (
		<div className={containerStyle}>
			<label>
				{props.label}
			</label>

			<textarea
				{...props}
				className={style}
			/>

			{
				!!props.errors && Array.isArray(props.errors) && <ErrorList errors={props.errors} />
			}

		</div>
	);
}
