import {
	useMutation, UseMutationResult, useQuery, UseQueryResult,
} from "react-query";
import api from "../axios/api";
import {
	DocumentQueryResult,
	RequestedDocumentResponse,
	ReviewDocumentResponse,
	SubmittedDocumentResponse,
} from "../models/types/api/documents.type";
import ReviewDocumentForm from "../models/forms/review-document.form";
import { AxiosError } from "axios";
import ResponseError, { FormError } from "../models/types/error.type";
import DocumentUploadForm from "../models/forms/document-upload.form";
import bodyToError from "../utils/bodyToError";

type QueryResult = UseQueryResult<DocumentQueryResult, null>;

export function getDocuments(): QueryResult {
	return useQuery("documents", async (): Promise<DocumentQueryResult> => {
		const [
			requested, submitted, review,
		] = await Promise.all([
			api.get<RequestedDocumentResponse[]>("/documents/requested/"),
			api.get<SubmittedDocumentResponse[]>("/documents/submitted/"),
			api.get<ReviewDocumentResponse[]>("/documents/review/"),
		]);

		return {
			requested: requested.data ?? [],
			submitted: submitted.data ?? [],
			review: review.data ?? [],
		};
	});
}

export function getRequestedDocuments(): UseQueryResult<RequestedDocumentResponse[]> {
	return useQuery("requestedDocuments", async (): Promise<RequestedDocumentResponse[]> => {
		const result = await api.get<RequestedDocumentResponse[]>("/documents/requested");

		return result.data;
	});
}

export function reviewDocument(
	options: { onSuccess: () => void | Promise<void> }
): UseMutationResult<void, FormError<object>, ReviewDocumentForm> {
	return useMutation(async (body: ReviewDocumentForm): Promise<void> => {
		try {
			await api.post("/documents/review", body);
		} catch (error) {
			const formError: FormError<object> = {};

			if (error instanceof AxiosError && error.response?.status === 400)
				formError.query = "documents.review.error";
			else
				formError.query = "default.error";

			return Promise.reject(formError);
		}
	}, options);
}

export function uploadDocument(
	options: { onSuccess: () => void | Promise<void> }
): UseMutationResult<void, string[], DocumentUploadForm, void> {
	return useMutation(async (form: DocumentUploadForm): Promise<void> => {
		try {
			const formData = new FormData();
			const documentId = form.relationDocumentId.toString();

			for (const file of form.files)
				formData.append(documentId, file);

			await api.post("/documents/submitted", formData, { headers: { "Content-Type": "multipart/form-data" } });
		} catch (error) {
			if (error instanceof AxiosError && error.response?.status === 400) {
				const bodyErrors = bodyToError(error.response.data as ResponseError<object>);

				const errors = Object.keys(bodyErrors)
					.map(key => `upload.errors.${key}`);

				return Promise.reject(errors);
			}

			const errors = ["default.error"];
			return Promise.reject(errors);
		}
	}, options);
}
