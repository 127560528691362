import React, { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import useEnvironment from "../../hooks/useEnvironment";
import { Navigate, useSearchParams } from "react-router-dom";
import { useQueryClient } from "react-query";

export default function SignOutPage(): JSX.Element {
	const auth = useAuth();
	const environment = useEnvironment();

	const [searchParams] = useSearchParams();
	const queryClient = useQueryClient();

	useEffect(() => {
		queryClient.removeQueries();

		auth.signOut();
	}, []);

	const currentEnvironment = environment.getEnvironment();
	const error = searchParams.has("expired") ? "?expired" : "";

	return <Navigate to={currentEnvironment ? `/${currentEnvironment}/auth/signin${error}` : "/error/environment"} />;
}
