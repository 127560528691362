import React, {
	useEffect, useRef, useState,
} from "react";
import AuthenticatedLayout from "../../layouts/AuthenticatedLayout";
import Stepper from "../../components/atoms/Stepper";
import Step from "../../components/atoms/Stepper/Step";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import EditUserForm from "../../components/organisms/forms/user/EditUserForm";
import { UserFormActions, UserFormProvider } from "../../hooks/useUserForm";
import StepperFooter from "../../components/atoms/Stepper/StepperFooter";
import { updateUser } from "../../queries/user";
import UserResponse from "../../models/types/api/user.type";
import { toast } from "react-toastify";
import RequestedDocuments from "../../components/organisms/documents/RequestedDocuments";
import { getRequestedDocuments } from "../../queries/documents";
import Table from "../../components/atoms/table/Table";
import TableBody from "../../components/atoms/table/TableBody";
import useAuth from "../../hooks/useAuth";
import TableHead from "../../components/atoms/table/TableHead";
import TableRow from "../../components/atoms/table/TableRow";
import TableCell from "../../components/atoms/table/TableCell";
import Button from "../../components/atoms/Button";
import DropdownOption from "../../components/atoms/dropdown/DropdownOption";
import i18next from "i18next";
import Dropdown from "../../components/atoms/dropdown/DropDown";
import useEnvironment from "../../hooks/useEnvironment";

const ONBOARDING_STEPS = [ "profile", "documents" ] as const;
type OnboardingSteps = typeof ONBOARDING_STEPS;

export default function OnboardingPage(): JSX.Element {
	const [ currentStep, setCurrentStep ] = useState(0);
	const [ uploadedDocuments, setUploadedDocuments ] = useState<number[]>([]);

	const { t } = useTranslation();
	const env = useEnvironment().getEnvironment();

	const userForm = useRef<UserFormActions>(null);
	const navigate = useNavigate();
	const { step } = useParams();

	const auth = useAuth();

	const requestedDocuments = getRequestedDocuments();

	const profileMutation = updateUser(env, {
		onSuccess: () => {
			toast.success(t("public.onboarding.profile.success"));

			if (!amountOfDocumentsToUpload) {
				navigate("/");
				return;
			}
			setCurrentStep(currentStep + 1);
		},
	});

	if (!auth.isUserCandidate()) {
		navigate("/");
	}

	useEffect(() => {
		if (!ONBOARDING_STEPS.includes(step as OnboardingSteps[0]) || auth.isUserCandidate()) {
			navigate("/onboarding/profile");
			return;
		}

		setCurrentStep(ONBOARDING_STEPS.indexOf(step as OnboardingSteps[0]));
	}, []);

	useEffect(() => {
		navigate(`/onboarding/${ONBOARDING_STEPS[currentStep] ?? "profile"}`);
	}, [currentStep]);

	const handlePrevious = (): void => {
		const value = currentStep - 1;

		if (value >= 0)
			setCurrentStep(value);
	};

	const handleProfileSubmit = (form: UserResponse): void => {
		profileMutation.mutate(form);
	};

	const handleSubmitForm = (): void => {
		userForm.current?.submitForm();
	};

	const saveRelationInformationOnly = (): void => {
		userForm.current?.submitForm();
	};

	const handleUploadedDocument = (id: number): void => {
		setUploadedDocuments(previousState => [ ...previousState, id ]);
	};

	const handleFinish = (): void => {
		auth.refreshActions()
			.finally(() => {
				localStorage.setItem("isCandidate", "false");
				navigate("/");
			});
	};

	const handleOption = (language: string): void => {
		void i18next.changeLanguage(language);
	};

	const [amountOfDocumentsToUpload] = useState(requestedDocuments.data?.length ?? 0 > 0);
	const [amountOfDocuments] = useState(requestedDocuments.data?.length ?? 0);

	const stepperTitles = [ t("public.onboarding.profile.title"), amountOfDocumentsToUpload ? t("public.onboarding.documents.title") : "" ];
	const stepperNextActions = [handleSubmitForm];
	const stepperCanGoNext = [
		true,
		(!requestedDocuments.data || (amountOfDocumentsToUpload > 0)),
	];

	return (
		<AuthenticatedLayout
			title="Onboarding"
			actions={[
				<div key="languageSelector" className="bg-primary">
					<Dropdown title={t("public.onboarding.changeLanguage")}>
						<DropdownOption onClick={handleOption} optionValue="en">
							English
						</DropdownOption>

						<DropdownOption onClick={handleOption} optionValue="nl">
							Nederlands
						</DropdownOption>
					</Dropdown>
				</div>,
			]}
		>
			{amountOfDocumentsToUpload ? (
				<>

					<Stepper
						titles={stepperTitles}
						current={currentStep}
					>
						<Step>
							<UserFormProvider
								onSubmit={handleProfileSubmit}
								queryError={profileMutation.error?.query}
								validationError={profileMutation.error?.validation}
								ref={userForm}
							>
								<EditUserForm />
							</UserFormProvider>
						</Step>

						<Step>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell />

										<TableCell>
											{t("public.onboarding.documents.document_type")}
										</TableCell>

										<TableCell alignment="right">
											{t("public.onboarding.documents.upload")}
										</TableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									<RequestedDocuments
										documents={requestedDocuments.data ?? []}
										onUpload={handleUploadedDocument}
										uploadedDocuments={uploadedDocuments}
									/>
								</TableBody>
							</Table>

						</Step>
					</Stepper>

					<StepperFooter
						currentStep={currentStep}
						lastStep={ONBOARDING_STEPS.length}
						onPrevious={handlePrevious}
						onNext={stepperNextActions[currentStep]}
						onSubmit={handleFinish}
						loading={profileMutation.isLoading}
						canSave={uploadedDocuments.length >= amountOfDocuments}
						canGoNext={stepperCanGoNext[currentStep]}
					/>
				</>
			) : (
				<>
					<UserFormProvider
						onSubmit={handleProfileSubmit}
						queryError={profileMutation.error?.query}
						validationError={profileMutation.error?.validation}
						ref={userForm}
					>
						<EditUserForm />
					</UserFormProvider>

					<div className="flex flex-col gap-form items-center mt-4">
						<Button onClick={saveRelationInformationOnly} e2e="User.Edit.Submit">
							{t("public.onboarding.finishedOnboarding")}
						</Button>
					</div>
				</>
			)}
		</AuthenticatedLayout>
	);
}
