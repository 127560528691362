import React from "react";
import TableRow from "../../atoms/table/TableRow";
import TableCell from "../../atoms/table/TableCell";
import IconButton from "../../atoms/IconButton";
import downloadDocument from "../../../utils/downloadDocument";
import { FaDownload } from "react-icons/fa";
import { CELL_STYLE, WARNING_CELL_STYLE } from "../../../pages/DocumentsPage";
import { SubmittedDocumentResponse } from "../../../models/types/api/documents.type";

type SubmittedDocumentsProps = {
	documents: SubmittedDocumentResponse[];
	requiredActionCount: number;
}

export default function SubmittedDocuments(props: SubmittedDocumentsProps): JSX.Element {
	return (
		<>
			{props.documents.map(document => (
				<TableRow key={`submitted-${document.fileName ?? `${document.documentId}-${document.size}`}`}>
					{props.requiredActionCount > 0 && (
						<TableCell className={WARNING_CELL_STYLE} />
					)}

					<TableCell className="w-full">
						{document.fileName ?? "Bestand"}
					</TableCell>

					<TableCell alignment="right" className={CELL_STYLE}>
						<IconButton onClick={(): void => downloadDocument("submitted", document.documentId)}>
							<FaDownload />
						</IconButton>
					</TableCell>
				</TableRow>
			))}
		</>
	);
}
