export default function formatNumber(number: number, addedPrefix?:string): string {
	let prefix = "";
	if (addedPrefix !== undefined) {
		prefix = `${addedPrefix}`;
	}
	if (number < 0) {
		number = Math.abs(number);
		prefix = `-${prefix}`;

	}
	if (number === 0) {
		return "0,00";
	}

	const splitNumber = number.toFixed(2).toString()
		.split(".");

	if (splitNumber[1] === undefined) splitNumber[1] = "00";

	const decimals = splitNumber[1];

	splitNumber.pop();

	const remainder = splitNumber[0].length % 3;
	const totalBlocks = (splitNumber[0].length - remainder) / 3;

	let formattedNumber = "";
	if (remainder > 0) {
		formattedNumber = splitNumber[0].substring(0, remainder);
		if (totalBlocks > 0) {
			formattedNumber += ".";
		}
	}

	for (let i = 0; i < totalBlocks; i++) {
		const blocksCutoff = remainder + 3 * (totalBlocks - i);
		const block = splitNumber[0].substring(blocksCutoff - 3, blocksCutoff);
		formattedNumber += block;
		if (i < totalBlocks - 1) {
			formattedNumber += ".";
		}
	}

	return `${prefix}${formattedNumber},${decimals}`;
}
