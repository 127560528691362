import Config from "./models/types/config.type";

const isDevelopment = process.env.NODE_ENV !== "production";
const useLocalApi = process.env.REACT_APP_PORTAL_USE_LOCAL_API === "true";

const config: Config = {
	isDevelopment,
	useLocalApi,
	localApiUrl: useLocalApi ? "http://localhost:5221/" : "",
	apiPrefix: isDevelopment ? "http://localhost:5221/" : "",
};

export default config;
