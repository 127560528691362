import ResponseError, { ValidationError } from "../models/types/error.type";

export default function bodyToError<T extends object>(body: ResponseError<T>): ValidationError<T> {
	const keys = Object.keys(body.errors) as (keyof T)[];

	const form = {} as ValidationError<T>;

	for (const key of keys) {
		const value = body.errors[key];

		form[key] = value ?? [];
	}

	return form;
}
