import React, { PropsWithChildren } from "react";
import makeStyle from "../../../style/makeStyle";

type Alignment = "left" | "center" | "right";

type Props = {
	header?: boolean;
	alignment?: Alignment;
	className?: string;
	e2e?: string;
}

const CLASSES: {
	[alignment in Alignment]: string;
} = {
	left: "text-left justify-start",
	center: "text-center justify-center",
	right: "text-right justify-end",
};

export default function TableCell(props: PropsWithChildren<Props>): JSX.Element {
	const style = makeStyle("p-2", [ CLASSES[props.alignment ?? "left"], props.className ]);

	if (props.header) {
		return (
			<th className={style} {...props}>
				{props.children}
			</th>
		);
	}

	return (
		<td className={style} {...props}>
			{props.children}
		</td>
	);
}
