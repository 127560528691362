import React, { PropsWithChildren } from "react";
import E2EProp from "../../../models/types/e2e.type";

type DropdownOptionProps = E2EProp & {
	optionValue: string;
	onClick?: (value: string) => void;
}

export default function DropdownOption(props: PropsWithChildren<DropdownOptionProps>): JSX.Element {
	const handleClick = ():void => {
		props.onClick?.(props.optionValue);
	};

	return (
		<button
			className="text-center w-full cursor-pointer hover:bg-light"
			onClick={handleClick}
		>
			{props.children}
		</button>
	);
}
