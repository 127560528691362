import React, { useState } from "react";
import PublicLayout from "../../layouts/PublicLayout";
import { useTranslation } from "react-i18next";
import Input from "../../components/atoms/input/Input";
import Button from "../../components/atoms/Button";
import api from "../../axios/api";
import Alert from "../../components/atoms/Alert";
import { AxiosError } from "axios";
import useEnvironment from "../../hooks/useEnvironment";
import Variant from "../../models/types/variant.type";
import LinkButton from "../../components/molecules/LinkButton";
import { useParams } from "react-router-dom";

export default function PasswordResetPage(): JSX.Element {
	const [ email, setEmail ] = useState("");
	const { t } = useTranslation();

	const [ loading, setLoading ] = useState(false);
	const [ feedback, setFeedback ] = useState<{ message: string, variant: Variant } | null>(null);

	const { environment: paramsEnvironment } = useParams();
	const environment = useEnvironment();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setEmail(event.target.value);
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
		event.preventDefault();

		const env = environment.getEnvironment();

		if (!env)
			return;

		setLoading(true);

		api.post(`${env}/authentication/resetpassword`, { email })
			.then(() => {
				setFeedback({ message: t("public.passwordreset.success"), variant: "success" });
			})
			.catch((error) => {
				if (error instanceof AxiosError && error.response?.status === 409) {
					setFeedback({ message: t("public.passwordreset.emailnotfound"), variant: "error" });

					return;
				}

				setFeedback({ message: t("default.error"), variant: "error" });
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div>
			<PublicLayout title={t("public.passwordreset.title") ?? "Reset password"}>
				<form className="flex flex-col gap-4" onSubmit={handleSubmit}>
					<div>
						<Input
							name="email"
							label={t("public.passwordreset.email")}
							type="email"
							value={email}
							onChange={handleChange}
						/>
					</div>

					{!!feedback && (
						<Alert variant={feedback.variant}>
							{feedback.message}
						</Alert>
					)}

					<div className="flex flex-row gap-4">
						<LinkButton href={`/${paramsEnvironment ?? ""}/auth/signin`} className="!bg-primary opacity-30">
							Annuleren
						</LinkButton>

						<Button
							type="submit"
							loading={loading}
						>
							{t("public.passwordreset.submit")}
						</Button>
					</div>

				</form>
			</PublicLayout>
		</div>
	);
}
