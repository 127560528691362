import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Stepper from "../../components/atoms/Stepper";
import Step from "../../components/atoms/Stepper/Step";
import StepperFooter from "../../components/atoms/Stepper/StepperFooter";
import SelectRentalContractUnit from "../../components/organisms/tickets/steps/SelectRentalContractUnit";
import AuthenticatedLayout from "../../layouts/AuthenticatedLayout";
import { RentalContractUnit } from "../../models/types/api/rental-contract-unit.type";
import SelectLocation from "../../components/organisms/tickets/steps/SelectLocation";
import { LocationResponse } from "../../models/types/api/location.type";
import SelectCategory from "../../components/organisms/tickets/steps/SelectCategory";
import { CategoryResponse } from "../../models/types/api/category.type";
import SelectRemedy from "../../components/organisms/tickets/steps/SelectRemedy";
import ChangeAdditionalInformation from "../../components/organisms/tickets/steps/ChangeAdditionalInformation";
import { sendTicket } from "../../queries/tickets";
import { Navigate } from "react-router-dom";
import { getRentalContractUnits } from "../../queries/rentalContractUnits";
import Alert from "../../components/atoms/Alert";

export default function NewTicketPage(): JSX.Element {
	const { t } = useTranslation("tickets", { keyPrefix: "new" });

	const stepperTitles = [
		t("steps.select_rentable.label"),
		t("steps.select_location.label"),
		t("steps.problem_type.label"),
		t("steps.suggested_solutions.label"),
		t("steps.additional_information.label"),
	];

	const stepperButtonText: { [key: number]: string } = { 3: t("steps.suggested_solutions.next_button") };

	const canGoNext: (() => boolean)[] = [
		(): boolean => !!selectedRentalContractUnit,
		(): boolean => !!selectedLocation,
		(): boolean => !!selectedCategory,
	];

	const [ currentStep, setCurrentStep ] = useState(0);
	const [ skipFirstStep, setSkipFirstStep ] = useState(false);

	const [ selectedRentalContractUnit, setSelectedRentalContractUnit ]
		= useState<RentalContractUnit | null>(null);
	const [ selectedLocation, setSelectedLocation ] = useState<LocationResponse | null>(null);
	const [ selectedCategory, setSelectedCategory ] = useState<CategoryResponse | null>(null);

	const [ emptyRemedyResult, setEmptyRemedyResult ] = useState(false);

	const [ selectedTitle, setSelectedTitle ] = useState("");
	const [ selectedDescription, setSelectedDescription ] = useState("");
	const [ selectedFiles, setSelectedFiles ] = useState<File[]>([]);

	const [ titleError, setTitleError ] = useState<string[] | undefined>(undefined);
	const [ descriptionError, setDescriptionError ] = useState<string[] | undefined>(undefined);

	const { data: rentalContracts, isLoading: isLoadingRentalContracts } = getRentalContractUnits();
	const hasRentalContractUnits = rentalContracts?.length !== 0;

	const handleMaintenanceAgreement = (value: boolean): void => {
		setMaintenanceAgreement(value);
	};

	const [ maintenanceAgreement, setMaintenanceAgreement ] = useState(false);

	const updateSelectedRentalContractUnit
		= (unit: RentalContractUnit, shouldImmediatelySkip: boolean): void => {
			setSelectedRentalContractUnit(unit);
			setSelectedLocation(null);
			setSelectedCategory(null);

			if (shouldImmediatelySkip) {
				setSkipFirstStep(true);
				setCurrentStep(s => s + 1);
			}
		};

	const updateSelectedLocation = (location: LocationResponse): void => {
		setSelectedLocation(location);
		setSelectedCategory(null);
	};

	const updateSelectedCategory = (category: CategoryResponse) : void => {
		setSelectedCategory(category);
	};

	const handleEmptyRemedyResult = (): void => {
		setEmptyRemedyResult(true);
		setCurrentStep(4);
	};

	const updateAdditionalInformation = (
		title: string,
		description: string,
		files: File[]
	): void => {
		setSelectedTitle(title);
		setSelectedDescription(description);
		setSelectedFiles(files);
	};
	const mutation = sendTicket();
	const handleSubmit = (): void => {
		if (selectedTitle.length < 10) {
			setTitleError(["title_to_short"]);
		} else {
			setTitleError(undefined);
		}

		if (selectedDescription.length < 10) {
			setDescriptionError(["description_to_short"]);
		} else {
			setDescriptionError(undefined);
		}
		if (titleError === undefined && descriptionError === undefined) {
			mutation.mutate({
				unitId: selectedRentalContractUnit?.unitId ?? 0,
				locationId: selectedLocation?.locationId ?? "",
				categoryId: selectedCategory?.categoryId ?? "",
				title: selectedTitle,
				description: selectedDescription,
				files: selectedFiles,
			});
		}
	};

	const handlePreviousStep = (): void => {
		let stepCount = 1;

		if (emptyRemedyResult && currentStep === 4) {
			stepCount = 2;

			setEmptyRemedyResult(false);
		}
		if (maintenanceAgreement) {
			setMaintenanceAgreement(false);
		}
		setCurrentStep(s => s - stepCount);
	};
	if (mutation.isSuccess) return <Navigate to="/tickets" />;
	return ( isLoadingRentalContracts || !hasRentalContractUnits
		? (
			<AuthenticatedLayout title={t("text")}>
				<Alert variant="warning" className="mb-4">
					{t("steps.select_rentable.no_vhe")}
				</Alert>
			</AuthenticatedLayout>
		) : (
			<AuthenticatedLayout title={t("text")}>
				<Stepper titles={stepperTitles} current={currentStep}>
					<Step>
						<div className="md:w-1/2 md:mx-auto">
							<SelectRentalContractUnit
								selected={selectedRentalContractUnit}
								onSelectUnit={updateSelectedRentalContractUnit}
								rentalContractUnits={rentalContracts ?? []}
							/>
						</div>
					</Step>

					<Step>
						<div className="md:w-1/2 md:mx-auto">
							<SelectLocation
								unitId={selectedRentalContractUnit?.unitId ?? 0}
								selected={selectedLocation}
								onSelected={updateSelectedLocation}
							/>
						</div>
					</Step>

					<Step>
						<div className="md:w-1/2 md:mx-auto">
							<SelectCategory
								unitId={selectedRentalContractUnit?.unitId ?? 0}
								selected={selectedCategory}
								onSelect={updateSelectedCategory}
							/>
						</div>
					</Step>

					<Step>
						<div className="md:w-1/2 md:mx-auto">
							<SelectRemedy
								categoryId={selectedCategory?.categoryId ?? ""}
								onEmptyResult={handleEmptyRemedyResult}
							/>
						</div>
					</Step>

					<Step>
						<div className="md:w-1/2 md:mx-auto">
							<ChangeAdditionalInformation
								selectedTitle={selectedTitle}
								selectedDescription={selectedDescription}
								selectedFiles={selectedFiles}
								onChange={updateAdditionalInformation}
								maintenanceAgreed={maintenanceAgreement}
								onChangeMaintenance={handleMaintenanceAgreement}
								errors={{
									titleError,
									descriptionError,
								}}
								isBOG={selectedRentalContractUnit?.isBOG ?? true}
							/>
						</div>
					</Step>
				</Stepper>

				<StepperFooter
					canSave={maintenanceAgreement || selectedRentalContractUnit?.isBOG}
					className="md:w-1/2 md:mx-auto"
					currentStep={currentStep}
					canGoPrevious={!(currentStep === 1 && skipFirstStep)}
					canGoNext={canGoNext[currentStep]?.() ?? true}
					lastStep={stepperTitles.length}
					onNext={(): void => setCurrentStep(s => s + 1)}
					onPrevious={handlePreviousStep}
					nextText={stepperButtonText[currentStep] ?? undefined}
					onSubmit={handleSubmit}
					loading={mutation.isLoading}
				/>
			</AuthenticatedLayout>
		)
	);
}
