import getLogo from "../../utils/getLogo";
import React from "react";

type LogoProps = {
	environment: string;
	size?: string;
}
export default function Logo(props: LogoProps): JSX.Element {
	return (
		<div className="flex py-5 place-content-center">
			<img src={getLogo(props.environment)} alt="logo" className={props.size} />
		</div>
	);
}
