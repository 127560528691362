import React, { PropsWithChildren } from "react";

export default function Table(props: PropsWithChildren): JSX.Element {
	return (
		<div className="overflow-auto overflow-x-auto rounded-t-xl">
			<table className="w-full rounded-full table-auto border-colapse">
				{props.children}
			</table>
		</div>
	);
}
