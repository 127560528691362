import React, { useState } from "react";
import { FaCheck, FaQuestion } from "react-icons/fa";
import makeStyle from "../../style/makeStyle";
import Input from "../atoms/input/Input";

type SearchItem<T> = T & {
	searchKey: string;
	icon?: string;
}

export type ListSelectorElement = {
	key: string | number;
	element: JSX.Element;
}

type Props<T> = {
	keyPrefix: string;
	searchLabel: string;
	items: SearchItem<T>[];
	/**
	 * Should be the `searchKey` of the selected element
	 */
	default?: string;
	icon?: JSX.Element;
	children: (item: SearchItem<T>) => ListSelectorElement;
	onSelect: (item: SearchItem<T>) => void;
	highlightSelected?: boolean;
}

export default function ListSelector<T>(props: Props<T>): JSX.Element {
	const [ search, setSearch ] = useState("");
	const [ selectedItemSearchKey, setSelectedItemSearchKey ]
		= useState<string | null>(props.default ?? null);

	const selectItem = (item: SearchItem<T>): void => {
		setSelectedItemSearchKey(item.searchKey);
		props.onSelect(item);
	};

	const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		setSearch(e.target.value);
	};

	const getIcon = (selected: boolean, itemIcon?: string): JSX.Element => {
		const SIZE = 22;

		if (selected && props.highlightSelected)
			return <FaCheck className="text-success" size={SIZE} />;

		if (itemIcon) {
			return (
				<img
					src={itemIcon}
					height={SIZE}
					width={SIZE}
				/>
			);
		}

		return props.icon ?? <FaQuestion size={SIZE} />;
	};

	return (
		<div>
			{props.items.length > 3 && (
				<Input
					label={props.searchLabel}
					onChange={handleSearchChange}
					className="mb-8"
				/>
			)}

			<div className="space-y-4">
				{props.items.filter(i => i.searchKey.toLowerCase().includes(search.toLowerCase()))
					.map((item: SearchItem<T>): JSX.Element => {
						const result = props.children(item);
						const selected = item.searchKey === selectedItemSearchKey;
						const style = makeStyle(
							"flex flex-row py-2 px-4 border border-slate-300 rounded cursor-pointer hover:bg-slate-100",
							[props.highlightSelected && selected && "bg-success/10 border-success"]
						);
						const icon = getIcon(selected, item.icon);

						return (
							<div
								role="button"
								key={`listselector-${props.keyPrefix}-${result.key}`}
								className={style}
								onClick={(): void => selectItem(item)}
							>
								<div className="flex items-center mr-4">
									{icon}
								</div>

								<div>
									{result.element}
								</div>
							</div>
						);
					})}
			</div>
		</div>
	);
}
