import { IconButtonProps } from "../../models/props/button.props";
import React, { PropsWithChildren } from "react";
import makeStyle from "../../style/makeStyle";
import Loader from "./Loader";

export default function IconButton(props: PropsWithChildren<IconButtonProps>): JSX.Element {
	const style = makeStyle("py-3 px-3 rounded-lg hover:bg-gray-100 max-h-10", [ props.disabled && "text-gray-400", props.className ]);

	const isDisabled = (!!props.disabled || !!props.loading);

	return (
		<button
			type={props.type ?? "button"}
			onClick={props.onClick}
			className={style}
			disabled={isDisabled}
			{...props}
		>
			{props.loading ? <Loader className="!w-4 !h-4" /> : (
				<>
					{props.children}
				</>
			)}
		</button>
	);
}
