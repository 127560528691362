import { useQuery, UseQueryResult } from "react-query";
import api from "../axios/api";
import SettlementResponse from "../models/types/api/settlement.type";

export default function getSettlementsByContract(
	contractId: number
): UseQueryResult<SettlementResponse[], void> {
	return useQuery([
		"settlements", "contract", contractId,
	], async (): Promise<SettlementResponse[]> => {
		if (isNaN(contractId))
			return [];

		const result = await api.get(`managementcontracts/${contractId}/settlements`);

		const settlements = result.data as SettlementResponse[];

		settlements.sort((settlementA, settlementB) =>
			new Date(settlementB.from).getTime() - new Date(settlementA.from).getTime()
		);

		return settlements;
	}, { enabled: false });
}
