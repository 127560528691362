import React from "react";
import Paper from "../../atoms/Paper";
import Title from "../../atoms/Title";

type GenericErrorProps = {
	body: string,
	title: string
}

export default function SystemError(props: GenericErrorProps): JSX.Element {
	return (
		<div className="flex flex-col justify-center items-center h-screen">
			<Paper className="w-11/12 md:w-1/3">
				<div className="flex flex-col gap-2">
					{!!props.title && (
						<Title>
							{props.title}
						</Title>
					)}
				</div>

				<p>
					{props.body}
				</p>
			</Paper>
		</div>
	);
}
