import React from "react";
import { useTranslation } from "react-i18next";
import { getPrivacyStatement } from "../../queries/public";

type PrivacyDisclaimerProps = {
	environment: string;
}
export default function PrivacyDisclaimerLink(props: PrivacyDisclaimerProps): JSX.Element {
	const { t } = useTranslation("authlayout");
	const privacyStatement = getPrivacyStatement(props.environment);

	return (
		<>
			{/* eslint-disable-next-line max-len */}
			{ privacyStatement.data?.DISCLAIMERENGB || privacyStatement.data?.DISCLAIMERNL ? (
				<a href={`/${props.environment}/privacy`} className="text-sm underline text-blue-600 text-center">
					{t("translation:privacyPolicyLink")}
				</a>
			) : (
				""
			)}
		</>
	);
}

