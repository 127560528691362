import React, { useState } from "react";
import AuthenticatedLayout from "../layouts/AuthenticatedLayout";
import Table from "../components/atoms/table/Table";
import TableHead from "../components/atoms/table/TableHead";
import TableCell from "../components/atoms/table/TableCell";
import TableBody from "../components/atoms/table/TableBody";
import { useTranslation } from "react-i18next";
import { getInvoices } from "../queries/invoices";
import TableRow from "../components/atoms/table/TableRow";
import formatNumber from "../utils/formatNumber";
import { openFile } from "../utils/file";
import { FaDownload } from "react-icons/fa";
import IconButton from "../components/atoms/IconButton";
import api from "../axios/api";
import { formatPeriod } from "../utils/formatDate";
import Alert from "../components/atoms/Alert";
import ContentLoader from "../components/molecules/ContentLoader";
import { toast } from "react-toastify";
import { DateTime } from "luxon";

export default function InvoicesPage(): JSX.Element {
	const { t } = useTranslation("invoices");
	const [ loading, setLoading ] = useState<(number | string)[]>([]);

	const { data, isLoading } = getInvoices();

	const download = ( id: number | string): void => {
		const previousState = [ ...loading, id ];
		setLoading(previousState);

		const baseUrl = api.defaults.baseURL ?? "/";
		const url = `${baseUrl}rentalcontracts/invoices/${id}/`;

		openFile(url)
			.catch(() => {
				toast.error(t("download_error"));
			})
			.finally(() => {
				const state = loading.filter(item => item !== id);
				setLoading(state);
			});
	};

	return (
		<div>
			<AuthenticatedLayout title={t("title")}>
				{(!data || data.length <= 0)
					? (
						<Alert variant="info" className="mb-5">
							{t("error.no_invoices")}
						</Alert>
					)
					: (isLoading || !data ? (<ContentLoader />) : (
						<Table>
							<TableHead>
								<TableRow>
									<TableCell header>
										{t("period")}
									</TableCell>

									<TableCell header alignment="right">
										{t("amount")}
									</TableCell>

									<TableCell header alignment="right">
										{t("outstanding")}
									</TableCell>

									<TableCell header alignment="right">
										{t("download")}
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{data.sort((a, b) =>
									DateTime.fromISO(a.invoiceDate).toMillis()
									- DateTime.fromISO(b.invoiceDate).toMillis())
									.map((invoice) => {
										const isPendingDownload = loading.includes(invoice.invoiceNo);

										return (
											<TableRow key={invoice.invoiceNo}>
												<TableCell>
													{formatPeriod(
														invoice.lines.map(line => (
															{
																periodFrom: line.periodFrom,
																periodTo: line.periodTo,
															})
														)
													)}
												</TableCell>

												<TableCell alignment="right">
													€
													{formatNumber(invoice.invoiceAmount)}
												</TableCell>

												<TableCell alignment="right">
													€
													{formatNumber(invoice.unpaidAmount)}
												</TableCell>

												<TableCell alignment="right">
													<IconButton
														onClick={
															(): void => download(invoice.invoiceNo)
														}
														loading={isPendingDownload}
													>
														<FaDownload />
													</IconButton>
												</TableCell>
											</TableRow>
										);
									}
									)}
							</TableBody>
						</Table>
					))}
			</AuthenticatedLayout>
		</div>
	);
}
