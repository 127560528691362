import React, { PropsWithChildren } from "react";
import makeStyle from "../../style/makeStyle";

type PaperProps = {
	className?: string;
}

export default function Paper(props: PropsWithChildren<PaperProps>): JSX.Element {
	const style = makeStyle("p-7 drop-shadow-md bg-white", [props.className]);

	return (
		<div className={style}>
			{props.children}
		</div>
	);
}
