import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAuth from "../hooks/useAuth";
import AuthenticatedLayout from "../layouts/AuthenticatedLayout";
import Subtitle from "../components/atoms/Subtitle";
import Alert from "../components/atoms/Alert";
import { UserAction } from "../models/types/auth.type";
import List from "../components/atoms/list/List";
import ListItem from "../components/atoms/list/ListItem";
import { HiOutlineArrowUpRight } from "react-icons/hi2";
import { getTickets } from "../queries/tickets";
import ContentLoader from "../components/molecules/ContentLoader";
import { CiWarning } from "react-icons/ci";
import TicketStatus from "../models/enums/ticket-status.enum";

export default function DashboardPage(): JSX.Element {
	const [ loading, setLoading ] = useState(true);
	const [ openTickets, setOpenTickets ] = useState(0);
	const [ actions, setActions ] = useState<UserAction[]>([]);
	const tickets = getTickets();

	const { t } = useTranslation([ "dashboard", "translation" ]);

	const auth = useAuth();

	useEffect(() => {
		auth.refreshActions()
			.then((result) => {
				setActions(result);
			})
			.catch(() => {
				setActions([]);
			})
			.finally(() => {
				setLoading(false);
			});
		setTimeout(() => {
			if (localStorage.getItem("isCandidate") === "true") {
				localStorage.setItem("isCandidate", "false");
			}
		}, 1000);
	}, []);

	useEffect(() => {
		const data = tickets.data?.filter((ticketData) => {
			const status : TicketStatus
				= TicketStatus[ticketData.statusId as unknown as keyof typeof TicketStatus];
			return !(status === TicketStatus.AFGEHANDELDDOORDIENSTVERLENER
				|| status === TicketStatus.AFGEHANDELDDOORHUURDER
				|| status === TicketStatus.AFGEHANDELDHANDMATIG);
		});

		if (!data || data.length === 0) {
			setOpenTickets(0);
			return;
		}

		const total = data.reduce((accumulator, currentTicket) => {
			if (!currentTicket.closedOn) return accumulator + 1;

			return accumulator;
		}, 0);

		setOpenTickets(total);
	}, [tickets.data]);

	const getUrl = (action: UserAction): string => {
		switch (action) {
			case "CompleteUserProfile":
				return "/onboarding/profile";
			case "SubmitRequestedDocuments":
				return "/documents";
			case "VerifyDocuments":
				return "/documents";
		}
	};

	return (
		<AuthenticatedLayout title={t("title")}>
			{loading || tickets.isLoading ? <ContentLoader /> : (
				<>

					<Subtitle>
						{t("actions.title")}
					</Subtitle>

					<div className="mt-2 mb-12">

						{
							actions.length <= 0 && !openTickets ? (
								<Alert variant="info">
									{t("actions.not_found")}
								</Alert>
							)
								: (
									<List>
										{actions.map(action => (
											<ListItem key={`action-${action}`} link={getUrl(action)}>
												<HiOutlineArrowUpRight size={25} />

												{t(`actions.options.${action}`)}
											</ListItem>
										))}

										{!!openTickets && (
											<ListItem link="/tickets">

												<CiWarning size={25} className="text-warning" />

												{t("actions.options.open_tickets", { count: openTickets })}
											</ListItem>
										)}
									</List>
								)
						}
					</div>
				</>
			)}
		</AuthenticatedLayout>
	);
}
