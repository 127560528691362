import React, { PropsWithChildren } from "react";
import { NavLink } from "react-router-dom";
import makeStyle from "../../style/makeStyle";

type NavbuttonProps = {
	light?: boolean;
	title: string;
	link: string;
	alwaysSolid?: boolean;
	neverSelected?: boolean;
}

export default function NavButton(props: PropsWithChildren<NavbuttonProps>): JSX.Element {
	const style = ({ isActive }: { isActive: boolean }): string =>
		makeStyle("block cursor-pointer text-xl px-5 py-2 mx-5 rounded-xl hover:bg-light", [
			props.light ? "text-primary" : "text-white hover:text-primary",
			isActive && !props.neverSelected ? "bg-light" : "",
			isActive || props.alwaysSolid ? "opacity-100" : "opacity-50",
		]);

	return (
		<NavLink to={props.link} className={style}>
			<div>
				{props.title}
			</div>
		</NavLink>
	);
}
