import React from "react";
import formatFileSize from "../../utils/formatFileSize";
import { FaTimes } from "react-icons/fa";

type FileListProps = {
	files: File[];
	onDelete?: (index: number) => void;
}

export default function FileList(props: FileListProps): JSX.Element | null {
	if (!props.files || props.files.length === 0) return null;

	return (
		<ul>
			{props.files.map((file, index) => (
				<li
					onClick={(): void => props.onDelete?.(index)}
					key={`file-${file.name}`}
					className="flex flex-row px-1 py-0.5 group hover:bg-gray-100 hover:cursor-pointer transition-colors items-center"
				>
					<p className="grow">
						{file.name}

						{" "}

						(
						{formatFileSize(file.size)}
						)
					</p>

					{!!props.onDelete && <FaTimes className="group-hover:text-red-600 transition-colors" />}
				</li>
			))}
		</ul>
	);
}
