import React, {
	PropsWithChildren, useEffect, useRef, useState,
} from "react";
import makeStyle from "../../../style/makeStyle";
import E2EProp from "../../../models/types/e2e.type";

type DropdownProps = E2EProp & {
	title: string;
	light?: boolean;
}

export default function DropdownMenuItem(props: PropsWithChildren<DropdownProps>): JSX.Element {
	const ref = useRef<HTMLDivElement>(null);
	const [ open, setOpen ] = useState(false);
	const style = makeStyle("border w-full shadow-md z-10 bg-white absolute bottom-full rounded-md my-0.5", [!open && "hidden"]);

	useEffect(() => {
		const checkIfClickedOutside = (e: MouseEvent):void => {
			// If the menu is open and the clicked target is not within the menu,
			// then close the menu
			if (open && ref.current && !ref.current.contains(e.target as Node)) {
				setOpen(false);
			}
		};

		document.addEventListener("mousedown", checkIfClickedOutside);

		return () => {
			// Cleanup the event listener
			document.removeEventListener("mousedown", checkIfClickedOutside);
		};
	}, [open]);
	const handleClick = (): void => {
		setOpen(!open);
	};
	return (
		<div className="w-full relative " ref={ref}>

			<div
				className={`cursor-pointer text-xl px-5 py-2 mx-5 rounded-xl hover:bg-light ${props.light ? "text-primary" : "text-white hover:text-primary"}`}
				onClick={handleClick}
			>
				{props.title}
			</div>

			<div
				className={style}
			>
				{props.children}
			</div>
		</div>
	);
}
