import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaHome } from "react-icons/fa";
import { RentalContractUnit } from "../../../../models/types/api/rental-contract-unit.type";
import ListSelector, { ListSelectorElement } from "../../../molecules/ListSelector";
import Alert from "../../../atoms/Alert";

type Props = {
	selected?: RentalContractUnit | null;
	onSelectUnit: (unit: RentalContractUnit, shouldImmediatelySkip: boolean) => void;
	rentalContractUnits: RentalContractUnit[];
}

export default function SelectRentalContractUnit(props: Props): JSX.Element {
	const { t } = useTranslation("tickets", { keyPrefix: "new.steps.select_rentable" });

	useEffect(() => {
		if (props.rentalContractUnits && props.rentalContractUnits.length === 1)
			props.onSelectUnit(props.rentalContractUnits[0], true);
	}, [props.rentalContractUnits]);

	return ( props.rentalContractUnits
		? (
			<ListSelector
				keyPrefix="rentalContractUnits"
				searchLabel={t("search")}
				icon={<FaHome size={22} />}
				default={props.selected?.name}
				items={props.rentalContractUnits.map(d => ({ ...d, searchKey: d.name }))}
				onSelect={(item): void => props.onSelectUnit(item, false)}
				highlightSelected
			>
				{(item): ListSelectorElement => ({
					key: item.unitId,
					element: (
						<p>
							{item.name}
						</p>
					),
				})}
			</ListSelector>
		) : 			(
			<Alert variant="warning" className="mb-4">
				{t("no_vhe")}
			</Alert>
		)

	);
}
