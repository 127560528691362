import React from "react";
import { getTicketLocations } from "../../../../queries/tickets";
import ListSelector, { ListSelectorElement } from "../../../molecules/ListSelector";
import { LocationResponse } from "../../../../models/types/api/location.type";
import { useTranslation } from "react-i18next";
import ContentLoader from "../../../molecules/ContentLoader";

type SelectLocationProps = {
	unitId: number;
	selected?: LocationResponse | null;
	onSelected: (location: LocationResponse) => void;
}

export default function SelectLocation(props: SelectLocationProps): JSX.Element {
	const { t } = useTranslation("tickets");

	const locations = getTicketLocations(props.unitId);

	if (!locations.data) return <ContentLoader />;

	return (
		<ListSelector
			keyPrefix="ticketLocation"
			searchLabel={t("new.steps.select_location.search")}
			default={props.selected?.name}
			items={locations.data
				.map(location => ({
					...location,
					searchKey: location.name ?? "",
					icon: `/icons/locations/${location.locationId ?? ""}.svg`,
				}))}
			onSelect={props.onSelected}
			highlightSelected
		>
			{(item): ListSelectorElement => ({
				key: item.locationId ?? "",
				element: (
					<p>
						{
							t(`location.${item.locationId ?? ""}`)
						}
					</p>
				),
			})}
		</ListSelector>
	);
}
