import { DateTime } from "luxon";
import { Period } from "../models/types/period";
import i18next from "i18next";

export function formatPeriod(periods:Period[]): string {
	let startDateISO = periods[0].periodFrom;
	let endDateISO = periods[0].periodTo;

	for (const period of periods) {
		if (period.periodFrom < startDateISO) {
			startDateISO = period.periodFrom;
		}
		if (period.periodTo > endDateISO) {
			endDateISO = period.periodTo;
		}
	}
	const startDate = DateTime.fromISO(startDateISO);
	const endDate = DateTime.fromISO(endDateISO);

	if (startDate.day === 1 && endDate.day === endDate.endOf("month").day && startDate.year === endDate.year) {
		if (startDate.month === endDate.month) {
			return startDate.setLocale(i18next.language).toFormat("MMMM y");
		} else if (startDate.month === (endDate.month - 2)) {
			switch (startDate.month) {
				case 1:
					return "Q1";
				case 4:
					return "Q2";
				case 7:
					return "Q3";
				case 10:
					return "Q4";
				default:
					return `${formatDateToReadable(startDateISO)} / ${formatDateToReadable(startDate.toString())}`;
			}
		} else {
			return `${formatDateToReadable(startDate.toString())} / ${formatDateToReadable(startDate.toString())}`;
		}

	} else {
		return `${formatDateToReadable(startDate.toString())} / ${formatDateToReadable(startDate.toString())}`;
	}
}

export function formatDateToReadable(value: string): string {
	return DateTime.fromISO(value).toFormat("dd-MM-yyyy");
}

export function formatTimeToReadable(value: string): string {
	return DateTime.fromISO(value).toFormat("HH:mm");
}

export function formatDateTimeToReadable(value: string): string {
	return `${formatDateToReadable(value)} ${formatTimeToReadable(value)}`;
}

