import React, {
	PropsWithChildren, useEffect, useState,
} from "react";
import { useTranslation } from "react-i18next";
import Title from "../components/atoms/Title";
import useAuth from "../hooks/useAuth";
import NavButton from "../components/atoms/NavButton";
import { Capability } from "../models/types/auth.type";
import { GiHamburgerMenu } from "react-icons/gi";
import { ImCross } from "react-icons/im";
import Dropdown from "../components/atoms/dropdown/DropdownMenuItem";
import DropdownOption from "../components/atoms/dropdown/DropdownOption";
import i18next from "i18next";
import useEnvironment from "../hooks/useEnvironment";
import SelectInput from "../components/atoms/input/SelectInput";
import useManagementContracts from "../hooks/useManagementContracts";
import SelectOption from "../components/atoms/input/SelectOption";
import Logo from "../components/atoms/Logo";
import { NavItemType } from "../models/types/nav-item.type";
import NavCategory from "../components/molecules/NavCategory";
import makeStyle from "../style/makeStyle";
import { useLocation, useNavigate } from "react-router-dom";
import IconButton from "../components/atoms/IconButton";
import PrivacyDisclaimerLink from "../components/atoms/PrivacyDisclaimerLink";

type GeneralLayoutProps = {
	title: string;
	showManagementContracts?: boolean;
	actions?: JSX.Element[];
	canGoBack?: boolean;
}

export default function AuthenticatedLayout(
	props: PropsWithChildren<GeneralLayoutProps>
): JSX.Element {
	const [ menuOpen, setMenuOpen ] = useState(false);

	const auth = useAuth();
	const env = useEnvironment();
	const contracts = useManagementContracts();

	const navigate = useNavigate();
	const location = useLocation();
	const { t } = useTranslation("authlayout");

	useEffect(() => {
		const actions = auth.getActions();

		if ((actions.includes("CompleteUserProfile") && !location.pathname.includes("onboarding")))
			navigate("/onboarding/profile");
	}, []);

	const handleGoBack = (): void => {
		navigate(-1);
	};

	const Capabilities = auth.getCapabilities();
	const CapabilityList: { [key in Capability]?: NavItemType } = {
		MyInfo: "/user/edit",
		Invoices: "/invoices",
		Documents: "/documents",
		Tickets: "/tickets",
		OwnerSettlements: "/ownersettlements",
		Finance: { subCategories: { exploitation: "/exploitation", "rent-arrears": "/rentArrears" } },
		Dossiers: "/dossiers",
		OwnerTickets: "/ownertickets",
	};

	const showManagementContracts = (!!props.showManagementContracts
		&& contracts.managementContracts.length > 1);
	const showActions = !!props.actions;

	const handleClick = (): void => {
		setMenuOpen(!menuOpen);
	};

	const handleOption = (language: string): void => {
		void i18next.changeLanguage(language);
	};

	return (
		<div>
			<div className="flex flex-row h-screen md:relative">
				{!auth.isUserCandidate() && (
					<div
						className={makeStyle( "w-full md:w-96 md:static absolute h-screen flex flex-col bg-white drop-shadow-xl z-40", [menuOpen ? "" : "hidden md:flex"])}
					>
						<div className="w-full h-16" />

						<div className="grow">
							<NavButton title={t("navbar.dashboard")} link="/" light />

							{Capabilities.map((data) => {
								const capability = CapabilityList[data];

								return (
									<div key={data}>
										{
											typeof capability === "string" ? (
												<NavButton
													title={t(`navbar.${data}`)}
													link={capability}
													light
												/>
											) : capability && (
												<NavCategory
													title={t(`navbar.${data}`) ?? data}
													light
													subCategories={capability.subCategories}
												/>
											)
										}
									</div>
								);
							})}
						</div>

						<div className="bg-primary grow-0 rounded-tr-xl p-2">
							<nav>

								<NavButton
									link={auth.getCapabilities().includes("MyInfo") ? CapabilityList["MyInfo"] as string : ""}
									title={auth.getDisplayName() ?? t("user")}
									light={false}
									alwaysSolid
									neverSelected
								/>

								<Dropdown title={t("navbar.select_language") ?? "Select language"}>
									<DropdownOption onClick={handleOption} optionValue="en">
										English
									</DropdownOption>

									<DropdownOption onClick={handleOption} optionValue="nl">
										Nederlands
									</DropdownOption>
								</Dropdown>

								<NavButton
									title={t("navbar.logout") ?? "Logout"}
									link="/auth/signout"
									light={false}
									alwaysSolid
								/>

							</nav>
						</div>
					</div>
				)}

				<div className="overflow-auto flex flex-col w-full min-h-screen relative">
					<div className="h-28 bg-light flex flex-row p-5">
						<div className="p-auto flex-col self-center whitespace-nowrap">
							{!!props.canGoBack && (
								<div className="pb-2">
									<button onClick={handleGoBack} type="button" className="flex items-center text-sm text-gray-700 transition-colors duration-200 gap-x-2 sm:w-auto">
										<svg
											className="w-5 h-5 rtl:rotate-180"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											strokeWidth="1.5"
											stroke="currentColor"
										>
											<path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
										</svg>

										<span>
											{t("go_back")}
										</span>
									</button>
								</div>
							)}

							<div>
								<Title>
									{props.title}
								</Title>
							</div>

						</div>

						<div className="items-center relative w-full h-full flex justify-end">
							{!auth.isUserCandidate() && (
								<IconButton className="z-50 md:hidden absolute right-2 top-1/2 transform -translate-y-1/2 hover:bg-gray-200" onClick={handleClick}>
									<ImCross className={`w-4 h-4 ${menuOpen ? "" : "hidden"}`} />

									<GiHamburgerMenu className={`w-6 h-6 ${menuOpen ? "hidden" : ""}`} />
								</IconButton>
							)}

						</div>
					</div>

					{!!(showManagementContracts || showActions) && (
						<div className="pt-5 px-5 flex flex-row items-end">
							{!!showManagementContracts && (
								<div className="w-52">
									<SelectInput
										label={t("managementContracts.label")}
										disabled={contracts.managementContracts.length <= 0}
										value={(contracts.currentContractId ?? NaN).toString()}
										onChange={contracts.handleChange}
									>
										{contracts.managementContracts
											.map(contract => (
												<SelectOption
													key={contract.managementContractId}
													value={contract.managementContractId.toString()}
												>
													{contract.name ?? t("managementContracts.no_name")}
												</SelectOption>
											))}
									</SelectInput>
								</div>
							)}

							{!!showActions && (
								<div className="flex flex-row grow justify-end gap-3">
									{props.actions}
								</div>
							)}
						</div>
					)}

					<div className="p-5 grow">
						{props.children}
					</div>

					<Logo size="max-w-small" environment={env.getEnvironment()} />

					<PrivacyDisclaimerLink environment={env.getEnvironment()} />

				</div>
			</div>

		</div>
	);
}
