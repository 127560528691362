import React, { useState } from "react";
import { ReviewDocumentResponse } from "../../../models/types/api/documents.type";
import TableRow from "../../atoms/table/TableRow";
import TableCell from "../../atoms/table/TableCell";
import { CiWarning } from "react-icons/ci";
import IconButton from "../../atoms/IconButton";
import {
	FaDownload, FaThumbsDown, FaThumbsUp,
} from "react-icons/fa";
import { CELL_STYLE, WARNING_CELL_STYLE } from "../../../pages/DocumentsPage";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { reviewDocument } from "../../../queries/documents";
import Modal from "../../atoms/Modal";
import Title from "../../atoms/Title";
import TextArea from "../../atoms/input/TextArea";
import Alert from "../../atoms/Alert";
import downloadDocument from "../../../utils/downloadDocument";

type ReviewDocumentsProps = {
	documents: ReviewDocumentResponse[];
}

export default function ReviewDocuments(props: ReviewDocumentsProps): JSX.Element {
	const [ documentId, setDocumentId ] = useState(0);
	const [ remarks, setRemarks ] = useState("");

	const { t } = useTranslation("documents");

	const queryClient = useQueryClient();

	const mutation = reviewDocument({
		onSuccess: async () => {
			setDocumentId(0);

			await queryClient.invalidateQueries("documents");
		},
	});

	const handleAccept = (id: number): void => {
		mutation.mutate({
			reviewDocumentId: id,
			settled: true,
			remarks: null,
		});
	};

	const handleDeny = (id: number): void => {
		mutation.reset();

		setDocumentId(id);
	};

	const handleSubmit = (): void => {
		mutation.mutate({
			reviewDocumentId: documentId,
			settled: false,
			remarks,
		});
	};

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
		setRemarks(event.target.value);
	};

	const handleCancel = (): void => {
		setDocumentId(0);
	};

	return (
		<>
			{props.documents.length > 0 && (
				<TableRow>
					<TableCell className="all-unset">
						<Modal
							open={!documentId}
							onSubmit={handleSubmit}
							onCancel={handleCancel}
							buttonSubmitText={t("review.deny")}
							buttonCancelText={t("modal.cancel", { ns: "components" })}
						>
							<div className="flex flex-col gap-4 w-full sm:w-96 md:w-80">
								<Title>
									{t("review.title")}
								</Title>

								<TextArea label={t("review.label")} value={remarks} onChange={handleChange} />

								{!!mutation.error?.query && (
									<Alert variant="error">
										{t(mutation.error.query)}
									</Alert>
								)}
							</div>
						</Modal>
					</TableCell>
				</TableRow>
			)}

			{props.documents.map(doc => (
				<TableRow key={`review-${doc.relationDocumentId}`}>
					<TableCell className={WARNING_CELL_STYLE}>
						<CiWarning size={24} className="text-warning" title={t("actions.review")} />
					</TableCell>

					<TableCell className="w-full">
						{doc.description}
					</TableCell>

					<TableCell alignment="right" className={CELL_STYLE}>
						<IconButton
							onClick={(): void => handleAccept(doc.relationDocumentId)}
						>
							<FaThumbsUp />
						</IconButton>

						<IconButton
							onClick={(): void => handleDeny( doc.relationDocumentId)}
						>
							<FaThumbsDown />
						</IconButton>

						<IconButton onClick={(): void => downloadDocument("review", doc.relationDocumentId)}>
							<FaDownload />
						</IconButton>
					</TableCell>
				</TableRow>
			))}
		</>
	);
}
