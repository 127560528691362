import React, { useEffect, useState } from "react";
import AuthenticatedLayout from "../../layouts/AuthenticatedLayout";
import { useTranslation } from "react-i18next";
import Alert from "../../components/atoms/Alert";
import Table from "../../components/atoms/table/Table";
import TableHead from "../../components/atoms/table/TableHead";
import TableRow from "../../components/atoms/table/TableRow";
import TableCell from "../../components/atoms/table/TableCell";
import TableBody from "../../components/atoms/table/TableBody";
import ContentLoader from "../../components/molecules/ContentLoader";
import { Link } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa";
import { getOwnerManagementContractTickets } from "../../queries/ownerTickets";
import useAuth from "../../hooks/useAuth";
import useManagementContracts from "../../hooks/useManagementContracts";

export default function OwnerManagementContractTickets(): JSX.Element {
	const { t } = useTranslation("ownertickets");
	const managementContracts = useManagementContracts();
	const managementContractId = managementContracts.currentContractId;

	if (isNaN(managementContractId)) {
		return (
			<AuthenticatedLayout
				title={t("managementContractTickets.pageTitle")}
				showManagementContracts
			>
				<Alert variant="info" className="mb-5">
					{t("managementContractsOverview.errors.error_no_contracts")}
				</Alert>
			</AuthenticatedLayout>
		);
	}

	const user = useAuth();

	const {
		data, isLoading, refetch,
	}
		= getOwnerManagementContractTickets(managementContractId);
	const [isOwnerView] = useState(user.getCapabilities().includes("OwnerTicketCorrespondence"));

	useEffect(() => {
		void refetch();
	}, [ data, managementContractId ]);

	return (
		<AuthenticatedLayout
			title={t("managementContractTickets.pageTitle")}
			showManagementContracts
		>
			{isLoading ? (<ContentLoader />)
				: !data
					? (
						<Alert variant="info" className="mb-5">
							{t("rentalContractDocumentDetail.errors.noTenantsAssigned")}
						</Alert>
					)
					: (
						<Table>
							<TableHead>
								<TableRow>

									<TableCell>
										{t("managementContractTickets.tableHeader.title")}
									</TableCell>

									<TableCell>
										{t("managementContractTickets.tableHeader.status")}
									</TableCell>

									<TableCell>
										{t("managementContractTickets.tableHeader.handler")}
									</TableCell>

									<TableCell>
										{t("managementContractTickets.tableHeader.unit")}
									</TableCell>

									{
										!!isOwnerView && (
											<TableCell>
												{t("managementContractTickets.tableHeader.show")}
											</TableCell>
										)
									}

								</TableRow>
							</TableHead>

							<TableBody>
								{data
									.map(ownerManagementContractTicket => (
										<TableRow key={`${ownerManagementContractTicket.ticketId}`}>
											<TableCell>
												{ownerManagementContractTicket.title}
											</TableCell>

											<TableCell>
												{ownerManagementContractTicket.status}
											</TableCell>

											<TableCell>
												{ownerManagementContractTicket.handler}
											</TableCell>

											<TableCell>
												{ownerManagementContractTicket.unit}
											</TableCell>

											{ !!isOwnerView && (
												<TableCell>
													<Link to={`/ownertickets/${managementContractId}/${ownerManagementContractTicket.ticketId}`}>
														<FaExternalLinkAlt />
													</Link>
												</TableCell>
											)}

										</TableRow>
									))}
							</TableBody>
						</Table>
					)}
		</AuthenticatedLayout>
	);
}
