import api from "../axios/api";

export async function getFile(url: string): Promise<Blob> {
	const result = await api.get(url, { responseType: "blob" });

	return result.data as Blob;
}

export async function openFile(url: string): Promise<void> {
	const file = await getFile(url);

	const blobUrl = URL.createObjectURL(file);

	window.open(blobUrl, "_blank")
		?.focus();
}
