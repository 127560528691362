import React, { useRef, useEffect } from "react";
import AuthenticatedLayout from "../../layouts/AuthenticatedLayout";
import EditUserForm from "../../components/organisms/forms/user/EditUserForm";
import Button from "../../components/atoms/Button";
import UserForm from "../../models/forms/user.form";
import { updateUser } from "../../queries/user";
import { UserFormActions, UserFormProvider } from "../../hooks/useUserForm";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useEnvironment from "../../hooks/useEnvironment";

export default function EditUserPage(): JSX.Element {
	const env = useEnvironment().getEnvironment();
	const mutation = updateUser(env);

	const { t } = useTranslation("user");
	const userForm = useRef<UserFormActions>(null);

	const handleClick = (): void => {
		userForm.current?.submitForm();
	};

	const handleSubmit = (form: UserForm): void => {
		mutation.mutate(form);
	};

	useEffect(() => {
		if (mutation.isSuccess) {
			toast.success(t("edit.success"));
		}
	}, [mutation.isSuccess]);

	return (
		<AuthenticatedLayout title={t("edit.title")}>
			<UserFormProvider
				onSubmit={handleSubmit}
				queryError={mutation.error?.query}
				validationError={mutation.error?.validation}
				ref={userForm}
			>
				<EditUserForm />
			</UserFormProvider>

			<div className="flex flex-col gap-form items-center mt-4">
				<Button onClick={handleClick} loading={mutation.isLoading}>
					{t("edit.submit")}
				</Button>
			</div>
		</AuthenticatedLayout>
	);
}
