import React from "react";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import makeStyle from "../../../style/makeStyle";

type StepperFooterProps = {
	currentStep: number;
	lastStep: number;
	onPrevious: () => void;
	loading?: boolean;
	canGoPrevious?: boolean;
	canGoNext?: boolean;
	onNext?: () => void;
	onSubmit?: () => void;
	submitText?: string;
	nextText?: string;
	className?: string;
	canSave?: boolean;
}

export default function StepperFooter(props: StepperFooterProps): JSX.Element {
	const { t } = useTranslation("components");

	const style = makeStyle("flex flex-row justify-between gap-3 mt-4", [props.className]);
	return (
		<div className={style}>
			<Button
				e2e="Onboarding.Stepper.Previous"
				onClick={props.onPrevious}
				disabled={props.currentStep <= 0 || !(props.canGoPrevious ?? true)}
				outlined
				variant="error"
			>
				{t("stepper.footer.previous")}
			</Button>

			{
				props.currentStep >= props.lastStep - 1 ? (
					<Button
						e2e="User.Edit.Submit"
						onClick={props.currentStep >= props.lastStep - 1
							? props.onSubmit : props.onNext}
						disabled={!props.canSave}
						type="submit"
						loading={props.loading}
					>
						{
							props.currentStep >= props.lastStep - 1
								? props.submitText ?? t("stepper.footer.submit")
								: props.nextText ?? t("stepper.footer.next")
						}
					</Button>
				) : (
					<Button
						e2e="User.Edit.Submit"
						onClick={props.currentStep >= props.lastStep - 1
							? props.onSubmit : props.onNext}
						disabled={!props.canGoNext}
						type="submit"
						loading={props.loading}
					>
						{
							props.currentStep >= props.lastStep - 1
								? props.submitText ?? t("stepper.footer.submit")
								: props.nextText ?? t("stepper.footer.next")
						}
					</Button>
				)

			}

		</div>
	);
}
