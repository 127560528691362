import React, { useEffect } from "react";
import AuthenticatedLayout from "../../layouts/AuthenticatedLayout";
import { useTranslation } from "react-i18next";
import Alert from "../../components/atoms/Alert";
import Table from "../../components/atoms/table/Table";
import TableHead from "../../components/atoms/table/TableHead";
import TableRow from "../../components/atoms/table/TableRow";
import TableCell from "../../components/atoms/table/TableCell";
import TableBody from "../../components/atoms/table/TableBody";
import api from "../../axios/api";
import { openFile } from "../../utils/file";
import ContentLoader from "../../components/molecules/ContentLoader";
import { getDossierRentalContractDocuments } from "../../queries/rentalContracts";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import i18next from "i18next";
import IconButton from "../../components/atoms/IconButton";
import { FaDownload } from "react-icons/fa";
import useManagementContracts from "../../hooks/useManagementContracts";

export default function DossierRentalContractDocuments(): JSX.Element {
	const { t } = useTranslation("dossiers");
	const contractId = useParams().contractId;
	const managementContracts = useManagementContracts();
	const contractDocuments
		= getDossierRentalContractDocuments(Number(contractId), managementContracts.currentContractId);

	useEffect(() => {
		void contractDocuments.refetch();
	}, [contractDocuments.data]);

	const handleDownload = (documentId: number): void => {
		const baseUrl = api.defaults.baseURL ?? "/";
		if (contractId) {
			const url = `${baseUrl}dossiers/rentalcontracts/${contractId}/${documentId}/download`;
			void openFile(url);
		}

	};

	return (
		<AuthenticatedLayout
			title={t("pageTitleRentalContractDocuments")}
			canGoBack
		>
			{contractDocuments.isLoading ? (<ContentLoader />)
				: !contractDocuments.data
					? (
						<Alert variant="info" className="mb-5">
							{t("rentalContractDocumentDetail.errors.noDocumentsFound")}
						</Alert>
					)
					: (
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										{t("rentalContractDocumentDetail.name")}
									</TableCell>

									<TableCell>
										{t("rentalContractDocumentDetail.createdOn")}
									</TableCell>

									<TableCell />
								</TableRow>
							</TableHead>

							<TableBody>
								{contractDocuments.data.map(document => (
									<TableRow key={`${document.documentId}`}>

										<TableCell>
											{document.name}
										</TableCell>

										<TableCell>
											{DateTime
												.fromISO(document.createdOn)
												.setLocale(i18next.language)
												.toFormat("dd MMM yyyy")}
										</TableCell>

										<TableCell>
											<IconButton
												onClick={
													(): void =>
														handleDownload(document.documentId)
												}
												disabled={!document.documentId}
											>
												<FaDownload />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					)}
		</AuthenticatedLayout>
	);
}
