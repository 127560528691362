import React, {
	createContext, PropsWithChildren, useContext, useEffect, useState,
} from "react";
import { toast } from "react-toastify";
import ManagementContractResponse from "../models/types/api/management-contract.type";
import { getManagementContracts } from "../queries/managementContracts";
import { useTranslation } from "react-i18next";
import useAuth from "./useAuth";

type ContextProps = {
	managementContracts: ManagementContractResponse[];
	currentContractId: number;
	init: () => void;
	handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};

const ManagementContractsContext = createContext<ContextProps>({} as ContextProps);

export function ManagementContractsProvider(props: PropsWithChildren): JSX.Element {
	const [ currentContractId, setCurrentContractId ] = useState<number>(NaN);

	const { t } = useTranslation();

	const auth = useAuth();
	const contracts = getManagementContracts();

	useEffect(() => {
		if (!auth.authenticated())
			return;

		init();
	}, []);

	useEffect(() => {
		if (!contracts.data || contracts.data.length === 0) {
			setCurrentContractId(NaN);
			return;
		}

		setCurrentContractId(contracts.data[0].managementContractId);
	}, [contracts.data]);

	const init = (): void => {
		contracts.refetch()
			.then((result) => {
				if (result.isError)
					throw new Error();
			})
			.catch(() => {
				toast.warning(t("public.managementContracts.error"));
			});
	};

	const handleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		const value = parseInt(event.target.value);

		if (!isNaN(value))
			setCurrentContractId(value);
	};

	return (
		<ManagementContractsContext.Provider
			value={{
				managementContracts: contracts.data ?? [],
				currentContractId,
				init,
				handleChange,
			}}
		>
			{props.children}
		</ManagementContractsContext.Provider>
	);
}

export default function useManagementContracts(): ContextProps {
	return useContext(ManagementContractsContext);
}
