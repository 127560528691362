import { useQuery, UseQueryResult } from "react-query";
import api from "../axios/api";
import PublicResponse from "../models/types/api/public.type";

export function getPrivacyStatement(environment: string): UseQueryResult<PublicResponse> {
	return useQuery([], async () => {
		const result = await api.get<PublicResponse>(`/${environment}/public/privacydisclaimer`);
		return result.data ?? "";
	});
}
