import React from "react";
import makeStyle from "../../style/makeStyle";

type Size = "small" | "medium" | "large";

type Props = {
	size?: Size;
	className?: string;
}

export default function Loader(props: Props): JSX.Element {
	const getSize = (): [ string, string ] => {
		switch (props.size) {
			case "large": return [ "w-8", "h-8" ];
			case "medium": return [ "w-6", "h-6" ];
			case "small":
			default:
				return [ "w-5", "h-5" ];
		}
	};

	const loaderStyle = [
		"box-border",
		"inline-block",
		"bg-transparent",
		...getSize(),
		"rounded-full",
		"border-2",
		"border-light",
		"border-t-primary",
		"animate-spin",
	].join(" ");

	const style = makeStyle(loaderStyle, [props.className]);

	return (
		<span className={style} />
	);
}
