import React from "react";
import { SelectInputProps } from "../../../models/props/input.props";
import makeStyle from "../../../style/makeStyle";
import ErrorList from "../ErrorList";

export default function SelectInput<V extends string | number>(
	props: SelectInputProps<V>
): JSX.Element {
	const style = makeStyle("text-sm text-black h-9 border rounded",
		[props.errors ? "border-error" : "border-slate-300"]);
	const containerStyle = makeStyle("flex flex-col", [props.className]);

	return props.visible || props.visible === undefined || props.visible === null ? (
		<div className={containerStyle}>
			<label>
				{ props.required ? `${props.label } *` : `${props.label}` }
			</label>

			<select
				value={props.value}
				required={props.required}
				disabled={!!props.disabled}
				size={props.size}
				name={props.name}
				onChange={props.onChange}
				className={style}
				{...props}
			>
				{props.children}
			</select>

			{!!props.errors && Array.isArray(props.errors) && <ErrorList errors={props.errors} />}
		</div>
	) : <div />;
}
