import React, { useState } from "react";
import PublicLayout from "../../layouts/PublicLayout";
import Input from "../../components/atoms/input/Input";
import SignInForm from "../../models/forms/auth/sign-in.form";
import { useTranslation } from "react-i18next";
import {
	Link,
	useNavigate, useParams, useSearchParams,
} from "react-router-dom";
import Button from "../../components/atoms/Button";
import useEnvironment from "../../hooks/useEnvironment";
import { signIn } from "../../queries/auth";
import Alert from "../../components/atoms/Alert";
import useValidation from "../../hooks/useValidation";
import { minLength, required } from "../../validators";
import { ValidationResult } from "../../models/types/validation.type";
import useManagementContracts from "../../hooks/useManagementContracts";

export default function SignInPage(): JSX.Element {
	const [ form, setForm ] = useState<SignInForm>({
		username: "",
		password: "",
	});
	const [ validationErrors, setValidationErrors ] = useState<ValidationResult<SignInForm>>(null);
	const { t } = useTranslation([
		"signin", "translation", "publiclayout",
	]);
	const params = useParams();
	const portalEnvironment = params.environment;
	const validation = useValidation();
	const [searchParams] = useSearchParams();
	const environment = useEnvironment();
	const navigate = useNavigate();
	const managementContracts = useManagementContracts();

	const {
		mutate, isLoading, error,
	} = signIn(portalEnvironment);
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setForm(state => ({
			...state,
			[event.target.name]: event.target.value,
		}));
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
		event.preventDefault();

		setValidationErrors(null);

		const errors = validation.validate(form, {
			username: [required],
			password: [ required, minLength(8) ],
		});

		if (errors) {
			setValidationErrors(errors);
			return;
		}

		mutate(form, {
			onSuccess: (): void => {
				managementContracts.init();
				const redirectPath = searchParams.get("redirect_path");
				if (redirectPath) {
					navigate(redirectPath);
				} else {
					navigate("/");
				}
			},
		});
	};

	return (
		<div>
			<PublicLayout title={t("title") ?? "Sign in"}>
				<form className="flex flex-col gap-4 pt-4" onSubmit={handleSubmit}>
					{!!error?.query && (
						<Alert variant="error">
							{t(error.query)}
						</Alert>
					)}

					{searchParams.has("expired") && (
						<Alert variant="info">
							{t("translation:public.error.signout")}
						</Alert>
					)}

					<Input
						label={t("email")}
						name="username"
						type="email"
						value={form.username}
						errors={validationErrors?.username}
						onChange={handleChange}
						e2e="Authentication.Login.Username"
					/>

					<Input
						label={t("password")}
						name="password"
						type="password"
						value={form.password}
						errors={validationErrors?.password}
						onChange={handleChange}
						e2e="Authentication.Login.Password"
					/>

					<Link to={`/${environment.getEnvironment()}/auth/resetpassword`} className="text-sm underline text-blue-600">
						{t("forgot_password")}
					</Link>

					<div>
						<Button e2e="Authentication.Login.Submit" type="submit" loading={isLoading}>
							{t("button")}
						</Button>
					</div>
				</form>
			</PublicLayout>
		</div>
	);
}
