import useEnvironment from "../hooks/useEnvironment";
import React from "react";
import { getPrivacyStatement } from "../queries/public";
import i18n from "../i18n";
import Logo from "../components/atoms/Logo";

export default function PrivacyDisclaimer(): JSX.Element {
	const env = useEnvironment();
	const privacyStatement = getPrivacyStatement(env.getEnvironment());
	// We sanitize before injecting to avoid potential XSS attacks (in case of a zero-day breach or some other vulnerability)
	const privacyStatementNL = privacyStatement.data?.DISCLAIMERNL ?? "";
	const privacyStatementEN = privacyStatement.data?.DISCLAIMERENGB ?? "";

	return (
		<>
			<div className="flex py-5 place-content-center">
				{ i18n.language === "nl" ? (
					<div className="container" dangerouslySetInnerHTML={{ __html: privacyStatementNL }} />
				) : (
					<div className="container" dangerouslySetInnerHTML={{ __html: privacyStatementEN }} />
				)}
			</div>

			<div>
				<Logo size="max-w-small" environment={env.getEnvironment()} />
			</div>
		</>
	);
}
