import Input from "../../../atoms/input/Input";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useUserForm from "../../../../hooks/useUserForm";
import UserForm from "../../../../models/forms/user.form";
import CountrySelector from "../../../molecules/CountrySelector";
import { ValidationResult } from "../../../../models/types/validation.type";
import Address, { ForeignAddress, NationalAddress } from "../../../../models/types/api/address.type";
import FormPart from "../../../molecules/FormPart";
import RelationFieldsConfigNames from "../../../../models/enums/RelationFields.enum";

type EditUserAddressFormProps = {
	addressType: Extract<keyof UserForm, "residentialAddress" | "postalAddress">;
};

export default function EditUserAddressFormPostalAddress(props: EditUserAddressFormProps): JSX.Element {
	const [ nationalErrors, setNationalErrors ] = useState<ValidationResult<NationalAddress>>({});
	const [ foreignErrors, setForeignErrors ] = useState<ValidationResult<ForeignAddress>>({});
	const { t } = useTranslation([ "user", "translate" ]);
	const user = useUserForm();

	const address = user.form[props.addressType];
	useEffect(() => {
		setNationalErrors({});
		setForeignErrors({});

		if (user.validationErrors) {
			const errors = user.validationErrors[props.addressType] as ValidationResult<Address>;

			if (errors?.nationalAddress)
				setNationalErrors(errors.nationalAddress as ValidationResult<NationalAddress>);

			if (errors?.foreignAddress)
				setForeignErrors(errors.foreignAddress as ValidationResult<ForeignAddress>);
		}
	}, [user.validationErrors]);

	const handleNationalAddressChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (props.addressType === "residentialAddress") {
			user.handleResidentialAddressChange(event, "nationalAddress");
			return;
		}

		user.handlePostalAddressChange(event, "nationalAddress");
	};

	const handleForeignAddressChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (props.addressType === "residentialAddress") {
			user.handleResidentialAddressChange(event, "foreignAddress");
			return;
		}

		user.handlePostalAddressChange(event, "foreignAddress");
	};

	const handleCountryChange = (country: string): void => {
		user.handleCountryChange(country, props.addressType);
	};

	return (
		<div className="flex flex-row flex-wrap gap-form basis-full lg:basis-auto justify-center grow">
			{
				user.accountType === "Company" ? (
					<FormPart
						title={props.addressType === "residentialAddress" ? t("edit.headers.company_location") : t("edit.headers.postal_address_details")}
						className="basis-full grow"
					>

						<div className="flex flex-col gap-form">

							<CountrySelector
								value={address?.foreignAddress?.country ?? ""}
								label={t("data.foreign_address.country")}
								onChange={handleCountryChange}
								required={user.isFieldRequired(
									RelationFieldsConfigNames.ResidentialAddressCountry)}
								e2e={props.addressType === "residentialAddress" ? "User.Edit.ResidentialAddress.Country" : "User.Edit.PostalAddress"}
							/>

							{address?.foreignAddress?.country === "Nederland" ? (
								<div className="flex flex-col gap-form basis-auto grow">
									<Input
										value={address?.nationalAddress?.street ?? ""}
										label={t("data.national_address.street")}
										onChange={handleNationalAddressChange}
										name="street"
										errors={nationalErrors?.street}
										required
										e2e={props.addressType === "residentialAddress" ? "User.Edit.ResidentialAddress.Street" : "User.Edit.PostalAddress.Street"}
									/>

									<Input
										value={address?.nationalAddress?.houseNumber !== 0 ? address.nationalAddress?.houseNumber?.toString() : ""}
										label={t("data.national_address.house_number")}
										onChange={handleNationalAddressChange}
										name="houseNumber"
										errors={nationalErrors?.houseNumber}
										required
										type="number"
										e2e={props.addressType === "residentialAddress" ? "User.Edit.ResidentialAddress.HouseNumber" : "User.Edit.PostalAddress.HouseNumber"}
									/>

									<Input
										label={t("data.national_address.house_letter")}
										value={address?.nationalAddress?.houseLetter ?? ""}
										onChange={handleNationalAddressChange}
										name="houseLetter"
										errors={nationalErrors?.houseLetter}
										maxLength={4}
										e2e={props.addressType === "residentialAddress" ? "User.Edit.ResidentialAddress.HouseLetter" : "User.Edit.PostalAddress.HouseLetter"}
									/>

									<Input
										value={address?.nationalAddress?.houseNumberExtension ?? ""}
										label={t("data.national_address.house_number_extension")}
										onChange={handleNationalAddressChange}
										name="houseNumberExtension"
										errors={nationalErrors?.houseNumberExtension}
										e2e={props.addressType === "residentialAddress" ? "User.Edit.ResidentialAddress.HouseNumberExtension" : "User.Edit.PostalAddress.HouseNumberExtension"}
									/>

									<Input
										value={address?.nationalAddress?.postalCode ?? ""}
										label={t("data.national_address.postal_code")}
										onChange={handleNationalAddressChange}
										name="postalCode"
										errors={nationalErrors?.postalCode}
										required
										e2e={props.addressType === "residentialAddress" ? "User.Edit.ResidentialAddress.PostalCode" : "User.Edit.PostalAddress.PostalCode"}
									/>

									<Input
										value={address?.nationalAddress?.city ?? ""}
										label={t("data.national_address.city")}
										onChange={handleNationalAddressChange}
										name="city"
										errors={nationalErrors?.city}
										required
										e2e={props.addressType === "residentialAddress" ? "User.Edit.ResidentialAddress.City" : "User.Edit.PostalAddress.City"}
									/>

								</div>
							) : (
								<div className="flex flex-col gap-form basis-auto grow">
									<Input
										value={address?.foreignAddress?.addressLineAbroad1 ?? ""}
										label={t("data.foreign_address.address_line_abroad1")}
										onChange={handleForeignAddressChange}
										name="addressLineAbroad1"
										errors={foreignErrors?.addressLineAbroad1}
										required
									/>

									<Input
										value={address?.foreignAddress?.addressLineAbroad2 ?? ""}
										label={t("data.foreign_address.address_line_abroad2")}
										onChange={handleForeignAddressChange}
										name="addressLineAbroad2"
										required
										errors={foreignErrors?.addressLineAbroad2}

									/>

									<Input
										value={address?.foreignAddress?.addressLineAbroad3 ?? ""}
										label={t("data.foreign_address.address_line_abroad3")}
										onChange={handleForeignAddressChange}
										required
										name="addressLineAbroad3"
									/>
								</div>
							)}
						</div>
					</FormPart>
				) : (
					<FormPart
						title={props.addressType === "residentialAddress" ? t("edit.headers.residential_address_details") : t("edit.headers.postal_address_details")}
						className="basis-full grow"
					>

						<div className="flex flex-col gap-form">

							<CountrySelector
								value={address?.foreignAddress?.country ?? ""}
								label={t("data.foreign_address.country")}
								onChange={handleCountryChange}
								required
								e2e={props.addressType === "residentialAddress" ? "ResidentialAddress" : "PostalAddress"}
							/>

							{address?.foreignAddress?.country === "Nederland" ? (
								<div className="flex flex-col gap-form basis-auto grow">
									<Input
										value={address?.nationalAddress?.street ?? ""}
										label={t("data.national_address.street")}
										onChange={handleNationalAddressChange}
										name="street"
										errors={nationalErrors?.street}
										required
										e2e={props.addressType === "residentialAddress" ? "User.Edit.ResidentialAddress.Street" : "User.Edit.PostalAddress.Street"}
									/>

									<Input
										value={address?.nationalAddress?.houseNumber !== 0 ? address.nationalAddress?.houseNumber?.toString() : ""}
										label={t("data.national_address.house_number")}
										onChange={handleNationalAddressChange}
										name="houseNumber"
										errors={nationalErrors?.houseNumber}
										required
										type="number"
										e2e={props.addressType === "residentialAddress" ? "User.Edit.ResidentialAddress.HouseNumber" : "User.Edit.PostalAddress.HouseNumber"}
									/>

									<Input
										value={address?.nationalAddress?.houseLetter ?? ""}
										label={t("data.national_address.house_letter")}
										onChange={handleNationalAddressChange}
										name="houseLetter"
										errors={nationalErrors?.houseLetter}
										maxLength={4}
										e2e={props.addressType === "residentialAddress" ? "User.Edit.ResidentialAddress.HouseLetter" : "User.Edit.PostalAddress.HouseLetter"}
									/>

									<Input
										value={address?.nationalAddress?.houseNumberExtension ?? ""}
										label={t("data.national_address.house_number_extension")}
										onChange={handleNationalAddressChange}
										name="houseNumberExtension"
										errors={nationalErrors?.houseNumberExtension}
										e2e={props.addressType === "residentialAddress" ? "User.Edit.ResidentialAddress.HouseNumberExtension" : "User.Edit.PostalAddress.HouseNumberExtension"}
									/>

									<Input
										value={address?.nationalAddress?.postalCode ?? ""}
										label={t("data.national_address.postal_code")}
										onChange={handleNationalAddressChange}
										name="postalCode"
										errors={nationalErrors?.postalCode}
										required
										e2e={props.addressType === "residentialAddress" ? "User.Edit.ResidentialAddress.PostalCode" : "User.Edit.PostalAddress.PostalCode"}
									/>

									<Input
										value={address?.nationalAddress?.city ?? ""}
										label={t("data.national_address.city")}
										onChange={handleNationalAddressChange}
										name="city"
										errors={nationalErrors?.city}
										required
										e2e={props.addressType === "residentialAddress" ? "User.Edit.ResidentialAddress.City" : "User.Edit.PostalAddress.City"}
									/>

								</div>
							) : (
								<div className="flex flex-col gap-form basis-auto grow">
									<Input
										value={address?.foreignAddress?.addressLineAbroad1 ?? ""}
										label={t("data.foreign_address.address_line_abroad1")}
										onChange={handleForeignAddressChange}
										name="addressLineAbroad1"
										errors={foreignErrors?.addressLineAbroad1}
										required
									/>

									<Input
										value={address?.foreignAddress?.addressLineAbroad2 ?? ""}
										label={t("data.foreign_address.address_line_abroad2")}
										onChange={handleForeignAddressChange}
										name="addressLineAbroad2"
										errors={foreignErrors?.addressLineAbroad2}
										required
									/>

									<Input
										value={address?.foreignAddress?.addressLineAbroad3 ?? ""}
										label={t("data.foreign_address.address_line_abroad3")}
										onChange={handleForeignAddressChange}
										errors={foreignErrors?.addressLineAbroad3}
										name="addressLineAbroad3"
										required
									/>
								</div>
							)}
						</div>
					</FormPart>
				)
			}

		</div>
	);
}
