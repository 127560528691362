import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FileUploader from "../../../molecules/FileUploader";
import Input from "../../../atoms/input/Input";
import TextArea from "../../../atoms/input/TextArea";
import api from "../../../../axios/api";
import { openFile } from "../../../../utils/file";
import { toast } from "react-toastify";
import { getOptionToSmallMaintenanceDocument } from "../../../../queries/tickets";
import Checkbox from "../../../atoms/input/Checkbox";
import IconButton from "../../../atoms/IconButton";
import Loader from "../../../atoms/Loader";
import { FaDownload } from "react-icons/fa";

type Props = {
	selectedTitle: string
	selectedDescription: string
	selectedFiles: File[]
	onChange: (title: string, description: string, files: File[]) => void
	errors: { titleError: string[] | undefined, descriptionError: string[] | undefined }
	maintenanceAgreed: boolean;
	onChangeMaintenance: (value: boolean) => void;
	isBOG: boolean;
}

export default function ChangeAdditionalInformation(props: Props): JSX.Element {
	const { t } = useTranslation("tickets");
	const translation = "new.steps.additional_information.";
	const [ loading, setLoading ] = useState<boolean>(false);
	const documentAvailable = getOptionToSmallMaintenanceDocument();

	const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>):void => {
		props.onChange(event.target.value, props.selectedDescription, props.selectedFiles);
	};

	const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>):void => {
		props.onChange(props.selectedTitle, event.target.value, props.selectedFiles);
	};

	const handleFileChange = (files: File[]):void => {
		props.onChange(props.selectedTitle, props.selectedDescription, files);
	};

	const download = () : void => {
		setLoading(true);

		const baseUrl = api.defaults.baseURL ?? "/";
		const url = `${baseUrl}tickets/documents/smallmaintenance/`;

		openFile(url)
			.catch(() => {
				toast.error(t("download_error"));
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<>

			<Input
				label={t(`${translation}title`)}
				value={props.selectedTitle}
				onChange={handleTitleChange}
				errors={props.errors.titleError}
			/>

			<TextArea
				className="w-full h-[50vh]"
				label={t(`${translation}describe`)}
				placeholder={t(`${translation}warning`)}
				value={props.selectedDescription}
				onChange={handleDescriptionChange}
				errors={props.errors.descriptionError}
			/>

			<FileUploader
				filesUpdateHandler={handleFileChange}
				max={Infinity}
				files={props.selectedFiles ?? []}
			/>

			{
				!props.isBOG ? (
					<>
						<Checkbox
							onChange={props.onChangeMaintenance}
							label={t(`${translation}download_information`)}
							checked={props.maintenanceAgreed}
							warningText={t(`${translation}warning_download_information`)}
						/>

						{
							// eslint-disable-next-line max-len
							documentAvailable.data?.smallMaintenanceDocumentAvailable ? (
								<IconButton
									onClick={
										(): void =>
											download()
									}
								>
									<div className="whitespace-nowrap inline-flex">
										<div className="w-4 h-4 mr-2">
											{ loading
												? <Loader size="small" />
												: <FaDownload />}
										</div>

										<span>

											{t(`${translation}download`)}
										</span>

									</div>
								</IconButton>
							) : null
						}
					</>
				) : <div />
			}

		</>
	);
}
