import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import makeStyle from "../../style/makeStyle";
import { FaAngleUp } from "react-icons/fa";
import { useTranslation } from "react-i18next";

type NavCategory = {
	light?: boolean;
	title: string;
	subCategories: {
		[key: string]: string
	}
}

export default function NavCategory(props: NavCategory): JSX.Element {
	const { t } = useTranslation("authlayout");
	const [ open, setOpen ] = useState(false);
	const style = makeStyle("block cursor-pointer text-xl px-5 py-2 mx-5 rounded-xl hover:bg-light", [
		props.light ? "text-primary" : "text-white hover:text-primary",
		open ? "bg-light" : "",
		open ? "opacity-100" : "opacity-50",
	]);

	const handleClick = (): void => {
		setOpen(!open);
	};

	return (
		<div className={style} onClick={handleClick}>

			<div>
				<p className="relative">
					{props.title}

					<span>
						<FaAngleUp className={makeStyle("my-1 duration-300 inline absolute right-0", [open ? "rotate-180" : ""])} size={20} />
					</span>
				</p>

				{props.subCategories !== undefined
					? (
						props.subCategories && Object.keys(props.subCategories).map(key => (
							<NavLink
								to={props.subCategories[key]}
								key={key}
								className={({ isActive }: { isActive: boolean }): string => {
									if (isActive) {
										setOpen(true);
									}
									return makeStyle("duration-300 px-5 block hover:bg-primary rounded-xl hover:text-white", [ open ? "scale-y-100 max-h-9 my-2 pb-1" : "scale-y-0 max-h-0 my-0 py-0", isActive ? "bg-primary text-white" : "" ]);
								}}
							>
								{t(`navbar.${key}`)}
							</NavLink>
						))
					)
					: ""}

			</div>

		</div>
	);
}
