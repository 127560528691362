import React, { PropsWithChildren } from "react";

export default function Title(props: PropsWithChildren): JSX.Element {
	return (
		<h1
			className="text-primary text-2xl md:text-4xl "
		>
			{props.children}
		</h1>
	);
}
