import React from "react";
import makeStyle from "../../../style/makeStyle";

type Props = {
	current: boolean;
	finished: boolean;
}

export default function StepSeparator(props: Props): JSX.Element {
	const style = makeStyle("grow inline-block w-auto h-px mx-2 md:mx-4 bg-slate-400 rounded-full", [
		props.current && "!bg-primary",
		props.finished && "!bg-success",
	]);

	return (
		<div className={style} />
	);
}
