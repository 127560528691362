import React, { PropsWithChildren } from "react";

import {
	CiCircleAlert, CiWarning, CiCircleCheck,
} from "react-icons/ci";
import makeStyle from "../../style/makeStyle";

type AlertProps = {
	title?: string;
	variant?: "info" | "success" | "warning" | "error"
	className?: string;
}

export default function Alert(props: PropsWithChildren<AlertProps>): JSX.Element {
	const style = makeStyle(
		"flex flex-row items-center rounded-md p-2 alert",
		[ props.variant ?? "info", props.className ],
	);

	const getIcon = (): JSX.Element => {
		const size = 30;

		switch (props.variant) {
			case "success":
				return <CiCircleCheck size={size} />;
			case "warning":
				return <CiWarning size={size} />;
			default:
				return <CiCircleAlert size={size} />;
		}
	};

	return (
		<div className={style}>
			<div className="px-2">
				{getIcon()}
			</div>

			<div>
				{!!props.title && (
					<h4 className="font-bold">
						{props.title}
					</h4>
				)}

				<p className="text-sm">
					{props.children}
				</p>
			</div>
		</div>
	);
}
