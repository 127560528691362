import React from "react";
import { useTranslation } from "react-i18next";
import Table from "../components/atoms/table/Table";
import TableBody from "../components/atoms/table/TableBody";
import TableCell from "../components/atoms/table/TableCell";
import TableHead from "../components/atoms/table/TableHead";
import TableRow from "../components/atoms/table/TableRow";
import AuthenticatedLayout from "../layouts/AuthenticatedLayout";
import { getDocuments } from "../queries/documents";
import Alert from "../components/atoms/Alert";
import makeStyle from "../style/makeStyle";
import ContentLoader from "../components/molecules/ContentLoader";
import RequestedDocuments from "../components/organisms/documents/RequestedDocuments";
import ReviewDocuments from "../components/organisms/documents/ReviewDocuments";
import SubmittedDocuments from "../components/organisms/documents/SubmittedDocuments";

export const CELL_STYLE = "w-full h-full inline-flex flex-row align-center gap-2";
export const WARNING_CELL_STYLE = makeStyle(CELL_STYLE, ["w-auto"]);

export default function DocumentsPage(): JSX.Element {
	const { t } = useTranslation("documents");

	const { data: documents, isLoading: documentsLoading } = getDocuments();

	const requiredActionCount = !documents ? 0
		: documents.requested.length + documents.review.length;

	return (
		<AuthenticatedLayout title={t("title")}>
			{requiredActionCount > 0 && (
				<Alert variant="warning" className="mb-5">
					{t("actions.required", { count: requiredActionCount })}
				</Alert>
			)}

			{documentsLoading || !documents ? (<ContentLoader />) : (
				<Table>
					<TableHead>
						<TableRow>
							{requiredActionCount > 0 && (
								<TableCell header className={WARNING_CELL_STYLE} />
							)}

							<TableCell header>
								{t("document.name")}
							</TableCell>

							<TableCell header alignment="right">
								{t("document.actions")}
							</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						<RequestedDocuments documents={documents.requested} />

						<ReviewDocuments documents={documents.review} />

						<SubmittedDocuments
							documents={documents.submitted}
							requiredActionCount={requiredActionCount}
						/>
					</TableBody>
				</Table>
			)}
		</AuthenticatedLayout>
	);
}
