import React, { useEffect } from "react";
import AuthenticatedLayout from "../../layouts/AuthenticatedLayout";
import { useTranslation } from "react-i18next";
import Alert from "../../components/atoms/Alert";
import Table from "../../components/atoms/table/Table";
import TableHead from "../../components/atoms/table/TableHead";
import TableRow from "../../components/atoms/table/TableRow";
import TableCell from "../../components/atoms/table/TableCell";
import TableBody from "../../components/atoms/table/TableBody";
import api from "../../axios/api";
import { openFile } from "../../utils/file";
import ContentLoader from "../../components/molecules/ContentLoader";
import { getDossiers } from "../../queries/rentalContracts";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import i18next from "i18next";
import IconButton from "../../components/atoms/IconButton";
import { FaDownload } from "react-icons/fa";
import useManagementContracts from "../../hooks/useManagementContracts";

export default function DossierTenantDocuments(): JSX.Element {
	const { t } = useTranslation("dossiers");
	const tenantId = useParams();
	const managementContracts = useManagementContracts();

	const rentalContracts = getDossiers(managementContracts.currentContractId);

	// Select all documents from the rental contract
	const relationContractDocuments = rentalContracts.data?.find(rentalContractDetail =>
		rentalContractDetail.rentalContractId === Number(tenantId.tenantId));

	useEffect(() => {
		void rentalContracts.refetch();
	}, [managementContracts.currentContractId]);

	const handleDownload = (documentId: number): void => {
		const baseUrl = api.defaults.baseURL ?? "/";
		const url = `${baseUrl}dossiers/tenant/document/${documentId}/${managementContracts.currentContractId}/download`;

		void openFile(url);
	};

	return (
		<AuthenticatedLayout
			title={t("pageTitleTenantDocuments")}
			canGoBack
		>
			{rentalContracts.isLoading ? (<ContentLoader />)
				: !rentalContracts.data
					? (
						<Alert variant="info" className="mb-5">
							{t("rentalContractDocumentDetail.errors.noDocumentsFound")}
						</Alert>
					)
					: (
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										{t("rentalContractDocumentDetail.name")}
									</TableCell>

									<TableCell>
										{t("rentalContractDocumentDetail.documentType")}
									</TableCell>

									<TableCell>
										{t("rentalContractDocumentDetail.createdOn")}
									</TableCell>

									<TableCell />
								</TableRow>
							</TableHead>

							<TableBody>
								{relationContractDocuments?.documents.filter((x) => {
									return x.type === "Relatiedocument";
								}).map(document => (
									<TableRow key={`${document.documentId}`}>

										<TableCell>
											{document.name}
										</TableCell>

										<TableCell>
											{document.type}
										</TableCell>

										<TableCell>
											{DateTime
												.fromISO(document.createdOn)
												.setLocale(i18next.language)
												.toFormat("dd MMM yyyy")}
										</TableCell>

										<TableCell>
											<IconButton
												onClick={
													(): void =>
														handleDownload(document.documentId)
												}
												disabled={!document.documentId}
											>
												<FaDownload />
											</IconButton>
										</TableCell>

									</TableRow>
								))}
							</TableBody>
						</Table>
					)}
		</AuthenticatedLayout>
	);
}
