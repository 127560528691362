import { useQuery, UseQueryResult } from "react-query";
import api from "../axios/api";
import { InvoiceQueryResponse } from "../models/types/api/invoice.type";

type QueryResult = UseQueryResult<InvoiceQueryResponse[], null>;

export function getInvoices(): QueryResult {
	return useQuery("invoices", async (): Promise<InvoiceQueryResponse[]> => {
		const response = await api.get<InvoiceQueryResponse[]>("/rentalcontracts/invoices");

		return response.data ?? [];
	});
}
