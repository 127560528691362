import { ValidationError, ValidationFunction } from "./models/types/validation.type";
import IBAN from "iban";

export function required<V>(value: V): ValidationError {
	const valid = !!value;

	if (valid) return null;

	return "required";
}

export function postalCode<V>(value: V): ValidationError {
	if (typeof (value) === "string") {
		if (/\s/g.test(value)) {
			return {
				key: "postalCode",
				context: { format: value.trim() },
			};
		}
		return null;
	}
	return null;
}

export function validateIBAN<V>(value: V): ValidationError {
	const readablePattern = "NL00 BANK 1234 5678 90";
	const ibanValidationError = {
		key: "invalidIBAN",
		context: { readablePattern },
	};

	return IBAN.isValid(value as string) ? null : ibanValidationError;
}

export function validateNothing<V>(_value: V): ValidationError {
	return null;
}

export function minLength<V, K>(requiredLength: number): ValidationFunction<V, K> {
	const minLengthError = {
		key: "minLength",
		context: { length: requiredLength.toString() },
	};

	return function (value: V): ValidationError {
		if (!value) return "";

		const validType = typeof value === "string"
			|| (typeof value === "object" && "length" in value);

		if (!validType) return minLengthError;

		const correctLength = (value.length as number) >= requiredLength;

		if (!correctLength) return minLengthError;

		return null;
	};
}

export function maxLength<V, K>(requiredLength: number): ValidationFunction<V, K> {
	const maxLengthError = {
		key: "maxLength",
		context: { length: requiredLength.toString() },
	};

	return function (value: V): ValidationError {
		if (!value) return "";

		const validType = typeof value === "string"
			|| (typeof value === "object" && "length" in value);

		if (!validType) return maxLengthError;

		const correctLength = (value.length as number) <= requiredLength;

		if (!correctLength) return maxLengthError;

		return null;
	};
}

export function matchPattern<V extends string | null, K>(
	pattern: RegExp,
	readablePattern: string
): ValidationFunction<V, K> {
	const matchPatternError = {
		key: "matchPattern",
		context: { readablePattern },
	};

	return function (value: V): ValidationError {
		if (!value)
			return matchPatternError;

		if (!pattern.test(value))
			return matchPatternError;

		return null;
	};
}

export function matchValue<V extends string | null, K>(
	otherValue: V, nameOfKey: string
): ValidationFunction<V, K> {
	const matchValueError = {
		key: "matchValue",
		context: { nameOfKey },
	};

	return function (value: V): ValidationError {
		if (!value || !otherValue)
			return matchValueError;

		if (value.localeCompare(otherValue) !== 0)
			return matchValueError;

		return null;
	};
}
