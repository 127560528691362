import React, { PropsWithChildren } from "react";

export default function Subtitle(props: PropsWithChildren): JSX.Element {
	return (
		<h2
			className="text-primary text-xl"
		>
			{props.children}
		</h2>
	);
}
