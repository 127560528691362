import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import config from "./config";

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: "en",
		debug: config.isDevelopment,
		interpolation: { escapeValue: false },
		returnNull: false,
	})
	.catch((error) => {
		// eslint-disable-next-line no-console
		console.error(error);
	});

export default i18n;
