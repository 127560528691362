import React, {
	createContext,
	forwardRef,
	PropsWithChildren,
	useContext,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import AccountType from "../models/enums/account-type.enum";
import UserForm from "../models/forms/user.form";
import Gender from "../models/enums/gender.enum";
import { DateTime } from "luxon";
import IdentificationKind from "../models/enums/identification-kind.enum";
import Address, { ForeignAddress, NationalAddress } from "../models/types/api/address.type";
import { getUser } from "../queries/user";
import useValidation from "./useValidation";
import {
	maxLength, minLength, postalCode, required, validateIBAN, validateNothing,
} from "../validators";
import {
	ValidationFunction, ValidationResult, Validators,
} from "../models/types/validation.type";
import mapEnum from "../utils/mapEnum";
import { FormError } from "../models/types/error.type";
import { getRelationFields } from "../queries/config";
import UserInformationSections from "../models/enums/user-information-sections";
import RelationFieldsConfigNames from "../models/enums/RelationFields.enum";
import useCountryPhoneCodes from "./useCountryPhoneCode";

type ContextProps = {
	postalAddressDiffersFromResidential: boolean;
	foreignIdentificationKind: boolean;
	accountType: AccountType;
	form: UserForm;
	validationErrors: ValidationResult<UserForm>;
	queryError?: string;
	handlePostalAddressDiffersFromResidential: (value: boolean) => void;
	handleAccountTypeChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
	handleDetailsChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleIdentificationCountryChange: (value: string) => void;
	handleNationalityChange: (value: string) => void;
	handleCountryOfBirthChange: (country: string) => void;
	handleResidentialAddressChange: (
		event: React.ChangeEvent<HTMLInputElement>,
		key: keyof Address
	) => void;
	handlePostalAddressChange: (
		event: React.ChangeEvent<HTMLInputElement>,
		key: keyof Address
	) => void;
	handleCountryChange: (
		country: string,
		addressType: Extract<keyof UserForm, "residentialAddress" | "postalAddress">
	) => void;
	handleGenderChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
	handleIdentificationKindChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
	handleDateOfBirthChange: (dateOfBirth: DateTime) => void;
	handleIdentificationIssueDateChange: (identificationIssueDate: DateTime) => void;
	handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
	isFieldRequired: (relationField: RelationFieldsConfigNames) => boolean;
	isFieldVisible: (field: RelationFieldsConfigNames) => boolean;
	isSectionRequired: (section: UserInformationSections) => boolean;
	setSelectedPhoneNo1Code: (code: string) => void;
	setSelectedPhoneNo2Code: (code: string) => void;
};

const UserFormContext = createContext<ContextProps>({} as ContextProps);

type UserFormProps = {
	onSubmit: (form: UserForm) => void;
	queryError?: string;
	validationError?: ValidationResult<UserForm>;
}

export type UserFormActions = {
	submitForm: () => void;
}

const DEFAULT_NATIONAL_ADDRESS = {
	street: "",
	houseNumber: 0,
	houseLetter: null,
	houseNumberExtension: null,
	postalCode: "",
	city: "",
};

const DEFAULT_FOREIGN_ADDRESS = {
	addressLineAbroad1: "",
	addressLineAbroad2: "",
	addressLineAbroad3: "",
	country: "Nederland",
};

const ADDRESS_KEYS: (Extract<keyof UserForm, "residentialAddress" | "postalAddress">)[] = [ "residentialAddress", "postalAddress" ];

// eslint-disable-next-line react/display-name
export const UserFormProvider = forwardRef<
UserFormActions,
PropsWithChildren<UserFormProps>
>((props, ref) => {
	const [ accountType, setAccountType ] = useState<AccountType>(AccountType.Private);
	const [ form, setForm ] = useState<UserForm>({
		firstName: "",
		initials: "",
		middleName: null,
		surname: "",
		gender: Gender.Male,
		countryOfBirth: "Nederland",
		dateOfBirth: DateTime.now().toString(),
		iban: "",
		ibanAccountName: null,
		identificationIssuingCountry: "",
		identificationKind: IdentificationKind.Other,
		identificationNumber: "",
		organization: null,
		phoneNo1: "",
		phoneNo2: null,
		cityOfBirth: null,
		vatNumber: null,
		residentialAddress: {
			nationalAddress: { ...DEFAULT_NATIONAL_ADDRESS },
			foreignAddress: { ...DEFAULT_FOREIGN_ADDRESS },
		},
		postalAddress: {
			nationalAddress: { ...DEFAULT_NATIONAL_ADDRESS },
			foreignAddress: { ...DEFAULT_FOREIGN_ADDRESS },
		},
		chamberOfCommerceNumber: null,
		emergencyEmailAddress: null,
		nationality: null,
		identificationIssueDate: null,
		identificationIssueLocation: null,
	});

	const [ errors, setErrors ] = useState<FormError<UserForm>>({});
	const [ selectedPhoneNo1Code, setSelectedPhoneNo1Code ] = useState<string>("");
	const [ selectedPhoneNo2Code, setSelectedPhoneNo2Code ] = useState<string>("");

	const user = getUser();
	const validation = useValidation();
	const relationFields = getRelationFields().data;
	const phoneValidation = useCountryPhoneCodes();
	const foreignIdentificationKind = form.identificationKind === IdentificationKind.Passport
		|| form.identificationKind === IdentificationKind.ForeignIdCard;

	const [
		postalAddressDiffersFromResidential,
		setPostalAddressDiffersFromResidential,
	] = useState(user.data?.postalAddress !== null);

	useImperativeHandle(ref, () => ({
		submitForm: handleSubmit,
		form,
		postalAddressDiffersFromResidential,
	}), [ form, postalAddressDiffersFromResidential ]);

	useEffect(() => {
		if (user.isSuccess && user.data) {
			if (user.data?.organization)
				setAccountType(AccountType.Company);

			if (user.data.gender === Gender.Unknown)
				user.data.gender = Gender.Male;

			if (!user.data.identificationKind)
				user.data.identificationKind = IdentificationKind.Passport;

			for (const key of ADDRESS_KEYS) {
				const address = user.data[key] ?? {
					foreignAddress: { ...DEFAULT_FOREIGN_ADDRESS },
					nationalAddress: { ...DEFAULT_NATIONAL_ADDRESS },
				};

				if (!address.foreignAddress)
					address.foreignAddress = { ...DEFAULT_FOREIGN_ADDRESS };

				if (!address.nationalAddress)
					address.nationalAddress = { ...DEFAULT_NATIONAL_ADDRESS };

				user.data[key] = address;
			}

			if (!user.data.countryOfBirth)
				user.data.countryOfBirth = "Nederland";

			if (!user.data.dateOfBirth)
				user.data.dateOfBirth = new Date().toISOString();

			if (!user.data.identificationIssueDate)
				user.data.identificationIssueDate = new Date().toISOString();

			setForm(user.data);
		}
	}, [ user.data, user.isSuccess ]);

	useEffect(() => {
		setErrors(previous => ({
			...previous,
			query: props.queryError,
		}));
	}, [props.queryError]);

	useEffect(() => {
		if (!foreignIdentificationKind) {
			setForm(previous => ({
				...previous,
				identificationIssuingCountry: "Nederland",
			}));
		}
	}, [form.identificationKind]);

	const handlePostalAddressDiffersFromResidential = (value: boolean): void => {
		if (!value) {
			if (form.postalAddress?.foreignAddress) {
				form.postalAddress.foreignAddress = { ...DEFAULT_FOREIGN_ADDRESS };
			}
			if (form.postalAddress?.nationalAddress) {
				form.postalAddress.nationalAddress = { ...DEFAULT_NATIONAL_ADDRESS };
			}
			setPostalAddressDiffersFromResidential(value);
		}
		setPostalAddressDiffersFromResidential(value);
	};

	const isFieldRequired = (field: RelationFieldsConfigNames): boolean => {
		if (relationFields) {
			return relationFields.find(x => x.apiName === field)?.required ?? false;
		}
		return false;
	};

	const isFieldVisible = (field: RelationFieldsConfigNames): boolean => {
		if (relationFields) {
			return relationFields.find(x => x.apiName === field)?.enabled ?? false;
		}
		return false;
	};

	const isSectionVisible = (section: UserInformationSections): boolean => {
		switch (section) {
			case UserInformationSections.ContactInformation:
				return isFieldVisible(RelationFieldsConfigNames.Organization)
					|| isFieldVisible(RelationFieldsConfigNames.FirstName)
					|| isFieldVisible(RelationFieldsConfigNames.MiddleName)
					|| isFieldVisible(RelationFieldsConfigNames.Surname)
					|| isFieldVisible(RelationFieldsConfigNames.Gender)
					|| isFieldVisible(RelationFieldsConfigNames.DateOfBirth)
					|| isFieldVisible(RelationFieldsConfigNames.CountryOfBirth)
					|| isFieldVisible(RelationFieldsConfigNames.CityOfBirth)
					|| isFieldVisible(RelationFieldsConfigNames.PhoneNo1)
					|| isFieldVisible(RelationFieldsConfigNames.PhoneNo2)
					|| isFieldVisible(RelationFieldsConfigNames.ChamberOfCommerceNumber)
					|| isFieldVisible(RelationFieldsConfigNames.EmergencyEmailAddress)
					|| isFieldVisible(RelationFieldsConfigNames.Nationality);
			case UserInformationSections.FinancialInformation:
				return isFieldVisible(RelationFieldsConfigNames.Iban)
					|| isFieldVisible(RelationFieldsConfigNames.IbanAccountName)
					|| isFieldVisible(RelationFieldsConfigNames.VATNumber);
			case UserInformationSections.IdentificationInformation:
				return isFieldVisible(RelationFieldsConfigNames.IdentificationKind)
					|| isFieldVisible(RelationFieldsConfigNames.IdentificationNumber)
					|| isFieldVisible(RelationFieldsConfigNames.IdentificationIssuingCountry);
			case UserInformationSections.ResidentialAddressInformation:
				return isFieldVisible(RelationFieldsConfigNames.ResidentialAddressPostalCode)
					|| isFieldVisible(RelationFieldsConfigNames.ResidentialAddressStreetName)
					|| isFieldVisible(RelationFieldsConfigNames.ResidentialAddressHouseLetter)
					|| isFieldVisible(RelationFieldsConfigNames.ResidentialAddressHouseNumberAddition)
					|| isFieldVisible(RelationFieldsConfigNames.ResidentialAddressHouseNumber)
					|| isFieldVisible(RelationFieldsConfigNames.ResidentialAddressCountry)
					|| isFieldVisible(RelationFieldsConfigNames.ResidentialAddressForeignAddress1)
					|| isFieldVisible(RelationFieldsConfigNames.ResidentialAddressForeignAddress2)
					|| isFieldVisible(RelationFieldsConfigNames.ResidentialAddressForeignAddress3)
					|| isFieldVisible(RelationFieldsConfigNames.ResidentialAddressPlaceOfResidence);
			case UserInformationSections.PostalAddressInformation:
				return isFieldVisible(RelationFieldsConfigNames.PostalAddressPostalCode)
					|| isFieldVisible(RelationFieldsConfigNames.PostalAddressStreetName)
					|| isFieldVisible(RelationFieldsConfigNames.PostalAddressHouseLetter)
					|| isFieldVisible(RelationFieldsConfigNames.PostalAddressHouseNumberAddition)
					|| isFieldVisible(RelationFieldsConfigNames.PostalAddressHouseNumber)
					|| isFieldVisible(RelationFieldsConfigNames.PostalAddressCountry)
					|| isFieldVisible(RelationFieldsConfigNames.PostalAddressForeignAddress1)
					|| isFieldVisible(RelationFieldsConfigNames.PostalAddressForeignAddress2)
					|| isFieldVisible(RelationFieldsConfigNames.PostalAddressForeignAddress3)
					|| isFieldVisible(RelationFieldsConfigNames.PostalAddressPlaceOfResidence);
		}
	};

	const handleAccountTypeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		const type = mapEnum(event.target.value, AccountType, AccountType.Private);
		if (AccountType.Private) {
			setForm(previous => ({
				...previous, chamberOfCommerceNumber: "", organization: "",
			}));
		}
		setAccountType(type);
	};

	const handleDetailsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setForm(previous => ({ ...previous, [event.target.name]: event.target.value }));
	};

	const handleIdentificationCountryChange = (value: string): void => {
		setForm(previous => ({ ...previous, identificationIssuingCountry: value }));
	};

	const handleNationalityChange = (value: string): void => {
		setForm(previous => ({ ...previous, nationality: value }));
	};

	const handleCountryOfBirthChange = (country: string): void => {
		setForm(previous => ({ ...previous, countryOfBirth: country }));
	};

	const handleAddressChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		addressKey: Extract<keyof UserForm, "residentialAddress" | "postalAddress">,
		key: keyof Address
	): void => {
		const address = { ...form[addressKey] };
		const addressChild = address[key];

		if (!addressChild) return;

		const name = event.target.name as keyof typeof addressChild;

		(addressChild[name] as string | number) = event.target.value;
		(address[key] as ForeignAddress | NationalAddress) = addressChild;

		setForm(previous => ({
			...previous,
			[addressKey]: address,
		}));
	};

	const handleResidentialAddressChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		key: keyof Address
	): void => {
		handleAddressChange(event, "residentialAddress", key);
	};

	const handlePostalAddressChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		key: keyof Address
	): void => {
		handleAddressChange(event, "postalAddress", key);
	};

	const handleCountryChange = (
		country: string,
		addressKey: Extract<keyof UserForm, "residentialAddress" | "postalAddress">
	): void => {
		const address = { ...form[addressKey] };

		if (address.foreignAddress?.country)
			address.foreignAddress.country = country;

		setForm(prevState => ({
			...prevState,
			[addressKey]: address,
		}));
	};

	const handleGenderChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		const gender = mapEnum(event.target.value, Gender, Gender.Unknown);

		setForm(previous => ({ ...previous, gender }));
	};

	const handleIdentificationKindChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		const identificationKindValue = event.target.value as keyof typeof IdentificationKind;
		const identificationKind = mapEnum(identificationKindValue, IdentificationKind, IdentificationKind.Other);

		setForm(previous => ({
			...previous,
			identificationKind,
		}));
	};
	const handleDateOfBirthChange = (dateOfBirth: DateTime): void => {
		setForm(previous => ({ ...previous, dateOfBirth: dateOfBirth.toISODate() }));
	};

	const handleIdentificationIssueDateChange = (identificationIssueDate: DateTime): void => {
		setForm(previous => ({ ...previous, identificationIssueDate: identificationIssueDate.toISODate() }));
	};

	// Handles validation logic for the form including checks for required fields and IBAN validation
	const handleSubmit = (): void => {
		const formState = structuredClone(form) as UserForm;

		if (form === user.data as UserForm) {
			return;
		}

		const ibanValidators: ValidationFunction<UserForm["iban"], string>[] = [
			isFieldRequired(RelationFieldsConfigNames.Iban) ? required : validateNothing,
			validateIBAN,
		];
		const organizationValidators: ValidationFunction<UserForm["organization"], string>[] = [];
		const chamberOfCommerceValidators: ValidationFunction<UserForm["chamberOfCommerceNumber"], string>[] = [];

		const nationalAddressValidators = {
			street: [required],
			houseNumber: [required],
			postalCode: [
				required, postalCode, maxLength(6),
			],
			city: [required],
			houseNumberExtension: [maxLength(4)],
			houseLetter: [maxLength(1)],
		};

		const foreignAddressValidators: undefined | object = {
			addressLineAbroad1: [required],
			addressLineAbroad2: [required],
			country: [required],
		};

		if (accountType === AccountType.Company) {
			organizationValidators.push(
				isFieldRequired(RelationFieldsConfigNames.Organization) ? required : validateNothing,
				maxLength(132)
			);

			chamberOfCommerceValidators.push(
				isFieldRequired(RelationFieldsConfigNames.ChamberOfCommerceNumber) ? required : validateNothing,
				maxLength(8)
			);
		}

		const validators: Validators<UserForm> = {
			identificationNumber: [
				isFieldRequired(RelationFieldsConfigNames.IdentificationNumber) ? required : validateNothing,
				maxLength(60),
			],
			identificationIssuingCountry: isFieldRequired(RelationFieldsConfigNames.IdentificationIssuingCountry)
				? required
				: validateNothing,
			identificationKind: isFieldRequired(RelationFieldsConfigNames.IdentificationKind)
				? required
				: validateNothing,
			emergencyEmailAddress: [
				isFieldRequired(RelationFieldsConfigNames.EmergencyEmailAddress) ? required : validateNothing,
				maxLength(40),
			],
			organization: organizationValidators,
			middleName: [
				isFieldRequired(RelationFieldsConfigNames.MiddleName) ? required : validateNothing,
				maxLength(10),
			],
			firstName: [
				isFieldRequired(RelationFieldsConfigNames.FirstName) ? required : validateNothing,
				maxLength(128),
			],
			initials: [
				isFieldRequired(RelationFieldsConfigNames.Initials) ? required : validateNothing,
				maxLength(10),
			],
			surname: [
				isFieldRequired(RelationFieldsConfigNames.Surname) ? required : validateNothing,
				maxLength(200),
			],
			cityOfBirth: [
				isFieldRequired(RelationFieldsConfigNames.CityOfBirth) ? required : validateNothing,
				maxLength(80),
			],
			phoneNo1: [
				// isFieldRequired(RelationFieldsConfigNames.PhoneNo1) ? required : validateNothing,
				validateNothing,
				maxLength(30),
			],
			phoneNo2: [
				// isFieldRequired(RelationFieldsConfigNames.PhoneNo2) ? required : validateNothing,
				validateNothing,
				maxLength(30),
			],
			iban: isFieldRequired(RelationFieldsConfigNames.Iban) ? ibanValidators : validateNothing,
			ibanAccountName: [
				isFieldRequired(RelationFieldsConfigNames.IbanAccountName) ? required : validateNothing,
				maxLength(50),
			],
			chamberOfCommerceNumber: chamberOfCommerceValidators,
			vatNumber: [
				isFieldRequired(RelationFieldsConfigNames.VATNumber) ? required : validateNothing,
				maxLength(15),
				minLength(4),
			],
			identificationIssueLocation: [
				isFieldRequired(RelationFieldsConfigNames.IdentificationIssueLocation) ? required : validateNothing,
				maxLength(50),
			],
		};

		if (isSectionVisible(UserInformationSections.ResidentialAddressInformation)) {
			validators.residentialAddress = {
				nationalAddress: nationalAddressValidators,
				foreignAddress: foreignAddressValidators,
			};
		}

		if (isSectionVisible(UserInformationSections.PostalAddressInformation) && postalAddressDiffersFromResidential) {
			validators.postalAddress = {
				nationalAddress: nationalAddressValidators,
				foreignAddress: foreignAddressValidators,
			};
		}

		for (const key of ADDRESS_KEYS) {
			const country = form[key]?.foreignAddress?.country ?? "Nederland";
			const addressKey: keyof Address = country === "Nederland" ? "foreignAddress" : "nationalAddress";

			if (validators[key]) (validators[key] as Address)[addressKey] = null;
		}
		console.log(errors);
		const validationResult = validation.validate(form, validators);

		if (validationResult) {
			setErrors({
				validation: validationResult,
				query: "default.validationError",
			});

			return;
		}

		setErrors({});

		for (const key of ADDRESS_KEYS) {
			const country = form[key]?.foreignAddress?.country ?? "Nederland";

			if (country === "Nederland") {
				const address = { ...formState[key] };
				if (address.foreignAddress) {
					address.foreignAddress = null;
				}
				address.nationalAddress = address.nationalAddress ?? null;
				formState[key] = address;
			} else {
				const address = { ...formState[key] };
				if (address.nationalAddress) {
					address.nationalAddress = null;
				}
				address.foreignAddress = address.foreignAddress ?? null;
				formState[key] = address;
			}
		}

		if (!postalAddressDiffersFromResidential) {
			formState.postalAddress = null;
		}

		// Ensure identificationKind is correctly set before submitting
		formState.identificationKind = form.identificationKind;

		/* verify the final phone number format from the regex */

		if (selectedPhoneNo1Code) {
			formState.phoneNo1 = `${selectedPhoneNo1Code}${form.phoneNo1 ?? ""}`;
		}

		if (selectedPhoneNo2Code) {
			formState.phoneNo2 = `${selectedPhoneNo2Code}${form.phoneNo2 ?? ""}`;
		}

		// if (formState.phoneNo2 && selectedPhoneNo2Code) {
		// 	const phoneCodeRegex = phoneValidation.countryPhoneCodes.find(
		// 		x => x.code === selectedPhoneNo2Code)?.regexPattern ?? "";
		// 	const regex = new RegExp(phoneCodeRegex);
		// 	if (regex[Symbol.match](formState.phoneNo2) === null) {
		// 		// setErrors({
		// 		// 	validation: { phoneNo2: [{ key: "public.validation.phoneNumber" }] },
		// 		// 	query: "default.validationError",
		// 		// });
		// 		// return;
		// 	}
		// }

		console.log(errors);
		props.onSubmit(formState);
	};

	return (
		<UserFormContext.Provider
			value={{
				postalAddressDiffersFromResidential,
				foreignIdentificationKind,
				accountType,
				form,
				validationErrors: errors.validation ?? props.validationError ?? {},
				queryError: errors.query,
				handlePostalAddressDiffersFromResidential,
				handleAccountTypeChange,
				handleDetailsChange,
				handleIdentificationCountryChange,
				handleNationalityChange,
				handleCountryOfBirthChange,
				handleResidentialAddressChange,
				handlePostalAddressChange,
				handleCountryChange,
				handleGenderChange,
				handleIdentificationKindChange,
				handleDateOfBirthChange,
				handleIdentificationIssueDateChange,
				handleSubmit,
				isFieldRequired,
				isFieldVisible,
				isSectionRequired: isSectionVisible,
				setSelectedPhoneNo1Code,
				setSelectedPhoneNo2Code,
			}}
		>
			{props.children}
		</UserFormContext.Provider>
	);
});

export default function useUserForm(): ContextProps {
	return useContext(UserFormContext);
}
