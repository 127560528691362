import axios, { AxiosError } from "axios";
import config from "../config";
import { STORAGE_KEYS } from "../hooks/useAuth";
import { STORAGE_KEY_URL } from "../hooks/useEnvironment";

const api = axios.create({ baseURL: `${config.apiPrefix}https://brixxonline.com`, headers: { "Content-Type": "application/json" } });

export default api;

api.interceptors.request.use((request) => {
	const token = sessionStorage.getItem(STORAGE_KEYS.token);
	const url = sessionStorage.getItem(STORAGE_KEY_URL);

	if (request.baseURL !== url)
		request.baseURL = url ?? "";

	if (token)
		request.headers.set("token", token);

	return request;
});

api.interceptors.response.use((response) => {
	return response;
}, (error) => {
	if (error instanceof AxiosError && error.response?.status === 401) {
		const pathname = location.pathname;

		if (!pathname.includes("/auth/signin"))
			window.location.replace(`/auth/signout?expired&return_uri=${encodeURIComponent(document.location.pathname)}`);

		throw error;
	}

	throw error;
});
