import React, {
	createContext, PropsWithChildren, useContext,
} from "react";
import {
	ValidationFunction, ValidationResult, Validators,
} from "../models/types/validation.type";

type ValidationContextProps = {
	validate<T extends object>(
		object: T,
		validators: Validators<T>,
	): ValidationResult<T>;
}

const ValidationContext = createContext<ValidationContextProps>({} as ValidationContextProps);

export function ValidationProvider(props: PropsWithChildren): JSX.Element {
	function validate<T extends object, K extends keyof T>(
		object: T,
		validators: Validators<T>,
	): ValidationResult<T> {
		let encounteredErrors = false;
		const result: ValidationResult<T> = {} as ValidationResult<T>;

		if (!object)
			return null;

		const keys = Object.keys(object) as [ K ];

		for (let i = 0; i < keys.length; i++) {
			const key = keys[i];
			const valueValidators = validators[key];

			if (!valueValidators) continue;

			const value = object[key];
			const errors = [];

			if (Array.isArray(valueValidators)) {
				// eslint-disable-next-line
				const valueValidatorArray = valueValidators as
					ValidationFunction<T[typeof key], typeof key>[];

				for (let j = 0; j < valueValidatorArray.length; j++) {
					const validator = valueValidatorArray[j];
					const error = validator(value, key);

					if (!error) continue;

					errors.push(error);
				}

				if (errors.length !== 0 && !!result) {
					encounteredErrors = true;
					result[key] = errors;
				}
			} else {
				const validationResults
					= validate(value as object, valueValidators);

				if (validationResults && !!result) {
					encounteredErrors = true;
					result[key] = validationResults;
				}
			}
		}

		return encounteredErrors ? result : null;
	}

	return (
		<ValidationContext.Provider value={{ validate }}>
			{props.children}
		</ValidationContext.Provider>
	);
}

export default function useValidation(): ValidationContextProps {
	return useContext(ValidationContext);
}
