import api from "../axios/api";
import { openFile } from "./file";

type DocumentType = "review" | "submitted";

const downloadDocument = (type: DocumentType, id: number | string): void => {
	const baseUrl = api.defaults.baseURL ?? "/";
	const url = `${baseUrl}documents/${type}/${id}/`;

	void openFile(url);
};

export default downloadDocument;
