import React from "react";
import { Navigate } from "react-router-dom";
import SignInPage from "./pages/auth/SignInPage";
import DashboardPage from "./pages/DashboardPage";
import InvalidEnvironmentPage from "./pages/error/InvalidEnvironmentPage";
import { Route } from "./Router";
import SignOutPage from "./pages/auth/SignOutPage";
import PasswordResetPage from "./pages/auth/PasswordResetPage";
import EditUserPage from "./pages/user/EditUserPage";
import SetPasswordPage from "./pages/onboarding/SetPasswordPage";
import InvoicesPage from "./pages/InvoicesPage";
import SettlementsOverviewPage from "./pages/settlements/SettlementsOverviewPage";
import DocumentsPage from "./pages/DocumentsPage";
import TicketsOverviewPage from "./pages/tickets/TicketsOverviewPage";
import TicketPage from "./pages/tickets/TicketPage";
import OnboardingPage from "./pages/onboarding/OnboardingPage";
import ExploitationPage from "./pages/ExploitationPage";
import RentArrearsPage from "./pages/RentArrearsPage";
import NewTicketPage from "./pages/tickets/NewTicketPage";
import PrivacyDisclaimer from "./pages/PrivacyDisclaimer";
import DossiersOverview from "./pages/dossiers/DossiersOverview";
import DossierTenantDocuments from "./pages/dossiers/DossierTenantDocuments";
import DossierRentalContractDocuments from "./pages/dossiers/DossierRentalContractDocuments";
import OwnerManagementContractTickets from "./pages/owner-tickets/OwnerManagementContractTickets";
import DossierUnitDocuments from "./pages/dossiers/DossierUnitDocuments";

const publicRoutes: Route[] = [
	{
		path: "/",
		element: <DashboardPage />,
	},
	{
		path: "/auth/signout/",
		element: <SignOutPage />,
	},
	{
		path: "/:environment/auth/resetpassword",
		element: <PasswordResetPage />,
	},
	{
		path: "/:environment/onboarding/setpassword",
		element: <SetPasswordPage />,
	},
	{
		path: "/onboarding/:step",
		element: <OnboardingPage />,
	},
	{
		path: "/documents/",
		element: <DocumentsPage />,
		requiredCapability: ["Documents"],
	},
	{
		path: "/user/edit",
		element: <EditUserPage />,
		requiredCapability: ["MyInfo"],
	},
	{
		path: "/error/environment",
		element: <InvalidEnvironmentPage />,
	},
	{
		path: "/invoices/",
		element: <InvoicesPage />,
		requiredCapability: ["Invoices"],
	},
	{
		path: "/tickets",
		element: <TicketsOverviewPage />,
		requiredCapability: ["Tickets"],
	},
	{
		path: "/ticket/new",
		element: <NewTicketPage />,
		requiredCapability: ["Tickets"],
	},
	{
		path: "/ticket/:id",
		element: <TicketPage />,
		requiredCapability: ["Tickets"],
	},
	{
		path: "/:environment/ticket/:id",
		element: <SignInPage />,
	},
	{
		path: "/ownersettlements/",
		element: <SettlementsOverviewPage />,
		requiredCapability: ["OwnerSettlements"],
	},
	{
		path: "/exploitation",
		element: <ExploitationPage />,
		requiredCapability: ["Finance"],
	},
	{
		path: "/rentArrears",
		element: <RentArrearsPage />,
		requiredCapability: ["OwnerSettlements"],
	},
	{
		path: "/:environment/",
		element: <Navigate replace to="./auth/signin/" />,
	},
	{
		path: "/:environment/auth/signin/",
		element: <SignInPage />,
	},
	{
		path: "/:environment/auth/signin/:activated",
		element: <SignInPage />,
	},
	{
		path: "/:environment/Huurder/Meldingen/Ticket/:id",
		element: <SignInPage />,
	},
	{
		path: "/:environment/privacy",
		element: <PrivacyDisclaimer />,
	},
	{
		path: "/dossiers",
		element: <DossiersOverview />,
		requiredCapability: [
			"DossierRelationDocuments", "DossierUnitDocuments", "DossierRentalContractDocuments",
		],
	},
	{
		path: "/dossiers/relation/:tenantId",
		element: <DossierTenantDocuments />,
		requiredCapability: ["DossierRelationDocuments"],
	},
	{
		path: "/dossiers/documents/:contractId",
		element: <DossierRentalContractDocuments />,
		requiredCapability: ["DossierRentalContractDocuments"],
	},
	{
		path: "/dossiers/documents/object/:unitId/:tenantId",
		element: <DossierUnitDocuments />,
		requiredCapability: ["DossierUnitDocuments"],
	},
	{
		path: "/ownertickets",
		element: <OwnerManagementContractTickets />,
		requiredCapability: ["OwnerTickets"],
	},
	{
		path: "/ownertickets/:managementContractId",
		element: <OwnerManagementContractTickets />,
		requiredCapability: ["OwnerTickets"],
	},
	{
		path: "/ownertickets/:ownerManagementContractId/:ticketId",
		element: <TicketPage />,
		requiredCapability: ["OwnerTickets"],
	},
];

export default publicRoutes;
