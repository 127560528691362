import {
	useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult,
} from "react-query";
import api from "../axios/api";
import UserResponse from "../models/types/api/user.type";
import UserForm from "../models/forms/user.form";
import ResponseError, { FormError } from "../models/types/error.type";
import { AxiosError } from "axios";
import bodyToError from "../utils/bodyToError";

export function getUser(): UseQueryResult<UserResponse, void> {
	return useQuery(["user"], async () => {
		try {
			const result = await api.get<UserResponse>("/relation");
			return result.data;
		} catch (error) {
			const errors: FormError<object> = { query: "default.error" };

			return Promise.reject(errors);
		}
	});
}

export function updateUser(environment: string,
	options?: { onSuccess: () => void }
): UseMutationResult<void, FormError<UserForm>, UserForm, void> {
	const queryClient = useQueryClient();
	return useMutation(async (form: UserForm) => {
		try {
			await api.put(`/relation?environment=${environment}`, form );
		} catch (error) {
			const errors: FormError<UserForm> = {};

			if (error instanceof AxiosError && !!error.response?.status) {
				switch (error.response.status) {
					case 400:
						errors.validation
							= bodyToError(error.response.data as ResponseError<UserForm>);
						break;
				}
			}

			errors.query = "default.error";

			return Promise.reject(errors);
		}
	}, {
		onSuccess: async (): Promise<void> => {
			await queryClient.invalidateQueries(["user"]);

			options?.onSuccess();
		},
	});
}
