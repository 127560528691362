import React from "react";

type StatDisplayProps = {
	title: string;
	value: string;
}

export default function StatDisplay(props: StatDisplayProps): JSX.Element {
	return (
		<div className="flex flex-row py-1">
			<p className="basis-2/5 font-semibold">
				{props.title}
			</p>

			<p className="basis-full">
				{props.value}
			</p>
		</div>
	);
}
