import React, { useEffect, useState } from "react";
import { getTicketRemedies } from "../../../../queries/tickets";
import ListSelector, { ListSelectorElement } from "../../../molecules/ListSelector";
import RemedyResponse from "../../../../models/types/api/remedy.type";
import Modal from "../../../atoms/Modal";
import { useTranslation } from "react-i18next";
import Subtitle from "../../../atoms/Subtitle";
import parseHtml from "html-react-parser";
import ContentLoader from "../../../molecules/ContentLoader";

type SelectRemedyProps = {
	categoryId: string;
	onEmptyResult: () => void;
};

export default function SelectRemedy(props: SelectRemedyProps): JSX.Element {
	const [ selectedRemedy, setSelectedRemedy ] = useState<RemedyResponse | null>(null);

	const remedies = getTicketRemedies(props.categoryId);

	const { t, i18n } = useTranslation("tickets", { keyPrefix: "new.steps.suggested_solutions" });

	useEffect(() => {
		void remedies.refetch();
	}, [i18n.language]);

	useEffect(() => {
		if ((!remedies.data || remedies.data.length <= 0) && !remedies.isLoading)
			props.onEmptyResult();
	}, [remedies.data]);

	const closeModal = (): void => {
		setSelectedRemedy(null);
	};

	if (remedies.isLoading)
		return <ContentLoader />;

	if (!remedies.data || remedies.data.length <= 0)
		return <div />;

	return (
		<>
			<ListSelector
				keyPrefix="remedy"
				searchLabel={t("search")}
				items={remedies.data
					.map(remedy => ({
						...remedy,
						searchKey: remedy.title ?? "",
					}))}
				default={selectedRemedy?.title}
				onSelect={setSelectedRemedy}
			>
				{(item): ListSelectorElement => ({
					key: item.remedyId ?? "",
					element: (
						<p>
							{item.title}
						</p>
					),
				})}
			</ListSelector>

			<Modal
				open={!!selectedRemedy}
				onSubmit={closeModal}
				buttonSubmitText={t("modal.cancel_button")}
				largeModal
			>
				<div className="flex flex-col gap-4 w-full first:border-b">
					<Subtitle>
						{selectedRemedy?.title ?? t("modal.title")}
					</Subtitle>

					{!!selectedRemedy?.text && parseHtml(selectedRemedy?.text)}

					{!!selectedRemedy?.videoUrl
						&& (
							<iframe
								src={selectedRemedy.videoUrl}
								height={500}
							/>
						)}
				</div>
			</Modal>
		</>
	);
}
