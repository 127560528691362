import React, { PropsWithChildren, useEffect } from "react";
import { useTranslation } from "react-i18next";
import makeStyle from "../../style/makeStyle";
import Button from "./Button";

type Props = {
	open: boolean;
	cancelOnEsc?: boolean;
	allowedToSubmit?: boolean;
	loading?: boolean;

	onCancel?: () => void;
	onSubmit: () => void;

	buttonSubmitText?: string;
	buttonCancelText?: string;

	largeModal?: boolean;
}

export default function Modal(props: PropsWithChildren<Props>): JSX.Element {
	const { t } = useTranslation("components");

	const backdropStyle = makeStyle("fixed top-0 left-0 flex items-center justify-center w-full h-full p-4 bg-black/50 z-50", [!props.open && "hidden"]);
	const modalStyle = makeStyle(
		"flex flex-col max-w-full max-h-full p-4 drop-shadow-xl rounded-md bg-white",
		[props.largeModal ? "md:max-w-[80%] lg:max-w-[60%] xl:max-w-[40%]" : "md:max-h-[66%] md:max-w-[50%]"]
	);

	const handleKeyPress = (event: KeyboardEvent): void => {
		if (props.cancelOnEsc && props.open && event.code === "Escape")
			props.onCancel?.();
	};

	useEffect(() => {
		document.addEventListener("keydown", handleKeyPress);

		return () => document.removeEventListener("keydown", handleKeyPress);
	}, []);

	return (
		<div className={backdropStyle}>
			<div className={modalStyle}>
				<div className="overflow-y-auto">
					{props.children}
				</div>

				<div className="flex flex-row justify-end mt-4">
					{!!props.onCancel && (
						<Button
							outlined
							variant="error"
							onClick={props.onCancel}
							className="py-1 px-4 rounded-md font-medium shadow-md text-white info"
							disabled={props.loading}
							e2e="Modal.Cancel"
						>
							{props.buttonCancelText ?? t("modal.cancel")}
						</Button>
					)}

					<Button
						onClick={props.onSubmit}
						className="ml-1 py-1 px-4 rounded-md font-medium shadow-md text-white info"
						disabled={props.allowedToSubmit === false}
						loading={props.loading}
						e2e="Modal.Submit"
					>
						{props.buttonSubmitText ?? t("modal.submit")}
					</Button>
				</div>
			</div>
		</div>
	);
}
