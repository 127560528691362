import { useQuery, UseQueryResult } from "react-query";
import api from "../axios/api";
import { RentalContractUnit } from "../models/types/api/rental-contract-unit.type";

type QueryResult = UseQueryResult<RentalContractUnit[], null>;

export function getRentalContractUnits(): QueryResult {
	return useQuery("rentalContractUnits", async (): Promise<RentalContractUnit[]> => {
		const response = await api.get<RentalContractUnit[]>("/rentalcontracts/units");

		return response.data ?? [];
	});
}
