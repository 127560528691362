import { useQuery, UseQueryResult } from "react-query";
import api from "../axios/api";
import RentalContractResponse from "../models/types/api/rental-contract-response.type";
import DossierResponse from "../models/types/api/dossier.type";

export function getDossiers(
	managementContractId: number): UseQueryResult<RentalContractResponse[], void> {
	return useQuery("tenantRentalContracts", async (): Promise<RentalContractResponse[]> => {

		const result = await api.get(`/dossiers/tenant/${managementContractId}`);

		return result.data as RentalContractResponse[];
	}, { enabled: false });
}

export function getDossierRentalContractDocuments(
	rentalContractId: number, managementContractId: number): UseQueryResult<DossierResponse[], void> {
	return useQuery("rentalContractsDocuments", async (): Promise<DossierResponse[]> => {

		const result = await api.get(`dossiers/rentalcontracts/${rentalContractId}/${managementContractId}`);

		return result.data as DossierResponse[];
	}, { enabled: false });
}

export function getDossierUnitDocuments(unitId: number): UseQueryResult<DossierResponse[], void> {
	return useQuery("rentalContractsUnitDocuments", async (): Promise<DossierResponse[]> => {

		const result = await api.get(`/dossiers/unit/${unitId}`);

		return result.data as DossierResponse[];
	}, { enabled: false });
}
