import React, {
	createContext, PropsWithChildren, useContext,
} from "react";
import { SignInResponse } from "../models/types/api/sign-in.type";
import {
	Capability, Language, UserAction,
} from "../models/types/auth.type";
import unique from "../utils/unique";
import { getSessionStorageArray } from "../utils/sessionStorage";
import api from "../axios/api";

type ContextProps = {
	authenticated: () => boolean;
	authenticate: (data: SignInResponse) => void;
	getToken: () => string | null;
	getCapabilities: () => Capability[];
	hasCapability: (capability: Capability[]) => boolean
	getActions: () => UserAction[];
	refreshActions: () => Promise<UserAction[]>;
	getDisplayName: () => string | null;
	getPreferredLanguage: () => Language | null;
	getEmail: () => string | null;
	signOut: () => void;
	isUserCandidate: () => boolean;
	setDisplayName: (displayName: string) => void;
};

const AuthContext = createContext<ContextProps>({} as ContextProps);

export const STORAGE_KEYS = {
	token: "token",
	capabilities: "capabilities",
	actions: "actions",
	displayName: "display_name",
	email: "email",
	preferredLanguage: "preferred_language",
};

export function AuthProvider(props: PropsWithChildren): JSX.Element {
	const authenticate = (data: SignInResponse): void => {
		setToken(data.token);
		setCapabilities(data.capabilities);
		setActions(data.userActions);
		setDisplayName(data.displayName);
		setEmail(data.email);
		setPreferredLanguage(data.preferredLanguage);
		if (data.userActions.includes("CompleteUserProfile")) setCandidateStatus(false);
	};

	const authenticated = (): boolean =>
		!!sessionStorage.getItem(STORAGE_KEYS.token);

	const getToken = (): string | null =>
		sessionStorage.getItem(STORAGE_KEYS.token);

	const setToken = (token: string): void =>
		sessionStorage.setItem(STORAGE_KEYS.token, token);

	const getCapabilities = (): Capability[] => getSessionStorageArray(STORAGE_KEYS.capabilities);

	const setCapabilities = (capabilities: {
		[key: string]: Capability[];
	}): void => {
		const data: Capability[] = unique(Object.values(capabilities).flat());

		sessionStorage.setItem(STORAGE_KEYS.capabilities, JSON.stringify(data));
	};

	const areItemsInArray = (arr1: Capability[], arr2: Capability[]): boolean =>
		arr1.some(item => arr2.includes(item));

	const hasCapability = (capability: Capability[]): boolean => {
		const capabilities = getCapabilities();
		return areItemsInArray(capabilities, capability);
	};

	const getActions = (): UserAction[] => getSessionStorageArray(STORAGE_KEYS.actions);

	const setActions = (actions: UserAction[]): void =>
		sessionStorage.setItem(STORAGE_KEYS.actions, JSON.stringify(actions));

	const refreshActions = async (): Promise<UserAction[]> => {
		try {
			const result = await api.get("/relation/actions");
			const actions = result.data as UserAction[];

			setActions(actions);

			return actions;
		} catch {
			return getActions();
		}
	};

	const isUserCandidate = (): boolean => {
		return getActions().includes("CompleteUserProfile");
	};

	const getDisplayName = (): string | null =>
		sessionStorage.getItem(STORAGE_KEYS.displayName);

	const setDisplayName = (displayName: string): void =>
		sessionStorage.setItem(STORAGE_KEYS.displayName, displayName);

	const getEmail = (): string | null =>
		sessionStorage.getItem(STORAGE_KEYS.email);

	const setEmail = (email: string): void =>
		sessionStorage.setItem(STORAGE_KEYS.email, email);

	const getPreferredLanguage = (): Language | null =>
		sessionStorage.getItem(STORAGE_KEYS.preferredLanguage) as Language | null;

	const setPreferredLanguage = (preferredLanguage: Language): void =>
		sessionStorage.setItem(STORAGE_KEYS.preferredLanguage, preferredLanguage);

	const signOut = (): void => {
		Object.values(STORAGE_KEYS).forEach(key => sessionStorage.removeItem(key));
	};

	const setCandidateStatus = (value: boolean) : void => {
		localStorage.setItem("isCandidate", value.toString());
	};

	return (
		<AuthContext.Provider
			value={{
				authenticated,
				authenticate,
				getToken,
				getCapabilities,
				hasCapability,
				getActions,
				refreshActions,
				getDisplayName,
				getEmail,
				getPreferredLanguage,
				signOut,
				isUserCandidate,
				setDisplayName,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
}

export default function useAuth(): ContextProps {
	return useContext(AuthContext);
}
