import { useMutation, UseMutationResult } from "react-query";
import api from "../axios/api";
import SignInForm from "../models/forms/auth/sign-in.form";
import ResponseError, { FormError } from "../models/types/error.type";
import { AxiosError } from "axios";
import bodyToError from "../utils/bodyToError";
import useAuth from "../hooks/useAuth";
import { SignInResponse } from "../models/types/api/sign-in.type";
import useCountries from "../hooks/useCountries";

type AuthErrors = SignInForm & {
	tooManyFailures?: string;
};

type MutationResult = UseMutationResult<void, FormError<AuthErrors>, SignInForm>;

const UNAUTHORIZED = [ 400, 401 ];

export function signIn(environment?: string): MutationResult {
	const auth = useAuth();
	const countries = useCountries();

	return useMutation({
		mutationFn: async (form: SignInForm): Promise<void> => {
			if (!environment) throw new Error("No environment provided to signIn mutation");
			try {
				const result = await api.post<unknown, AxiosResponse<SignInResponse>, SignInForm>(`/${environment}/authentication/login`, form);
				auth.authenticate(result.data);
			} catch (error) {
				const queryError: FormError<AuthErrors> = {};

				if (error instanceof AxiosError && !!error.response) {
					const status = error.response.status;

					if (!status || UNAUTHORIZED.includes(status)) {
						const body = error.response.data as ResponseError<AuthErrors>;
						const errors = bodyToError<AuthErrors>(body);

						queryError.validation = errors;
						queryError.query = "tooManyFailures" in errors ? "errors.tooManyFailures" : "errors.wrongCombination";

						return Promise.reject(queryError);
					}
				}

				queryError.query = "translation:default.error";

				return Promise.reject(queryError);
			}
		},
		onSuccess() {
			countries.init();
		},
	});
}
