import React, { useEffect, useState } from "react";
import PublicLayout from "../../layouts/PublicLayout";
import Input from "../atoms/input/Input";
import { useTranslation } from "react-i18next";
import Button from "../atoms/Button";
import SetPasswordForm from "../../models/forms/onboarding/set-password.form";
import {
	useNavigate, useParams, useSearchParams,
} from "react-router-dom";
import Alert from "../atoms/Alert";
import api from "../../axios/api";
import useEnvironment from "../../hooks/useEnvironment";
import { setPassword } from "../../queries/onboarding";
import { toast } from "react-toastify";
import i18next from "i18next";
import { signIn } from "../../queries/auth";
import SignInForm from "../../models/forms/auth/sign-in.form";

export default function SetPassword(): JSX.Element {
	const [error] = useState("");

	const [ code, setCode ] = useState("");
	const [ hash, setHash ] = useState("");

	const [ form, setForm ] = useState<SetPasswordForm>({
		email: "",
		password: "",
		repeatPassword: "",
	});

	const [searchParams] = useSearchParams();
	const params = useParams();
	const environment = useEnvironment();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const mutation = setPassword({
		onSuccess() {
			toast.success(t("public.onboarding.setPassword.success"));
			const credentials: SignInForm = {
				username: form.email,
				password: form.password,
			};

			mutate(credentials, {
				onSuccess: (): void => {
					const redirectUri = searchParams.get("redirect_uri");
					if (redirectUri) navigate(redirectUri);
					else navigate("/");
				},
			});
		},
	});

	const { mutate } = signIn(environment.getEnvironment());

	useEffect(() => {
		if (!environment.initialized)
			return;

		validateParameters()
			.catch(() => {
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				navigate(`/${params.environment}/auth/signin/true`);
			});
	}, [environment.initialized]);

	const validateParameters = async (): Promise<void> => {
		const paramCode = searchParams.get("code");
		const paramHash = searchParams.get("hash");
		const isEN = searchParams.get("en") === "true";

		// check whether isEN is true
		if (isEN) {
			await i18next.changeLanguage("en");
		} else {
			await i18next.changeLanguage("nl");
		}

		if (!paramCode || !paramHash)
			throw new Error();

		setCode(paramCode);
		setHash(paramHash);

		const response = await api.post(`${environment.getEnvironment()}/onboarding/start`, {
			code: paramCode,
			hash: paramHash,
		});

		// get email from response
		const email = removeSecondaryEmailAddresses((response.data as { email: string }).email);

		// set email in form
		setForm(prevState => ({
			...prevState,
			email,
		}));
	};

	const removeSecondaryEmailAddresses = (email: string ): string => {
		const indexOfFirstComma = email.indexOf(",");

		if (indexOfFirstComma !== -1) {
			return email.substring(0, indexOfFirstComma);
		}

		// Return the original string if no comma is found
		return email;
	};
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const name = event.target.name;

		if (!name) return;

		setForm(prevState => ({
			...prevState,
			[name]: event.target.value,
		}));
	};

	const handleSubmit = (event: React.FormEvent): void => {
		event.preventDefault();
		mutation.mutate({
			...form, code, hash,
		});
	};

	return (
		<PublicLayout title={t("public.onboarding.setPassword.title")}>
			<form className="flex flex-col gap-4 pt-4" onSubmit={handleSubmit}>
				{(!!mutation.error?.query || !!error) && (
					<Alert variant="error">
						{t(mutation.error?.query ?? error)}
					</Alert>
				)}

				<Input
					label={t("public.onboarding.setPassword.email")}
					type="email"
					value={form.email}
					onChange={handleChange}
					name="email"
					disabled={!!error}
					errors={mutation.error?.validation?.email}
					e2e="SetPassword.EmailAddress"
				/>

				<Input
					label={t("public.onboarding.setPassword.password")}
					type="password"
					value={form.password}
					onChange={handleChange}
					name="password"
					disabled={!!error}
					errors={mutation.error?.validation?.password}
					e2e="SetPassword.Password"
				/>

				<Input
					label={t("public.onboarding.setPassword.repeatPassword")}
					type="password"
					value={form.repeatPassword}
					onChange={handleChange}
					name="repeatPassword"
					disabled={!!error}
					errors={mutation.error?.validation?.repeatPassword}
					e2e="SetPassword.RepeatPassword"
				/>

				<div>
					<Button
						type="submit"
						disabled={!!error}
						loading={mutation.isLoading}
						e2e="SetPassword.Submit"
					>
						{t("public.onboarding.setPassword.button")}
					</Button>
				</div>
			</form>
		</PublicLayout>
	);
}
