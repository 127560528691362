export default function totalOfArray(array: number[]): number {
	return array.reduce((partialSum, b) => partialSum + b, 0);
}

export function totalOfRow(array: number[][]): number[] {
	return array.map(row => totalOfArray(row));
}

export function totalOfcolumn(array: number[][]): number[] {
	return array.reduce((partialSum, current) => {
		current.forEach((value, index) => {
			partialSum[index] = (partialSum[index] || 0) + value;
		});
		return partialSum;
	}, []);
}
