import React, { useEffect, useState } from "react";
import { getFile } from "../../utils/file";
import ContentLoader from "../molecules/ContentLoader";

type ImageProps = {
	src: string;
	className?: string;
}

export default function Image(props: ImageProps): JSX.Element {
	const [ objectUrl, setObjectUrl ] = useState("");

	useEffect(() => {
		getFile(props.src)
			.then((image) => {
				setObjectUrl(URL.createObjectURL(image));
			})
			.catch(() => {
				setObjectUrl("");
			});
	}, []);

	if (!objectUrl)
		return <ContentLoader className={props.className} />;

	return <img className={props.className} src={objectUrl} />;

}
