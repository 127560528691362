export function getSessionStorageArray<T>(key: string): T[] {
	const items = sessionStorage.getItem(key);

	if (!items) return [];

	try {
		const parsedJson = JSON.parse(items) as unknown;

		if (!Array.isArray(parsedJson)) return [];

		return parsedJson as T[];
	} catch {
		return [];
	}
}
