import { useQuery, UseQueryResult } from "react-query";
import api from "../axios/api";
import { FormError } from "../models/types/error.type";
import RelationField from "../models/types/api/relation-fields.type";

export function getRelationFields(): UseQueryResult<RelationField[], void> {
	return useQuery(["relationFields"], async () => {
		try {
			const result = await api.get<RelationField[]>("/config/fields/relation");
			return result.data;
		} catch (error) {
			const errors: FormError<object> = { query: "default.error" };

			return Promise.reject(errors);
		}
	});
}
