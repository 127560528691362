const BYTES_IN_KB = 1000;
const SIZES = [
	"B", "kB", "MB", "GB", "TB",
] as const;

// from: https://stackoverflow.com/a/20732091/9946744
export default function humanFileSize(originalSize: number): string {
	const size = originalSize === 0
		? 0
		: Math.floor(Math.log(originalSize) / Math.log(BYTES_IN_KB));
	const number = (originalSize / Math.pow(BYTES_IN_KB, size)).toFixed(2);

	return `${number} ${SIZES[size]}`;
}
