import React from "react";

type ToggleProps = {
	label?: string;
	checked?: boolean;
	onChange: (value: boolean) => void;
}

export default function Toggle(props: ToggleProps): JSX.Element {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.onChange(event.target.checked);
	};

	return (
		<label className="relative inline-flex items-center cursor-pointer">
			<input
				type="checkbox"
				onChange={handleChange}
				className="sr-only peer"
				checked={props.checked}
			/>

			<div
				className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"
			/>

			{!!props.label && (
				<span className="ml-3">
					{props.label}
				</span>
			)}
		</label>
	);
}
