import { useQuery, UseQueryResult } from "react-query";
import api from "../axios/api";
import { ExploitationQueryResponse } from "../models/types/api/exploitation.type";

type QueryResult = UseQueryResult<ExploitationQueryResponse | null, null>;

export function getExploitation(
	managementContractId : number,
	year :string,
	grouping:string,
	periods:string): QueryResult {

	return useQuery("Exploitation", async (): Promise<ExploitationQueryResponse | null> => {
		if (isNaN(managementContractId))
			return null;

		const response = await api.get<ExploitationQueryResponse>(`/exploitation/managementcontract/${managementContractId}/${year}?grouping=${grouping}&periods=${periods}`);

		return response.data ?? [];
	}, { enabled: false });
}
