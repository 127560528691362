import { useMutation, UseMutationResult } from "react-query";
import SetPasswordForm, { SetPasswordBody } from "../models/forms/onboarding/set-password.form";
import { AxiosError } from "axios";
import ResponseError, { FormError } from "../models/types/error.type";
import useEnvironment from "../hooks/useEnvironment";
import useValidation from "../hooks/useValidation";
import {
	matchPattern,
	matchValue, minLength, required,
} from "../validators";
import api from "../axios/api";
import bodyToError from "../utils/bodyToError";

export function setPassword( options: {
	onSuccess: () => void
}):
	UseMutationResult<void, FormError<SetPasswordForm>, SetPasswordBody> {
	const validation = useValidation();
	const environment = useEnvironment();

	return useMutation({
		mutationFn: async (form: SetPasswordBody): Promise<void> => {
			const queryError: FormError<SetPasswordForm> = {};

			const validationResult = validation.validate(form, {
				email: [required],
				password: [
					required, minLength(8), matchPattern(new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!@|#$%?^_&*~:`,\\\\<>()=.+'\\[\\]{}\\/])"), "1 klein karakter, 1 groot karakter, 1 cijfer en een speciaal karakter (-!@|#$%?^_&*~:`)"),
				],
				repeatPassword: [ required, matchValue(form.password, "public.onboarding.setPassword.password") ],
			});

			if (validationResult) {
				queryError.validation = validationResult;
				return Promise.reject(queryError);
			}

			try {
				await api.post(`${environment.getEnvironment()}/onboarding/setpassword`, form);
			} catch (error) {
				if (error instanceof AxiosError && !!error.response?.status) {
					switch (error.response?.status) {
						case 400:
							queryError.validation = bodyToError(
								error.response.data as ResponseError<SetPasswordForm>
							);
							break;
						case 401:
							queryError.query = "public.onboarding.setPassword.errors.invalidLink";
					}
				}

				if (!queryError.query)
					queryError.query = "default.error";

				return Promise.reject(queryError);
			}
		},
		onSuccess: options.onSuccess,
	});
}
