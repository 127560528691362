import React from "react";
import Loader from "../atoms/Loader";
import makeStyle from "../../style/makeStyle";

type ContentLoaderProps = {
	className?: string;
}

export default function ContentLoader(props: ContentLoaderProps): JSX.Element {
	const style = makeStyle("flex flex-col items-center", [props.className]);

	return (
		<div className={style}>
			<Loader size="large" />
		</div>
	);
}
