import React from "react";
import { useTranslation } from "react-i18next";
import SystemError from "../../components/organisms/error/SystemError";
export default function InvalidEnvironmentPage(): JSX.Element {
	const { t } = useTranslation();

	return (
		<SystemError title={t("public.error.invalidEnvironment.title")} body={t("public.error.invalidEnvironment.body")} />
	);
}
