enum RelationFieldsConfigNames {
	Surname = "Surname",
	Gender = "Gender",
	DateOfBirth = "DateOfBirth",
	CountryOfBirth = "CountryOfBirth",
	CityOfBirth = "CityOfBirth",
	Iban = "Iban",
	IbanAccountName = "IbanAccountName",
	Organization = "Organization",
	PhoneNo1 = "PhoneNo1",
	PhoneNo2 = "PhoneNo2",
	MiddleName = "MiddleName",
	FirstName = "FirstName",
	Initials = "Initials",
	IdentificationKind = "IdentificationKind",
	IdentificationNumber = "IdentificationNumber",
	IdentificationIssuingCountry = "IdentificationIssuingCountry",
	ChamberOfCommerceNumber = "ChamberOfCommerceNumber",
	EmergencyEmailAddress = "EmergencyEmailAddress",
	Nationality = "Nationality",
	PostalAddressStreetName = "PostalAddressStreetName",
	PostalAddressHouseNumber = "PostalAddressHouseNumber",
	PostalAddressHouseNumberAddition = "PostalAddressHouseNumberAddition",
	PostalAddressHouseLetter = "PostalAddressHouseLetter",
	PostalAddressPostalCode = "PostalAddressPostalCode",
	PostalAddressPlaceOfResidence = "PostalAddressPlaceOfResidence",
	PostalAddressCountry = "PostalAddressCountry",
	PostalAddressForeignAddress1 = "PostalAddressForeignAddress1",
	PostalAddressForeignAddress2 = "PostalAddressForeignAddress2",
	PostalAddressForeignAddress3 = "PostalAddressForeignAddress3",
	ResidentialAddressStreetName = "ResidentialAddressStreetName",
	ResidentialAddressHouseNumber = "ResidentialAddressHouseNumber",
	ResidentialAddressHouseNumberAddition = "ResidentialAddressHouseNumberAddition",
	ResidentialAddressHouseLetter = "ResidentialAddressHouseLetter",
	ResidentialAddressPostalCode = "ResidentialAddressPostalCode",
	ResidentialAddressPlaceOfResidence = "ResidentialAddressPlaceOfResidence",
	ResidentialAddressCountry = "ResidentialAddressCountry",
	ResidentialAddressForeignAddress1 = "ResidentialAddressForeignAddress1",
	ResidentialAddressForeignAddress2 = "ResidentialAddressForeignAddress2",
	ResidentialAddressForeignAddress3 = "ResidentialAddressForeignAddress3",
	VATNumber = "VATNumber",
	IdentificationIssueDate = "IdentificationIssueDate",
	IdentificationIssueLocation = "IdentificationIssueLocation",
}

export default RelationFieldsConfigNames;

