import React, { useEffect, useState } from "react";
import AuthenticatedLayout from "../layouts/AuthenticatedLayout";
import { useTranslation } from "react-i18next";
import useManagementContracts from "../hooks/useManagementContracts";
import { getExploitation } from "../queries/exploitation";
import ContentLoader from "../components/molecules/ContentLoader";
import Table from "../components/atoms/table/Table";
import TableHead from "../components/atoms/table/TableHead";
import TableRow from "../components/atoms/table/TableRow";
import TableCell from "../components/atoms/table/TableCell";
import TableBody from "../components/atoms/table/TableBody";
import Alert from "../components/atoms/Alert";
import SelectInput from "../components/atoms/input/SelectInput";
import SelectOption from "../components/atoms/input/SelectOption";
import formatNumber from "../utils/formatNumber";
import { DateTime } from "luxon";
import TableFooter from "../components/atoms/table/TableFooter";
import totalOfArray, { totalOfcolumn, totalOfRow } from "../utils/totalOfArray";
import Button from "../components/atoms/Button";

import { FaFileExcel } from "react-icons/fa";

import { openFile } from "../utils/file";
import api from "../axios/api";

export default function ExploitationPage(): JSX.Element {
	const { t } = useTranslation("exploitation");
	const contracts = useManagementContracts();
	const [ year, setYear ] = useState(DateTime.now().get("year")
		.toString());
	const [ period, setPeriod ] = useState("month");
	const [ grouping, setGrouping ] = useState("unit");
	const currentdate = DateTime.now();
	const years = [];
	for (let i = 2015; i <= currentdate.get("year"); i++) {
		years.unshift(i.toString());
	}

	const {
		data, isLoading, refetch,
	} = getExploitation(contracts.currentContractId, year, grouping, period);

	useEffect(() => {
		void refetch();
	}, [
		contracts.currentContractId, year, grouping, period,
	]);

	const handlePeriodChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		setPeriod(event.target.value);
	};

	const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		setYear(event.target.value);
	};

	const handleGroupingChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		setGrouping(event.target.value);
	};

	const tableHeader = [
		grouping, ...(data?.columns ?? []), year,
	];

	const downloadExcelReport = (currentContractId: number): void => {
		const baseUrl = api.defaults.baseURL ?? "/";
		const url = `${baseUrl}exploitation/managementcontract/${currentContractId}/download?year=${year}&periodeWeergave=${period}&groepering=${grouping}&omzetInclBtw=true`;
		void openFile(url);
	};

	const allInfo = data?.rows.sort((a, b) => a.description.localeCompare(b.description));
	const costs = data?.rows.map(row => (row.costs)) ?? [];

	const turnovers = data?.rows.map(row => (row.turnover)) ?? [];

	const costPerYear = totalOfRow(costs);

	const turnoverPerYear = totalOfRow(turnovers);

	const costTotals = totalOfcolumn(costs);

	const turnoverTotals = totalOfcolumn(turnovers);

	const sumTotals = turnoverTotals.map((value, index) => value + costTotals[index]);

	const turnoverTotalPerYear = totalOfArray(turnoverTotals);
	const costTotalPerYear = totalOfArray(costTotals);
	const sumTotalPerYear = totalOfArray(sumTotals);

	return (
		<div>
			<AuthenticatedLayout
				title={t("title")}
				showManagementContracts
				actions={[
					<div key="period" className="w-40">
						<SelectInput
							label={t("period")}
							onChange={handlePeriodChange}
						>
							<SelectOption value="month">
								{t("month")}
							</SelectOption>

							<SelectOption value="quarter">
								{t("quarter")}
							</SelectOption>

							<SelectOption value="year">
								{t("year")}
							</SelectOption>
						</SelectInput>
					</div>,
					<div key="grouping" className="w-40">
						<SelectInput
							label={t("grouping")}
							onChange={handleGroupingChange}
						>
							<SelectOption value="unit">
								{t("Unit")}
							</SelectOption>

							<SelectOption value="object">
								{t("Object")}
							</SelectOption>

							<SelectOption value="complex">
								{t("Complex")}
							</SelectOption>

							<SelectOption value="specification">
								{t("specification")}
							</SelectOption>

							<SelectOption value="category">
								{t("category")}
							</SelectOption>
						</SelectInput>
					</div>,
					<div key="year" className="w-40">

						<SelectInput

							label={t("year")}
							onChange={handleYearChange}
						>
							{years.map(option => (
								<SelectOption value={option} key={option}>
									{option}
								</SelectOption>
							))}
						</SelectInput>

					</div>,
					<div key="downloadExcelReport" className="mt-5">
						<Button
							disabled={data?.columns.length === 0 || data?.rows.length === 0}
							onClick={
								(): void =>
									downloadExcelReport(contracts.currentContractId)
							}
						>

							<FaFileExcel />

							{t("downloadExcelReport")}
						</Button>
					</div>,
				]}
			>
				{isLoading ? <ContentLoader />
					: (!data ? (
						<Alert variant="warning" className="mb-5">
							{t("no_data")}
						</Alert>
					)
						: !data.columns[0] && !data.rows[0] ? (
							<Alert variant="warning" className="mb-5">
								{t("emptyYear")}
							</Alert>
						) : (
							<Table>
								<TableHead>
									<TableRow>
										{tableHeader.map(col => (
											// Temporary solution to properly load the translation, as the i18n library can't read the English version of it for some reason...? Unecessary spaces.
											col === "unit"
												? (
													<TableCell key={col}>
														{t(col.trim())}
													</TableCell>
												)
												: (
													<TableCell key={col}>
														{t(col.trim())}
													</TableCell>
												)
										))}
									</TableRow>
								</TableHead>

								<TableBody>
									{allInfo?.map((row, rowindex) => (
										<TableRow key={row.description}>
											<TableCell>
												{row.description}
											</TableCell>

											{turnovers[rowindex].map((turnover, index) => (
												<TableCell
													alignment="right"
													key={tableHeader[index + 1]}
													className="whitespace-pre"
												>
													<p>
														{`€ ${formatNumber(turnover)}`}
													</p>

													<p className="text-red-600">
														{`€ ${formatNumber(costs[rowindex][index])}`}
													</p>
												</TableCell>
											))}

											{period !== "year" && (
												<TableCell className="whitespace-pre" alignment="right">
													<p>
														{`€	${formatNumber(turnoverPerYear[rowindex])}\n`}
													</p>

													<p className="text-red-600">
														{`€ ${formatNumber(costPerYear[rowindex])}`}
													</p>
												</TableCell>
											)}

										</TableRow>
									)
									)}
								</TableBody>

								<TableFooter>
									<TableRow>
										<TableCell>
											{t("total_turnover")}
										</TableCell>

										{turnoverTotals.map((turnoverTotal, index) => (
											<TableCell key={tableHeader[index + 1]}>
												{`€ ${formatNumber(turnoverTotal)}`}
											</TableCell>
										))}

										<TableCell>
											{
												`€ ${formatNumber(turnoverTotalPerYear)}`
											}
										</TableCell>

									</TableRow>

									<TableRow>
										<TableCell>
											{t("total_costs")}
										</TableCell>

										{costTotals.map((costTotal, index) => (
											<TableCell key={tableHeader[index + 1]}>
												{`€ ${formatNumber(costTotal)}`}
											</TableCell>
										))}

										<TableCell>
											{
												`€ ${formatNumber(costTotalPerYear)}`
											}
										</TableCell>

									</TableRow>

									<TableRow>
										<TableCell>
											{t("total")}
										</TableCell>

										{sumTotals.map((sumTotal, index) => (
											<TableCell key={tableHeader[index + 1]}>
												{`€ ${formatNumber(sumTotal)}`}
											</TableCell>
										))}

										<TableCell>
											{`€ ${formatNumber(sumTotalPerYear)}`}
										</TableCell>

									</TableRow>
								</TableFooter>

							</Table>
						))}
			</AuthenticatedLayout>
		</div>
	);

}
