import React, { PropsWithChildren, useEffect } from "react";
import joinElements from "../../../utils/joinElements";
import StepSeparator from "./StepSeparator";
import StepTitle from "./StepTitle";

type Props = {
	titles: string[];
	current: number;
}

export default function Stepper(props: PropsWithChildren<Props>): JSX.Element {
	// the useEffect checks if the children are actually an array
	const selectedStep = (props.children as React.ReactNode[])[props.current];

	useEffect(() => {
		const validChildren = Array.isArray(props.children)
			&& props.children.length === props.titles.length;

		if (!validChildren) throw new Error("Titles must be the same length as the props");
	}, [props.children]);

	const steps = joinElements(
		props.titles.map((title, i) => (
			<StepTitle
				key={title}
				stepNumber={i + 1}
				current={i === props.current}
				finished={i < props.current}
			>
				{title}
			</StepTitle>
		)),
		key => (
			<StepSeparator
				key={`stepper-${key}`}
				current={key === props.current - 1}
				finished={key < props.current - 1}
			/>
		),
	);

	return (
		<div>
			<div className="mb-2 md:mb-4">
				<div className="flex flex-row items-center overflow-x-auto">
					{steps}
				</div>

				<p className="md:hidden relative mt-2 pb-2 text-center text-primary after:content-[''] after:absolute after:bottom-0 after:left-1/2 after:w-10 after:h-px after:bg-slate-400 after:-translate-x-1/2">
					{props.titles[props.current]}
				</p>
			</div>

			<div>
				{selectedStep}
			</div>
		</div>
	);
}
