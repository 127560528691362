import React, { useEffect } from "react";
import AuthenticatedLayout from "../../layouts/AuthenticatedLayout";
import { useTranslation } from "react-i18next";
import useManagementContracts from "../../hooks/useManagementContracts";
import getSettlementsByContract from "../../queries/settlements";
import Alert from "../../components/atoms/Alert";
import Table from "../../components/atoms/table/Table";
import TableHead from "../../components/atoms/table/TableHead";
import TableRow from "../../components/atoms/table/TableRow";
import TableCell from "../../components/atoms/table/TableCell";
import TableBody from "../../components/atoms/table/TableBody";
import IconButton from "../../components/atoms/IconButton";
import { FaDownload } from "react-icons/fa";
import api from "../../axios/api";
import { openFile } from "../../utils/file";
import { DateTime } from "luxon";
import { formatDateToReadable } from "../../utils/formatDate";
import i18next from "i18next";
import ContentLoader from "../../components/molecules/ContentLoader";

export default function SettlementsOverviewPage(): JSX.Element {
	const { t } = useTranslation([ "translation", "invoices" ]);

	const contracts = useManagementContracts();

	const settlementsByContract = getSettlementsByContract(contracts.currentContractId);

	useEffect(() => {
		void settlementsByContract.refetch();
	}, [contracts.currentContractId]);

	const handleDownload = (id: number): void => {
		const baseUrl = api.defaults.baseURL ?? "/";
		const url = `${baseUrl}managementcontracts/${contracts.currentContractId}/settlements/${id}/download`;

		void openFile(url);
	};

	return (
		<AuthenticatedLayout
			title={t("public.rentalBill.title")}
			showManagementContracts
		>
			{settlementsByContract.isLoading ? (<ContentLoader />)
				: !settlementsByContract.data
					? (
						<Alert variant="info" className="mb-5">
							{t("error.no_invoices")}
						</Alert>
					)
					: (
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										{t("public.rentalBill.table.period")}
									</TableCell>

									<TableCell>
										{t("public.rentalBill.table.date")}
									</TableCell>

									<TableCell>
										{t("public.rentalBill.table.download")}
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{settlementsByContract.data.map(settlement => (
									<TableRow key={`${contracts.currentContractId}-${settlement.settlementId}`}>
										<TableCell>
											{DateTime
												.fromISO(settlement.from)
												.setLocale(i18next.language)
												.toFormat("MMMM y")}
										</TableCell>

										<TableCell>
											{formatDateToReadable(settlement.createdOn)}
										</TableCell>

										<TableCell>
											{settlement.pdfAvailable ? (
												<IconButton
													onClick={
														(): void =>
															handleDownload(settlement.settlementId)
													}
													disabled={!settlement.pdfAvailable}
												>
													<FaDownload />
												</IconButton>
											) : (
												<p>
													{" "}

													{t("public.rentalBill.errors.noBillDownloadAvailable")}

													{" "}
												</p>
											)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					)}
		</AuthenticatedLayout>
	);
}
