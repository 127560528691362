import React from "react";
import { InputProps } from "../../../models/props/input.props";
import makeStyle from "../../../style/makeStyle";
import ErrorList from "../ErrorList";

export default function Input<V extends string | number>(props: InputProps<V>): JSX.Element {
	const style = makeStyle("input", [props.errors ? "border-error" : "border-slate-300"]);
	const containerStyle = makeStyle("flex flex-col", [props.className]);

	return props.visible || props.visible === undefined || props.visible === null ? (
		<div className={containerStyle}>

			<label>
				{ props.required ? `${props.label } *` : `${props.label} ` }
			</label>

			<input
				className={style}
				value={props.value}
				required={props.required}
				disabled={props.disabled}
				placeholder={props.placeholder}
				type={props.type}
				name={props.name}
				onChange={props.onChange}
				onKeyDown={props.onKeyDown}
				maxLength={props.maxLength}
				onKeyUp={props.onKeyUp}
				{...props}
			/>

			{
				!!props.errors && Array.isArray(props.errors) && <ErrorList errors={props.errors} e2e="ErrorList" />
			}
		</div>

	) : <div />;
}
