import React, {
	createContext,
	PropsWithChildren,
	useContext, useEffect, useState,
} from "react";
import api from "../axios/api";
import CountryResponse from "../models/types/api/country.type";
import useAuth from "./useAuth";
import useEnvironment from "./useEnvironment";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

type ContextProps = {
	availableCountries: CountryResponse[];
	init: () => void;
};

const CountryContext = createContext<ContextProps>({} as ContextProps);

export function CountryProvider(props: PropsWithChildren): JSX.Element {
	const [ availableCountries, setAvailableCountries ] = useState<CountryResponse[]>([]);

	const { t } = useTranslation();

	const auth = useAuth();
	const environment = useEnvironment();

	useEffect(() => {
		if (!auth.authenticated())
			return;

		init();
	}, [auth.authenticated()]);

	const init = (): void => {
		const baseUrl = environment.getBaseUrl();

		if (baseUrl === null)
			return;

		api.get("/tables/countries")
			.then((result) => {
				setAvailableCountries(result.data as CountryResponse[]);
			})
			.catch(() => {
				toast.warning(t("public.countries.error"));
			});
	};

	return (
		<CountryContext.Provider
			value={{ availableCountries, init }}
		>
			{props.children}
		</CountryContext.Provider>
	);
}

export default function useCountries(): ContextProps {
	return useContext(CountryContext);
}
