import React from "react";

type CheckboxProps = {
	label?: string;
	checked?: boolean;
	onChange: (value: boolean) => void;
	warningText?: string;
}

export default function Checkbox(props: CheckboxProps): JSX.Element {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.onChange(event.target.checked);
	};

	return (
		<div className="flex">
			<div className="flex items-center h-5">
				<input
					onChange={handleChange}
					id="helper-checkbox"
					aria-describedby="helper-checkbox-text"
					checked={props.checked}
					type="checkbox"
					className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
				/>
			</div>

			<div className="ml-2 text-sm">
				<label htmlFor="helper-checkbox" className="font-bold text-m">
					{props.warningText}
				</label>

				<p id="helper-checkbox-text" className="text-m font-normal">
					{props.label}
				</p>
			</div>
		</div>
	);
}
