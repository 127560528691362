enum TicketStatus {
	"NIEUW",
	"IN_BEHANDELING",
	"AFWACHTING_OFFERTE",
	"AFWACHTING_EIGENAAR",
	"AFWACHTING_DIENSTVERLENER",
	"AFWACHTING_HUURDER",
	"AFWACHTING_BEHEERDER",
	"WORDT_UITGEVOERD",
	"FOLLOW_UP",
	"AFGEHANDELDHANDMATIG",
	"AFGEHANDELDDOORDIENSTVERLENER",
	"AFGEHANDELDDOORHUURDER",
}

export default TicketStatus;
