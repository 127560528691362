import React, { PropsWithChildren } from "react";
import Subtitle from "../atoms/Subtitle";
import makeStyle from "../../style/makeStyle";

type FormPartProps = {
	title: string;
	className?: string;
}

export default function FormPart(props: PropsWithChildren<FormPartProps>): JSX.Element {
	const style = makeStyle("", [props.className]);

	return (
		<div className={style}>
			<div className="bg-light rounded-t-lg p-1 pl-2">
				<Subtitle>
					{props.title}
				</Subtitle>
			</div>

			<div className="drop-shadow-md bg-white p-2 rounded-b-lg">
				{props.children}
			</div>
		</div>
	);
}
