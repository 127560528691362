import React, { PropsWithChildren, useState } from "react";
import FormPart from "../../../molecules/FormPart";
import { useTranslation } from "react-i18next";
import Input from "../../../atoms/input/Input";
import DateTimeInput from "../../../atoms/input/DateTimeInput";
import SelectInput from "../../../atoms/input/SelectInput";
import SelectOption from "../../../atoms/input/SelectOption";
import { DateTime } from "luxon";
import AccountType from "../../../../models/enums/account-type.enum";
import useUserForm from "../../../../hooks/useUserForm";
import EditUserAddressFormResidentialAddress from "./EditUserAddressFormResidentialAddress";
import CountrySelector from "../../../molecules/CountrySelector";
import Alert from "../../../atoms/Alert";
import UserInformationSections from "../../../../models/enums/user-information-sections";
import RelationFieldsConfigNames from "../../../../models/enums/RelationFields.enum";
import EditUserAddressFormPostalAddress from "./EditUserAddressFormPostalAddress";
import PhoneCountryCodeSelector from "../../../molecules/PhoneCountryCodeSelector";

export default function EditUserForm(props: PropsWithChildren): JSX.Element {
	const user = useUserForm();
	const { t } = useTranslation([ "user", "translate" ]);
	const [ selectedPhoneNo1, setSelectedPhoneNo1 ] = useState("");
	const [ selectedPhoneNo2, setSelectedPhoneNo2 ] = useState("");

	const handlePhoneNo1Change = (value: string, code: string): void => {
		setSelectedPhoneNo1(value);
		user.setSelectedPhoneNo1Code(code); // Set the phone code in context
	};

	const handlePhoneNo2Change = (value: string, code: string): void => {
		setSelectedPhoneNo2(value);
		user.setSelectedPhoneNo2Code(code); // Set the phone code in context
	};

	// Replace with field retrieval checks from config using function (more readable)
	return (
		<form>
			<div className="flex flex-wrap gap-form justify-center">

				{user.isSectionRequired(UserInformationSections.ContactInformation) && (
					<FormPart
						title={t("edit.headers.contact_details")}
						className="basis-full lg:basis-auto grow"
					>
						<div className="flex flex-row gap-form justify-center flex-wrap">
							<div className="flex flex-col gap-form basis-full md:basis-auto grow">
								<SelectInput
									label={t("edit.account_type.label")}
									value={AccountType[user.accountType]}
									onChange={user.handleAccountTypeChange}
									e2e="User.Edit.AccountType"
								>
									<SelectOption value="Private">
										{t("edit.account_type.private")}
									</SelectOption>

									<SelectOption value="Company">
										{t("edit.account_type.company")}
									</SelectOption>
								</SelectInput>

								{user.accountType === AccountType.Company
									&& (
										<Input
											label={t("data.organization")}
											value={user.form.organization ?? ""}
											onChange={user.handleDetailsChange}
											name="organization"
											errors={user.validationErrors?.organization}
											visible={
												user.isFieldVisible(
													RelationFieldsConfigNames.Organization)
											}
											required={
												user.isFieldRequired(
													RelationFieldsConfigNames.Organization)
											}
											e2e="User.Edit.Organization"
										/>
									)}

								<Input
									label={t("data.first_name")}
									value={user.form.firstName ?? ""}
									onChange={user.handleDetailsChange}
									name="firstName"
									errors={user.validationErrors?.firstName}
									// Replace with field retrieval checks from config using function (more readable)
									required={
										user.isFieldRequired(RelationFieldsConfigNames.FirstName)
									}
									visible={user.isFieldVisible(RelationFieldsConfigNames.FirstName)}
									e2e="User.Edit.FirstName"
								/>

								<Input
									label={t("data.initials")}
									value={user.form.initials ?? ""}
									onChange={user.handleDetailsChange}
									name="initials"
									errors={user.validationErrors?.initials}
									required={
										user.isFieldRequired(
											RelationFieldsConfigNames.Initials)
									}
									visible={
										user.isFieldVisible(
											RelationFieldsConfigNames.Initials)
									}
									e2e="User.Edit.Initials"
								/>

								<Input
									label={t("data.middle_name")}
									value={user.form.middleName ?? ""}
									onChange={user.handleDetailsChange}
									errors={user.validationErrors?.middleName}
									name="middleName"
									required={
										user.isFieldRequired(
											RelationFieldsConfigNames.MiddleName)
									}
									visible={
										user.isFieldVisible(
											RelationFieldsConfigNames.MiddleName)
									}
									e2e="User.Edit.MiddleName"
								/>

								<Input
									label={t("data.surname")}
									value={user.form.surname ?? ""}
									onChange={user.handleDetailsChange}
									name="surname"
									errors={user.validationErrors?.surname}
									required={
										user.isFieldRequired(
											RelationFieldsConfigNames.Surname)
									}
									visible={
										user.isFieldVisible(
											RelationFieldsConfigNames.Surname)
									}
									e2e="User.Edit.Surname"
								/>
							</div>

							<div className="flex flex-col gap-form basis-full md:basis-auto grow">
								<SelectInput
									label={t("data.gender.label")}
									value={user.form.gender}
									onChange={user.handleGenderChange}
									errors={user.validationErrors?.gender}
									required={user.isFieldRequired(RelationFieldsConfigNames.Gender)}
									visible={user.isFieldVisible(RelationFieldsConfigNames.Gender)}
									e2e="User.Edit.Gender"
								>
									<SelectOption value="Male">
										{t("data.gender.options.male")}
									</SelectOption>

									<SelectOption value="Female">
										{t("data.gender.options.female")}
									</SelectOption>

									<SelectOption value="NonBinary">
										{t("data.gender.options.nonBinary")}
									</SelectOption>
								</SelectInput>

								<DateTimeInput
									label={t("data.date_of_birth")}
									type="date"
									value={DateTime.fromISO(user.form.dateOfBirth ?? "")}
									onChange={user.handleDateOfBirthChange}
									errors={user.validationErrors?.dateOfBirth}
									required={
										user.isFieldRequired(
											RelationFieldsConfigNames.DateOfBirth)
									}
									visible={
										user.isFieldVisible(
											RelationFieldsConfigNames.DateOfBirth)
									}
									e2e="User.Edit.DateOfBirth"
								/>

								<CountrySelector
									label={t("data.country_of_birth")}
									value={user.form.countryOfBirth ?? "NL"}
									onChange={user.handleCountryOfBirthChange}
									required={
										user.isFieldRequired(
											RelationFieldsConfigNames.CountryOfBirth)
									}
									visible={
										user.isFieldVisible(
											RelationFieldsConfigNames.CountryOfBirth)
									}
									e2e="User.Edit.CountryOfBirth"
								/>

								<Input
									label={t("data.city_of_birth")}
									value={user.form.cityOfBirth ?? ""}
									onChange={user.handleDetailsChange}
									name="cityOfBirth"
									errors={user.validationErrors?.cityOfBirth}
									required={
										user.isFieldRequired(
											RelationFieldsConfigNames.CityOfBirth)
									}
									visible={
										user.isFieldVisible(
											RelationFieldsConfigNames.CityOfBirth)
									}
									e2e="User.Edit.CityOfBirth"
								/>

								<CountrySelector
									label={t("data.nationality")}
									value={user.form.nationality ?? ""}
									onChange={user.handleNationalityChange}
									required={
										user.isFieldRequired(
											RelationFieldsConfigNames.Nationality)
									}
									visible={
										user.isFieldVisible(
											RelationFieldsConfigNames.Nationality)
									}
									e2e="User.Edit.Nationality"
								/>

								<PhoneCountryCodeSelector
									name="phoneNo1"
									inputValue={user.form.phoneNo1 ?? ""}
									onInputChange={user.handleDetailsChange}
									onSelectedValueChange={handlePhoneNo1Change}
									label={t("data.phone_no_1")}
									placeholder="0612345678"
									required={user.isFieldRequired(RelationFieldsConfigNames.PhoneNo1)}
									visible={user.isFieldVisible(RelationFieldsConfigNames.PhoneNo1)}
								/>

								<PhoneCountryCodeSelector
									name="phoneNo2"
									inputValue={user.form.phoneNo2 ?? ""}
									label={t("data.phone_no_2")}
									placeholder="0612345678"
									onInputChange={user.handleDetailsChange}
									onSelectedValueChange={handlePhoneNo2Change}
									required={user.isFieldRequired(RelationFieldsConfigNames.PhoneNo2)}
									visible={user.isFieldVisible(RelationFieldsConfigNames.PhoneNo2)}
								/>

								{user.accountType === AccountType.Company
									&& (
										<Input
											label={t("data.chamberOfCommerceNumber")}
											value={user.form.chamberOfCommerceNumber ?? ""}
											onChange={user.handleDetailsChange}
											errors={user.validationErrors?.chamberOfCommerceNumber}
											name="chamberOfCommerceNumber"
											required={
												user.isFieldRequired(
													RelationFieldsConfigNames.ChamberOfCommerceNumber)
											}
											visible={
												user.isFieldVisible(
													RelationFieldsConfigNames.ChamberOfCommerceNumber)
											}
											e2e="User.Edit.ChamberOfCommerceNumber"
										/>
									)}

								<Input
									label={t("data.emergencyEmail")}
									value={user.form.emergencyEmailAddress ?? ""}
									errors={user.validationErrors?.emergencyEmailAddress}
									onChange={user.handleDetailsChange}
									name="emergencyEmailAddress"
									required={
										user.isFieldRequired(
											RelationFieldsConfigNames.EmergencyEmailAddress)
									}
									visible={
										user.isFieldVisible(
											RelationFieldsConfigNames.EmergencyEmailAddress)
									}
									e2e="User.Edit.EmergencyEmailAddress"
								/>
							</div>
						</div>
					</FormPart>
				)}

				<div className="flex flex-col gap-form basis-full lg:basis-auto grow">
					{user.isSectionRequired(UserInformationSections.FinancialInformation)
								&& (
									<FormPart
										title={t("edit.headers.bank_details")}
										className="basis-full md:basis-2/5 xl:basis-0 gap-form"
									>
										<div className="flex flex-col gap-form">
											<Input
												label={t("data.iban")}
												value={user.form.iban ?? ""}
												onChange={user.handleDetailsChange}
												name="iban"
												errors={user.validationErrors?.iban}
												required={
													user.isFieldRequired(
														RelationFieldsConfigNames.Iban)
												}
												visible={
													user.isFieldVisible(
														RelationFieldsConfigNames.Iban)
												}
												e2e="User.Edit.Iban"
											/>

											<Input
												label={t("data.iban_account_name")}
												value={user.form.ibanAccountName ?? ""}
												onChange={user.handleDetailsChange}
												name="ibanAccountName"
												errors={user.validationErrors?.ibanAccountName}
												required={
													user.isFieldRequired(
														RelationFieldsConfigNames.IbanAccountName)
												}
												visible={
													user.isFieldVisible(
														RelationFieldsConfigNames.IbanAccountName)
												}
												e2e="User.Edit.IbanAccountName"
											/>

											{user.accountType === AccountType.Company
												&& (
													<Input
														label={t("data.vat_number")}
														value={user.form.vatNumber ?? ""}
														onChange={user.handleDetailsChange}
														name="vatNumber"
														errors={user.validationErrors?.vatNumber}
														required={
															user.isFieldRequired(
																RelationFieldsConfigNames.VATNumber)
														}
														visible={
															user.isFieldVisible(
																RelationFieldsConfigNames.VATNumber)
														}
														e2e="User.Edit.VATNumber"
													/>
												)}
										</div>
									</FormPart>
								)}

					{user.isSectionRequired(
						UserInformationSections.IdentificationInformation
					)
								&& (
									<FormPart
										title={t("edit.headers.identification_details")}
										className="basis-full md:basis-2/5 xl:basis-0"
									>
										<div className="flex flex-col gap-form">
											<SelectInput
												label={t("data.identification_kind.label")}
												value={user.form.identificationKind}
												onChange={user.handleIdentificationKindChange}
												errors={user.validationErrors?.identificationKind}
												required={
													user.isFieldRequired(
														RelationFieldsConfigNames.IdentificationKind)
												}
												visible={
													user.isFieldVisible(
														RelationFieldsConfigNames.IdentificationKind)
												}
												e2e="User.Edit.IdentificationKind"
											>
												<SelectOption value="NationalIdCard">
													{t("data.identification_kind.options.national_id_card")}
												</SelectOption>

												<SelectOption value="ForeignIdCard">
													{t("data.identification_kind.options.foreign_id_card")}
												</SelectOption>

												<SelectOption value="Passport">
													{t("data.identification_kind.options.passport")}
												</SelectOption>

												<SelectOption value="DriversLicense">
													{t("data.identification_kind.options.drivers_license")}
												</SelectOption>

												<SelectOption value="Other">
													{t("data.identification_kind.options.other")}
												</SelectOption>
											</SelectInput>

											<Input
												label={t("data.identification_number")}
												value={user.form.identificationNumber ?? ""}
												onChange={user.handleDetailsChange}
												name="identificationNumber"
												errors={user.validationErrors?.identificationNumber}
												required={
													user.isFieldRequired(
														RelationFieldsConfigNames.IdentificationNumber)
												}
												visible={
													user.isFieldVisible(
														RelationFieldsConfigNames.IdentificationNumber)
												}
												e2e="User.Edit.IdentificationNumber"
											/>

											<DateTimeInput
												label={t("data.identification_issue_date")}
												type="date"
												value={DateTime.fromISO(user.form.identificationIssueDate ?? "")}
												onChange={user.handleIdentificationIssueDateChange}
												name="identificationIssueDate"
												errors={user.validationErrors?.identificationIssueDate}
												required={
													user.isFieldRequired(
														RelationFieldsConfigNames.IdentificationIssueDate)
												}
												visible={
													user.isFieldVisible(
														RelationFieldsConfigNames.IdentificationIssueDate)
												}
												e2e="User.Edit.IdentificationIssueDate"
											/>

											<Input
												label={t("data.identification_issue_location")}
												value={user.form.identificationIssueLocation ?? ""}
												onChange={user.handleDetailsChange}
												name="identificationIssueLocation"
												errors={user.validationErrors?.identificationIssueLocation}
												required={
													user.isFieldRequired(
														RelationFieldsConfigNames.IdentificationIssueLocation)
												}
												visible={
													user.isFieldVisible(
														RelationFieldsConfigNames.IdentificationIssueLocation)
												}
												e2e="User.Edit.IdentificationIssueLocation"
											/>

											{!!user.foreignIdentificationKind && (
												<CountrySelector
													label={t("data.identification_issuing_country")}
													value={user.form.identificationIssuingCountry ?? ""}
													onChange={
														user.handleIdentificationCountryChange
													}
													required={
														user.isFieldRequired(
															RelationFieldsConfigNames
																.IdentificationIssuingCountry)
													}
													visible={
														user.isFieldVisible(
															RelationFieldsConfigNames
																.IdentificationIssuingCountry)
													}
													e2e="User.Edit.IdentificationIssuingCountry"
												/>
											)}
										</div>
									</FormPart>
								)}
				</div>

				<div className="flex flex-row flex-wrap gap-3 grow">
					<EditUserAddressFormResidentialAddress addressType="residentialAddress" />

					{!!user.postalAddressDiffersFromResidential && <EditUserAddressFormPostalAddress addressType="postalAddress" />}
				</div>
			</div>

			{!!user.queryError && (
				<Alert variant="error" className="w-full mt-4">
					{t(user.queryError, { ns: "translation" })}
				</Alert>
			)}

			{props.children}
		</form>
	);
}
